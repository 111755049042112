<template>
  <div>
    <div class="banner">
      <div class="title"></div>
      <div class="desc"></div>
      <div class="inp">
        <input v-model="keyword" placeholder="请输入搜索关键词" type="text" name="" id="">
        <div @click="search">
          <i class="icon icon-search"></i>
        </div>
      </div>
    </div>

    <div class="w1200 jiance_content">
      <!-- 左侧菜单 -->


      <!-- 右侧内容 -->

      <div class="jc_list">
        <div class="top">
          <span>检测项目列表</span>
          <div class="position">
            <span>您所在的位置：<router-link to="/">首页 </router-link>> <router-link to="jiance_center">检测中心</router-link></span>
          </div>
        </div>
        <div class="xiangmu">
          <div class="xiangmu_item" :style="index % 4 == 2 ? 'margin-right: 0' : ''" v-for="(item,index) in xiangmulist" :key="index">
            <img :src="item.thumb" class="thumb" alt="">
            <div class="info">
              <div class="stitle" @click="goinfo(item.id)">{{item.title}}</div>
              <div class="desc">{{item.memo}}</div>
              <div class="btn" @click="goinfo(item.id)">立即预约</div>
            </div>
          </div>
          <div v-if="!xiangmulist || xiangmulist.length < 1" style="width: 100%;">
            <el-empty :image-size="200"></el-empty>
          </div>
          
        </div>
        <div class="page" v-if="count > 0">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="count"
            @current-change="changePage">
          </el-pagination>
        </div>
      </div>
      

    </div>
  </div>
</template>

<script>
import { getcate,getprojectlist } from '../utils/api.js'
export default {
  name: 'BaizeJianceCenter',

  data() {
    return {
      menulist: [

      ],
      menu_active: 0,
      child_active: -1,
      cateid: "",
      cateid2: "",
      keyword: "",
      nextp: 1,
      xiangmulist: [

      ],
      xiangmuindex: 0,
      count: 0,
      isback: 0
    };
  },

  mounted() {
    this.$store.commit('setSelected',-1)
    this.keyword = this.$route.query.keyword
    this.getlist()
  },

  methods: {
    search(){
      var that = this
      if(this.keyword){
        this.nextp = 1
        this.getlist()
      }else{
        this.$message({
          message: "请输入搜索内容",
          type: "error"
        })
      }

    },
    goinfo(id){
      this.$router.push({
        name: 'jiance_info',
        query: {
          id: id,
        }
      })
    },
    getlist(){
      getprojectlist({
        p: this.nextp,
        cateid: "",
        cateid2: "",
        keyword: this.keyword
      },res=>{
        if(res.code == 0){
          this.xiangmulist = res.data.list
          this.count = res.data.total * 1
        }
      })
    },
    changePage(e){
      this.nextp = e
      this.getlist()
    }
  },

  beforeCreate () {
    document.querySelector('body').setAttribute('style','background-color: #fff')
  },

  watch: {
    // $route(){
    //   if(this.$route.query.isback){
    //     this.$refs.child.getlist(this.cateid,this.cateid2,this.keyword)
    //   }
    // }
  }

};
</script>

<style scoped>

  .banner{
    width: 100%;
    height: 4.2rem;
    background-image: url('../assets/jiancebg.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
  .banner .title{
    color: #fff;
    font-size: .42rem;
    padding-top: 2.9rem;
    text-align: center;
  }
  .banner .desc{
    color: #fff;
    font-size: .16rem;
    margin-top: .26rem;
    text-align: center;
  }
  .banner .inp{
    width: 5.43rem;
    height: fit-content;
    margin: .5rem auto 0;
    display: flex;

  }
  .banner .inp > input{
    width: 4.83rem;
    height: .52rem;
    box-sizing: border-box;
    padding: 0 .18rem;
    border: none;
    background-color: #fff;
    border-radius: .4rem 0 0 .4rem;
  }
  .banner .inp > div{
    flex: 1;
    background-color: #013B65;
    border-radius: 0 .4rem .4rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .container{
    margin: 0 auto;
  }
  .jiance_content{
    display: flex;
    justify-content: space-between;
    margin: .4rem auto;
  }
.menu{
    width: 2.71rem;
    height: fit-content;
  }
.menu .title{
    display: flex;
    flex-direction: column;
    height: 1.34rem;
    background-color: #F2F2F2;
    border-radius: 2px 2px 0 0;
    align-items: center;
    justify-content: center;
  }
.menu .title > span:nth-child(1){
    color: #333333;
    font-size: .26rem;
    font-weight: bold;
  }
.menu .title > span:nth-child(2){
    color: #333333;
    font-size: .18rem;
  }
  .menu .menu_list{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    background-color: #E9E9E9;
    color: #111111;
    font-size: .18rem;
    cursor: pointer;
    border-bottom: 1px solid #D6D4D4;
    overflow: hidden;
    position: relative;
    flex-direction: column;
  }
  .menu .menu_list > span{
    display: flex;
    width: 100%;
    height: .75rem;
    justify-content: center;
    align-items: center;
  }
  .menu > .active{
    background-color: #1C65B1;
    color: #fff;
    border-bottom: none;
    font-weight: bold;
  }
  .menu .menu_list .child_list{
    width: 100%;
    background-color: #F2F2F2;
    display: flex;
    flex-direction: column;
    height: fit-content;
    overflow: hidden;

  }
  .menu .menu_list .child_list > span{
    display: inline-block;
    height: 0;
    text-align: center;
    line-height: .6rem;
    color: #333333;
    font-size: .16rem;
    font-weight: normal;
    transition: all ease .3s;
  }
  .menu .menu_list .child_list > .active{
    color: #1C65B1;
    font-weight: bold;
  }
  .jc_list{
    width: 12rem;
  }

  .jc_list .top{
    display: flex;
    justify-content: space-between;
    padding-bottom: .13rem;
    border-bottom: 1px solid #E9E9E9;
    margin-bottom: .3rem;
  }
  .jc_list .top > span{
    color: #333333;
    font-size: .18rem;
    font-weight: bold;
    position: relative;
  }
  .jc_list .top > span::after{
    content: "";
    width: 100%;
    height: 2px;
    background-color: #1C65B1;
    position: absolute;
    left: 0;
    bottom: -.14rem;
  }
  .jc_list .top > .position{
    color: #999999;
    font-size: .16rem;
  }
  .jc_list .top > .position a{
    color: #999999;
    font-size: .16rem;
  }
  .xiangmu{
    width: 100%;
    margin-top: .4rem;
    display: flex;
    flex-wrap: wrap;
  }
  .xiangmu .xiangmu_item{
    width: 2.8rem;
    height: 4.15rem;
    background-color: #fff;
    margin-right: .24rem;
    margin-top: .26rem;
    display: flex;
    flex-direction: column;
    transition: all .3s ease;
  }
  .xiangmu .xiangmu_item:hover{
    transition: all .3s ease;
    transform: scale(1.05);
    box-shadow: 0 -.1rem .2rem rgba(19, 116, 222, .3);
  }
  .xiangmu .xiangmu_item .thumb{
    width: 100%;
    height: 2.21rem;
    cursor: pointer;
  }
  .xiangmu .xiangmu_item .info{
    flex: 1;
    box-sizing: border-box;
    padding: .2rem;
    text-align: left;
    background-color: #F6F6F6;
  }
  .xiangmu .xiangmu_item .info .stitle{
    color: #333333;
    font-size: .17rem;
    font-weight: bold;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .xiangmu .xiangmu_item .info .desc{
    color: #666666;
    font-size: .14rem;
    margin-top: .23rem;
    line-height: .22rem;
    height: .44rem;
    overflow: hidden;
  }
  .xiangmu .xiangmu_item .info .btn{
    width: 1.1rem;
    height: .36rem;
    border-radius: .2rem;
    border: 1px solid #1C65B1;
    color: #1C65B1;
    font-size: .16rem;
    margin: .18rem auto 0;
    text-align: center;
    line-height: .36rem;
    cursor: pointer;
    transition: all ease .3s;
  }
  .xiangmu .xiangmu_item .info .btn:hover{
    background-color: #1C65B1;
    color: #fff;
    transition: all .3s ease;
  }
  .jc_list .page{
    display: flex;
    margin: .4rem 0;
    justify-content: center;
  }
</style>