<template>
  <div class="jc_info">
    <!-- 右侧内容 -->
    <div class="top">
      <span>项目产品介绍</span>
      <div class="position">
        <span>您所在的位置：<router-link to="/">首页 </router-link><router-link to="/jiance_center">> 检测中心 </router-link>> 项目介绍</span>
      </div>
    </div>

    <div class="info">
      <div class="info_title">
        <img :src="content.thumb" alt="">
        <div class="intro">
          <div>{{content.title}}</div>
          <div class="productinfo">{{content.memo}}</div>
          <div class="btn" @click="goyuyue(content.cateid,content.cateid2,content.id)">立即预约</div>
        </div>
      </div>

      <div class="info_content">
        <div class="title">{{content.title}}检测介绍</div>
        <div class="content ql-editor" v-html="content.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getprojectinfo } from '../utils/api.js'
export default {
  name: 'BaizeJianceInfo',

  data() {
    return {
      id: "",
      content: ""
    };
  },

  mounted() {
    this.id = this.$route.query.id
    this.getinfo(this.id)
  },

  methods: {
    getinfo(id){
      getprojectinfo({
        id
      },res=>{
        if(res.code == 0){
          this.content = res.data.data
        }
      })
    },
    goyuyue(cateid,cateid2,proid){
      this.$router.push({
        name: 'yuyue',
        query: {
          cateid,
          cateid2,
          proid
        }
      })
    }
  },
  watch: {
    $route(){
      location.reload()
    }
  }
};
</script>

<style>
  .jc_info{
    width: 74%;
  }
  .jc_info .top{
    display: flex;
    justify-content: space-between;
    padding-bottom: .13rem;
    border-bottom: 1px solid #E9E9E9;
    margin-bottom: .3rem;
  }
  .jc_info .top > span{
    color: #333333;
    font-size: .18rem;
    font-weight: bold;
    position: relative;
  }
  .jc_info .top > span::after{
    content: "";
    width: 100%;
    height: 2px;
    background-color: #1C65B1;
    position: absolute;
    left: 0;
    bottom: -.14rem;
  }
  .jc_info .top > .position{
    color: #999999;
    font-size: .16rem;
  }
  .jc_info .top > .position a{
    color: #999999;
    font-size: .16rem;
  }

  .jc_info .info .info_title{
    width: 100%;
    display: flex;
    padding-bottom: .4rem;
    border-bottom: 1px solid #EDEDED;
    justify-content: space-between;
  }
  .jc_info .info .info_title > img{
    width: 3.57rem;
    height: 2.64rem;
  }
  .jc_info .info .info_title > .intro{
    width: 4.95rem;
    height: 2.64rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .jc_info .info .info_title > .intro > div:nth-child(1){
    color: #1C65B1;
    font-size: .22rem;
  }
  .jc_info .info .info_title > .intro > .productinfo{
    height: 1.4rem;
    overflow: hidden;
    color: #999999;
    font-size: .16rem;
  }
  .jc_info .info .info_title > .intro > .btn{
    width: 1.2rem;
    height: .36rem;
    background-color: #1C65B1;
    border-radius: .2rem;
    color: #fff;
    font-size: .14rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .jc_info .info .info_content .title{
    color: #333333;
    font-size: .32rem;
    text-align: center;
    margin-top: .32rem;

  }
</style>