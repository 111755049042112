import axios from 'axios'
import store from '../store/index';
import router from '../router/index'
import { Loading } from 'element-ui';
import { Message } from 'element-ui'
// import { reject, resolve } from 'core-js/fn/promise';
var loadingcontext;
axios.defaults.baseURL = location.href.indexOf('https://bezova.com') >= 0 ? 'https://bezova.com/app' : 'https://www.bezova.com/app'
axios.defaults.timeout = 10000
axios.interceptors.request.use(config=>{
  const token = localStorage.getItem('token')
  config.headers.token = token
  return config
})
axios.interceptors.response.use(response=>{
  loadingcontext.close()
  if(response.status === 200){
    if(response.data.code == 10086){
      store.commit('removeToken')
      Message({
        message: response.data.msg,
        type: "warning",
        onClose(){
          router.push({
            name: 'login',
            query: {
              backurl: response.data.backurl
            }
          })
        }
      })
      return false
    }
    if(response.data.code > 0){
      Message({
        message: response.data.msg,
        type: "error"
      })
      return false
    }
    return Promise.resolve(response.data)
  }else{
    return Promise.reject(response)
  }
},error=>{
  console.log(error)
  return Promise.reject(error.response)
  if(error.response.status){

    
  }
})

var post = function(url,params,showLoading=true){
  if(showLoading){
    loadingcontext = Loading.service({
      lock: true
    })
  }

  return new Promise((resolve,reject)=>{
    axios.post(url,params)
    .then(res=>{

      resolve(res)
    })
    .catch(err=>{
      reject(err)
    })
  })
}
export default {
  post
}