<template>
  <div class="news_msg">
    <div class="news_msg_content">
      <div class="title">{{content.title}}</div>
      <div class="time">
        <span>{{content.zuozhe}}</span>
        <span>发布时间:{{content.updatetime}}</span>
      </div>
      <div class="news_html ql-editor" v-html="content.content">
        
      </div>
    </div>

    <div class="page2">
      <div class="prev">
        上一篇:<span @click="goprev">{{prev.title}}</span>
      </div>
      <div class="next">
        下一篇:<span @click="gonext">{{next.title}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getnewsinfo } from '../utils/api.js'
export default {
  name: 'BaizeNewsContent',

  data() {
    return {
      content: "",
      prev: {},
      next: {}
    };
  },

  mounted() {
    this.cid = this.$route.query.cid
    this.getinfo()
  },

  methods: {
    getinfo(){
      getnewsinfo({id: this.cid},res=>{
        if(res.code == 0){
          this.content = res.data.data
          if(res.data.prev){
            this.prev = res.data.prev
          }else{
            this.prev = {
              id: 0,
              title: "没有更多了"
            }
          }
          
          this.next = res.data.next
        }
      })
    },
    goprev(){
      if(this.prev.id > 0){
        this.cid = this.prev.id
        this.getinfo()
      }
    },
    gonext(){
      if(this.next.id > 0){
        this.cid = this.next.id
        this.getinfo()
      }
    }
  },
  watch: {
    $route(){
      location.reload()
    }
  }
};
</script>

<style>
  .news_msg{
    width: 100%;
    height: fit-content;
    background-color: #fff;
    box-sizing: border-box;
    padding: .38rem .36rem;
    margin-top: .4rem;
    margin-bottom: .9rem;
  }
  .news_msg_content{
    width: 100%;
    padding-bottom: .3rem;
    border-bottom: 1px solid #EEE7E7;
  }
  .news_msg_content .title{
    color: #000000;
    font-size: .3rem;
    text-align: center;
  }
  .news_msg_content .time{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: #999999;
    font-size: .14rem;
    margin: .36rem 0;
  }
  .news_msg_content .time > span:nth-child(1){
    margin-right: .4rem;
  }
  .page2{
    width: 100%;
    color: rgba(17, 17, 17, 0.8);
    font-size: .16rem;
  }
  .page2 .prev span{
    margin-top: .35rem;
    cursor: pointer;
    color: #999999;
    display: inline-block;
  }
  .page2 .next span{
    margin-top: .2rem;
    cursor: pointer;
    color: #999999;
    display: inline-block;
  }
  .news_html{
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
  }
</style>