<template>
  <div class="info">
    <div class="item">
      <div class="left">姓名<span>*</span></div>
      <input class="longinp" type="text" v-model="registerData.name" placeholder="请输入真实姓名" name="" id="">
    </div>
    <div class="item">
      <div class="left">手机号码<span>*</span></div>
      <input class="longinp" type="text" v-model="registerData.phone" placeholder="请输入手机号码" name="" id="">
    </div>
    <div class="item">
      <div class="left">图片验证码<span>*</span></div>
      <div class="shortinp">
        <div>
          <input type="text" v-model="registerData.imgcode" placeholder="请输入图片验证码" name="" id="">
          <img :src="imgcode" alt="" @click="getImgCode">
        </div>
        <!-- <span style="cursor: pointer;">换一张</span> -->
      </div>
    </div>

    <div class="item">
      <div class="left">手机验证码<span>*</span></div>
      <div class="shortinp phonecode">
        <input type="text" v-model="registerData.code" placeholder="请输入短信验证码" name="" id="">
        <span class="getcode" @click="getPhoneCode">{{codetext}}</span>
      </div>
    </div>

    <div class="item">
      <div class="left">密码<span>*</span></div>
      <input class="longinp" v-model="registerData.password" type="password" placeholder="请输入登陆密码" name="" id="">
    </div>

    <div class="item">
      <div class="left">重复密码<span>*</span></div>
      <input class="longinp" v-model="registerData.repassword" type="password" placeholder="请确认密码" name="" id="">
    </div>

    <div class="item">
      <div class="left">所属企业<span>*</span></div>
      <div class="longbox">
        <el-select
          v-model="qiye"
          placeholder="请选择"
          filterable
          remote
          reserve-k
          :remote-method="search"
          @change="changeComid">
          <el-option
            v-for="item in qylist"
            :key="item.uid"
            :label="item.company"
            :value="item.uid">
          </el-option>
        </el-select>
      </div>

    </div>

    <div class="item">
      <div class="left"></div>
      <div class="btn center" @click="register">确认注册</div>
    </div>

    <div class="pop_wx" v-show="show_wx">
      <div class="shadow" @click="show_wx = false"></div>
      <div class="wx_content">
        <div class="wx_top">微信绑定</div>
        <div class="wx_pic">
          <img :src="qrcode" alt="">
          <span>扫一扫上面的二维码图案，绑定微信</span>
        </div>
      </div>
    </div>
    <ShowModal
      title="注册成功"
      content="注册成功！请等待审核"
      @confirm="confirm"
      :show="show"
      @close="close"
      />
  </div>
</template>

<script>
import ShowModal from '../../components/ShowModal'
import { getimgcode,getcompany,register,getcode,qrCodeOk,getqrcode } from '../../utils/api.js'
export default {
  name: 'BaizeRegisterUser',

  data() {
    return {
      qylist:[],
      qy: "",
      show: false,
      imgcode: "",
      registerData: {
        name: "",
        phone: "",
        imgcode: "",
        code: "",
        password: "",
        repassword: "",
        comid: "",
        type: 1
      },
      qiye: "",
      times: 120,
      codetext: "获取验证码",
      iscodeing: false,
      show_wx: false,
      qrcode: "",
      dsq: null
    };
  },

  mounted() {
    this.getImgCode()

  },

  watch: {
    show_wx(val){
      var that = this
      if(val){
        this.dsq = qrCodeOk({},res=>{
            if(res.data.closeok == 1){
              clearInterval(that.dsq)
              that.$message({
                message: res.msg,
                type: "success"
              })
              that.show_wx = false
              that.$router.push('/')
            }
          })
		
		// setInterval(()=>{
  //         qrCodeOk({},res=>{
  //           if(res.data.closeok == 1){
  //             clearInterval(that.dsq)
  //             that.$message({
  //               message: res.msg,
  //               type: "success"
  //             })
  //             that.show_wx = false
  //             that.$router.push('/')
  //           }
  //         })

  //       },1000)
        
      }else{
        clearInterval(this.dsq)
        that.$router.push('/')
      }
    }

  },

  methods: {
    changeComid(e){
      console.log(e)
      this.registerData.comid = e
    },
    bind_wx(){
      if(!this.qrcode){
        this.show_wx = true
        // getqrcode({},res=>{
        //   if(res.code == 0){
        //     this.qrcode = res.data.path
        //   }
        // })
      }else{
        this.show_wx = true
      }
    },
    search(query){
      if (query !== '') {
        getcompany({
          keyword: query
        },res=>{
          this.qylist = res.data.list
        })
        return
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = this.list.filter(item => {
            return item.label.toLowerCase()
              .indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
	
      } else {
        this.options = [];
      }
    },
    confirm(){
      this.show = false
      this.bind_wx()
    },
	// 确认
    register(){
      for (const key in this.registerData) {
        if(!this.registerData[key] && key != 'comid'){
          
          this.$message({
            message: "请正确填写信息",
            type: "error"
          })
          return false
        }
      }
      if(this.registerData.password !== this.registerData.repassword){
        this.$message({
          message: "两次输入密码不一致，请检查后重试",
          type: "error"
        })
        return false
      }
      register(this.registerData,res=>{
        
        if(res.code == 10501){
          this.$message({
            message: "此账号已注册，可直接登录",
            type: "error"
          })
          return false
        }
        if(res.code > 0 && res.code != 10501){
          this.$message({
            message: res.msg,
            type: "error"
          })
          this.getImgCode()
          return false
        }else if(res.code == 0){
          this.show = true
          this.qrcode = res.qrcode
        }
      })
      
    },
    close(){
      this.show = false 
      this.$router.push('/')
    },
    getImgCode(){
      getimgcode({},res=>{
        var that = this
        that.imgcode = ""
        if(res.code == 0){
          that.imgcode = res.data.imgcode+'?'+Math.random()
        }
      })
    },
    getPhoneCode(){
      if(!this.registerData.phone || this.registerData.phone.length != 11){
        this.$message({
          message: "请输入正确的手机号",
          type: "error"
        })
        return false
      }
      if(!this.iscodeing){
        getcode({
          phone: this.registerData.phone,
          type: '1'
        },res=>{
          var that = this
          if(res.code == 0){
            this.$message({
              message: "发送成功！请注意查收",
              type: "success"
            })
            this.iscodeing = true
            var daojishi;
            daojishi = setInterval(()=>{
              if(that.times > 0){
                that.times--
                that.codetext = '重新获取'+that.times+'s'
              }else{
                that.iscodeing = false
                clearInterval(daojishi)
                that.codetext = '获取验证码'
                that.times = 120
              }
            },1000)
          }
        })
      }

    }
  },

  components: {
    ShowModal
  }
};
</script>

<style>
  .register_content .content .info{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: .57rem 0;
    align-items: center;
  }
  .register_content .content .info .item{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: .26rem;
  }
  .register_content .content .info .item .left{
    width: .9rem;
    color: #333;
    font-size: .16rem;
    text-align: right;
  }
  .register_content .content .info .item .left span{
    color: #D90606;
  }
  .register_content .content .info .item .longinp{
    width: 3.3592rem;
    height: .44rem;
    border: .01rem solid #EDEDED;
    box-sizing: border-box;
    padding: 0 .2rem;
    margin-left: .2rem;
  }
  .register_content .content .info .item .shortinp{
    width: 3.3592rem;
    margin-left: .2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .register_content .content .info .item .shortinp div{
    width: 100%;
    height: .44rem;
    border: .01rem solid #EDEDED;
    display: flex;
    justify-content: space-between;
  }
  .register_content .content .info .item .shortinp div > input{
    width: 1.84rem;
    height: 100%;
    border: none;
    box-sizing: border-box;
    padding: 0 .2rem;
  }
  .register_content .content .info .item .shortinp div > img{
    width: 1.3rem;
    height: 100%;
  }
  .register_content .content .info .item .phonecode {
    border: .01rem solid #EDEDED;
  }
  .register_content .content .info .item .shortinp > input{
    width: 2.37rem;
    border: none;
    box-sizing: border-box;
    padding: 0 .2rem;
    height: .44rem;
  }
  .register_content .content .info .item .shortinp > .getcode{
    width: .98rem;
    height: .44rem;
    background-color: #EFEFEF;
    color: #999999;
    font-size: .14rem;
    text-align: center;
    line-height: .44rem;
    cursor: pointer;
  }
  .register_content .content .info .item .longbox{
    width: 3.3592rem;
    margin-left: .2rem;
  }
  .register_content .content .info .item .longbox .el-select{
    width: 100%;
  }
  .register_content .content .info .item .btn{
    width: 3.36rem;
    margin-left: .2rem;
    height: .44rem;
    background-color: #1C65B1;
    border-radius: .04rem;
    color: #FFFFFF;
    font-size: .16rem;
    margin-top: .48rem;
    cursor: pointer;
  }
  .pop_wx{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
  }
  .pop_wx .shadow{
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
  }
  .pop_wx .wx_content{
    width: 4.54rem;
    height: fit-content;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 102;
  }
  .pop_wx .wx_content .wx_top{
    width: 100%;
    height: .94rem;
    background: linear-gradient(90deg,#1C65B1,#0653A7);
    box-shadow: 0 .1rem .1rem rgba(28, 101, 177, .2);
    border-radius: .1rem .1rem 50% 50%;
    color: #fff;
    font-size: .16rem;
    display: flex;
    align-items: center;
    z-index: 9;
    justify-content: center;
    position: absolute;
  }
  .pop_wx .wx_content .wx_pic{
    width: 100%;
    height: 3.88rem;
    border-radius: 0 0 .1rem .1rem;
    background-color: #fff;
    z-index: -1;
    padding-top: .65rem;
    margin-top: .65rem;
  }
  .pop_wx .wx_content .wx_pic > img{
    width: 2.59rem;
    height: 2.59rem;
    display: flex;
    margin: 0 auto .26rem;
  }
  .pop_wx .wx_content .wx_pic > span{
    color: #999999;
    font-size: .12rem;
    display: block;
    margin: 0 auto;
    text-align: center;
  }
</style>