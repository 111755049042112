<template>
  <div>
    <div class="banner">
      <div v-for="(item,index) in imgs" class="progressive" style="width: 100%;height: auto;z-index: -1;position: relative">
        <img :src="item.preview" class="preview" alt="" v-progressive="item.banner" style="width: 100%;">
      </div>
      <!-- <div class="title"></div>
      <div class="desc"></div>
      <div class="inp">
        <input class="swiper-no-swiping" v-model="keyword" placeholder="请输入搜索关键词" type="text" name="" id="">
        <div @click="search">
          <i class="icon icon-search"></i>
        </div>
      </div> -->
    </div>
    <div class="w1200 contact_content">
      <div class="imgs">
        <div class="img_top" v-for="(item,index) in list" :style="index == 0 ? '' : 'margin-top: .38rem;'">
          <div class="img_top_left" :id="index == list.length - 1 ? '' : 'line'">
            <div style="margin-bottom: .04rem;">{{item.title}}</div>
            <div class="englist">{{item.etitle}}</div>
            <!-- <div class="englist">cooperation</div> -->
          </div>
          <div class="img_top_right">
            <img :src="item.thumb" alt="">
            <div class="pop" :style="index % 2 == 1 ? 'right: 0' : 'left: 0;'">
              <div class="pop_title">联系我们</div>
              <div class="pop_info" style="margin-top: .3rem;">地址： {{item.address}}</div>
              <div class="pop_info">电话：{{item.phone}}</div>
              <div class="pop_info">传真：{{item.fax}}</div>
              <div class="pop_info">电子邮件：{{item.email}}</div>
              <div class="pop_info">工作时间：{{item.worktime}} 欢迎来电咨询！</div>
            </div>
          </div>
        </div>

        <!-- <div class="img_top" id="img_right">
          <div class="img_top_left">
            <div style="margin-bottom: .04rem;">渠道合作</div>
            <div class="englist">Channel</div>
            <div class="englist">cooperation</div>
          </div>
          <div class="img_top_right">
            <img src="../assets/c1.png" alt="">
            <div class="pop2">
              <div class="pop_title">联系我们</div>
              <div class="pop_info" style="margin-top: .3rem;">地址： 山东省淄博市高新区青龙山路9509号</div>
              <div class="pop_info">电话：+ 86-188-8888888</div>
              <div class="pop_info">传真+ 86-188-8888888</div>
              <div class="pop_info">电子邮件：13232323232@163.com</div>
              <div class="pop_info">工作时间：周一至周五 [9:00-18:00] 欢迎来电咨询！</div>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div class="map" id="map"></div>
    <div class="linian">
      <div class="w1200 linian_content">
        <div class="title">公司理念</div>
        <div class="stitle">ABOUT US</div>
        <div class="linianinfo ql-editor" v-html="linian"></div>
      </div>
    </div>    
  </div>
</template>

<script>
import { getarticle,contact } from '../utils/api.js'
import AMapLoader from '@amap/amap-jsapi-loader';
import axios from 'axios'
export default {
  name: 'BaizeContactUs',

  data() {
    return {
      linian: "",
      list: [],
      imgs: [
        {
          preview: require('../assets/contactusbg-s.jpg'),
          banner: require('../assets/contactbg.png')
        }
      ]
    };
  },

  mounted() {
    this.$store.commit('setSelected',4)
    getarticle({name: 'linian'},res=>{
      this.linian = res.data.data.content
    })
    contact({},res=>{
      this.list = res.data.list
    })
    axios.post('https://bezova.com/addons/execute/map-action-getconfig.html',{}).then(res=>{
      this.initMap(res.data.config.amap_web)
    })
  },

  methods: {
    initMap(key){
      var that = this
      AMapLoader.load({
        "key": key,              // 申请好的Web端开发者Key，首次调用 load 时必填
        "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        "plugins": [],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap)=>{
          var map = new AMap.Map('map',{
            center: [118.05,36.78],
            zoom: 10
          });
          var marker = new AMap.Marker({
            position: new AMap.LngLat(118.05, 36.78),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: that.list[0]['address']
          })
          var marker2 = new AMap.Marker({
            position: new AMap.LngLat(117, 36.65),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: that.list[1]['address']
          })
          map.add(marker)
          map.add(marker2)
            
      }).catch(e => {
          
      })
    }
  },
};
</script>

<style scoped>
  .map{
    margin-top: .6rem;
  }
  .banner{
    width: 100%;
    height: 4.2rem;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
  }
  .banner .title{
    color: #fff;
    font-size: .42rem;
    padding-top: 2.9rem;
    text-align: center;
  }
  .banner .desc{
    color: #fff;
    font-size: .16rem;
    margin-top: .26rem;
    text-align: center;
  }
  .banner .inp{
    width: 5.43rem;
    height: fit-content;
    margin: .5rem auto 0;
    display: flex;

  }
  .banner .inp > input{
    width: 4.83rem;
    height: .52rem;
    box-sizing: border-box;
    padding: 0 .18rem;
    border: none;
    background-color: #fff;
    border-radius: .4rem 0 0 .4rem;
  }
  .banner .inp > div{
    flex: 1;
    background-color: #013B65;
    border-radius: 0 .4rem .4rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .contact_content{
    margin: 0 auto;
  }
  .contact_content .imgs{
    margin-top: -.7rem;
  }
  .contact_content .imgs .img_top{
    height: 2.97rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .img_top .img_top_left{
    text-align: right;
    color: #333333;
    font-size: .16rem;
    font-weight: bold;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .img_top .img_top_left .englist{
    color: #999999;
    font-size: .14rem;
    font-weight: normal;
  }
  .img_top .img_top_right{
    /* width: 10.61rem; */
    width: 88.5%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  .img_top .img_top_right img{
    width: 100%;
    border-radius: .04rem;
  }
  /* .img_top:hover .img_top_right .pop{
    left: 0;
  } */
  .img_top .img_top_right .pop{
    /* width: 4.61rem; */
    width: 43.5%;
    height: 100%;
    background: rgba(0, 0, 0, .71);
    position: absolute;  
    top: 0;
    border-radius: .04rem;
    transition: all ease .3s;
    box-sizing: border-box;
    padding: .33rem .28rem;
    color: #fff;
  }
  .img_top_right .pop .pop_title{
    font-weight: bold;
    font-size: .16rem;
    position: relative;
  }
  .img_top_right .pop .pop_title::after{
    content: "";
    width: .26rem;
    height: .03rem;
    background-color: #1C65B1;
    position: absolute;
    bottom: -.11rem;
    left: 0;
  }
  .img_top_right .pop .pop_info{
    margin-top: .2rem;
    font-size: .14rem;
  }
  
  .img_top .img_top_right .pop2{
    width: 4.61rem;
    height: 100%;
    background: rgba(0, 0, 0, .71);
    position: absolute;
    right: -100%;
    top: 0;
    border-radius: .04rem;
    transition: all ease .3s;
    box-sizing: border-box;
    padding: .33rem .28rem;
    color: #fff;
  }
  .img_top_right .pop2 .pop_title{
    font-weight: bold;
    font-size: .16rem;
    position: relative;
  }
  .img_top_right .pop2 .pop_title::after{
    content: "";
    width: .26rem;
    height: .03rem;
    background-color: #1C65B1;
    position: absolute;
    bottom: -.11rem;
    left: 0;
  }
  .img_top_right .pop2 .pop_info{
    margin-top: .2rem;
    font-size: .14rem;
  }
  #img_right:hover .img_top_right .pop2{
    right: 0;
  }

  .img_top .img_top_left::before{
    content: "";
    width: .15rem;
    height: .15rem;
    border-radius: 50%;
    background-color: #fff;
    border: .02rem solid #1C65B1;
    position: absolute;
    right: -.35rem;
  }
  #line::after{
    content: "";
    width: 0rem;
    height: 3.23rem;
    border-right: .01rem dashed #C9C3C3;
    position: absolute;
    right: -.26rem;
    top: .30rem;
  }
  .linian{
    width: 100%;
    height: fit-content;
    background-image: url('../assets/linianbg.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: .6rem;
  }
  .linian_content{
    margin: 0 auto;
    height: 100%;
  }
  .linian_content .title{
    text-align: center;
    color: #333333;
    font-size: .38rem;
    padding-top: .4rem;
    position: relative;
    display: flex;
    justify-content: center;
  }
  .linian_content .title::after{
    content: "";
    width: .4rem;
    height: .02rem;
    background-color: #1C65B1;
    position: absolute;
    bottom: -.18rem;
  }
  .linian_content .stitle{
    color: #999999;
    font-size: .22rem;
    margin-top: .25rem;
    text-align: center;
  }
  .linian_content .linianinfo{
    width: 11.28rem;
    margin: .4rem auto;
    color: #000;
    font-size: .16rem;
    line-height: .32rem;
    text-indent: 2em;
  }
  .map{
    width: 100%;
    height: 5rem;
  }
</style>