<template>
  <div>
    <div class="header">
      <div class="w1200 container">
        <img src="../../assets/logo.png" class="logo" alt="">
        <div class="listbox" :style="index == 0 ? 'margin-left: .46rem' : ''" v-for="(item,index) in list" :key="index">
          <!-- 标题 -->
          <span @click="gourl(item.url,index)" @mouseenter=" index == 2 ? showdrop = 1 : showdrop = 0" @mouseleave="showdrop = 0">{{item.title}}</span>
          <!-- 下拉菜单 -->
          <div :style="showdrop == 1 ? 'opacity: 1;overflow: hidden' : 'opacity: 0;overflow: hidden'">
            <span @click="gourl2(items.url,items.id)" :style="showdrop == 1 ? 'height: .6rem' : 'height: 0'" @mouseenter="showdrop = 1" @mouseleave="showdrop=0" v-for="(items,indexs) in item.list" :key="indexs">{{items.title}}</span>
          </div>
        </div>

        <div class="loginbox">
          <div class="login login_fill">
            <i class="icon icon-login"></i>
            <span>登录</span>
          </div>
          <div class="register" @click="register">
            <i class="icon icon-register2"></i>
            <span>注册</span>
          </div>
        </div>
        <!-- <div class="loginbox userinfo" v-else>
          <img class="headimgurl" src="../../assets/headimgurl.png" alt="">
          <span>个人中心</span>
          <span>退出</span>
        </div> -->
      </div>
    </div>

    <div class="loginbg">
      <div class="loginbox" :style="loginType == 1 ? 'height: fit-content' : ''">
        <div class="login_tabs">
          <span :class="loginType == '2' ? 'active' : ''" @click="changeLoginType('2')">手机号登录</span>
          <span :class="loginType == '1' ? 'active' : ''" @click="changeLoginType('1')">密码登录</span>
        </div>

        <div class="code_login">
          <div class="code_phone">
            <i :class="formatFocus == 'phone' ? 'icon icon-userphone-b' : 'icon icon-userphone'"></i>
            <div id="phone" class="relative center">
              <input class="phoneinp" v-model="phone" @focus="changeFocus('phone')" type="text" placeholder="请输入手机号" name="">
              <i class="icon icon-close" @click="clearPhone" style="position: absolute;right: .2rem;cursor: pointer;" v-show="phone"></i>
            </div>
          </div>
          <div class="code_phone" v-show="loginType == 2">
            <i :class="formatFocus == 'imgcode' ? 'icon icon-code1-b' : 'icon icon-code1'"></i>
            <div class="code_right">
              <div id="imgcode">
                <input type="text" v-model="imgcodetext" @focus="changeFocus('imgcode')" placeholder="请输入图形验证码" name="">
                <img :src="imgcode" class="imgcode" alt="">
              </div>
              <span @click="getImgCode">换一张</span>
            </div>
          </div>
          <div class="code_phone" v-show="loginType == 1"></div>
          <div class="code_phone" v-show="loginType == '2'">
            <i :class="formatFocus == 'code' ? 'icon icon-code2-b' : 'icon icon-code2'"></i>
            <div class="code_right2" id="code">
              <input type="text" v-model="code" @focus="changeFocus('code')" placeholder="请输入手机验证码" name="">
              <div @click="getPhoneCode">{{codetext}}</div>
            </div>
          </div>

          <div class="code_phone" v-show="loginType == '1'">
            <i :class="formatFocus == 'password' ? 'icon icon-lock-b' : 'icon icon-lock'"></i>
            <div id="password" class="relative center">
              <input :type="showpwd ? 'text' : 'password'" v-model="password" maxlength="16" @focus="changeFocus('password')" placeholder="请输入密码">
              <i class="icon icon-close" v-show="password" @click="password = ''" style="position: absolute; right: .5rem;cursor: pointer;"></i>
              <i :class="showpwd ? 'icon icon-openeyes' : 'icon icon-closeeyes'" @click="showpwd = !showpwd" v-show="password" style="position: absolute; right: .2rem;cursor: pointer;"></i>
            </div>
            
          </div>

          <div class="code_phone">
            <span></span>
            <div class="submit" @click="login">登录</div>
          </div>

          <div class="code_phone">
            <span></span>
            <div class="bottom">
              <router-link to="/register?type=1">新用户注册</router-link>
              <router-link to="/find_pwd">忘记密码?</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <PublicFooter />
  </div>
</template>

<script>
import { getimgcode,getcode,login,getsupcatelist } from '../../utils/api.js'
export default {
  name: 'BaizeLogin',

  data() {
    return {
      list: [
        {
          title: "首页",
          url: '/index_content'
        },
        {
          title: "检测中心",
          url: '/jiance_center'
        },
        {
          title: "技术支持",
          list: [
            {
              title: '技术支持1',
              url: 'jishu',
              id: 1
            },
            {
              title: '技术支持2',
              url: 'jishu',
              id: 2
            },
            {
              title: '技术支持3',
              url: 'jishu',
              id: 3
            }
          ]
        },
        {
          title: "新闻公告",
          url: '/news'
        },
        {
          title: "联系我们",
          url: '/contact_us'
        },
        {
          title: "关于我们",
          url: '/aboutus'
        },
        {
          title: "在线预约",
          url: '/yuyue'
        }
      ],
      showdrop: 0,
      loginType: "1", //登陆类型 1密码 2验证码
      formatFocus: "",
      phone: '',
      imgcode: '',
      code: '',
      password: '',
      imgcodetext: "",
      iscodeing: false,
      times: 120,
      codetext : "获取验证码",
      backurl: "",
      showpwd: false
    };
  },

  mounted() {
    getsupcatelist({},res=>{
      if(res.code == 0){
        this.list[2]['list'] = res.data.list
      }
    })
    if(this.$route.query.backurl){
      this.backurl = this.$route.query.backurl
    }
    this.getImgCode()
  },

  methods: {
    gourl(url,index){
      this.$store.commit('setSelected',index)
      this.$router.push(url)
    },
    gourl2(url,id){
      this.$store.commit('setSelected',2)
      this.$router.push({
        name: 'jishu',
        query: {
          id
        }
      })
    },
    login(){
      login({
        type: this.loginType,
        phone: this.phone,
        imgcode: this.imgcodetext,
        password: this.password,
        code: this.code
      },res=>{
        if(res.code == 0){
          localStorage.setItem('token',res.data.token)
          this.$store.commit('reFreshToken')
          if(this.backurl){
            this.$router.replace({
              name: this.backurl
            })
          }else{
            this.$router.replace({
              name: "ucenter"
            })
          }
        }else if(res.code > 0){
          this.$message({
            message: res.msg,
            type: "error",
          })
          return false
        }
      })
      
    },
    changeLoginType(e){
      if(this.loginType == e){
        return false
      }
      this.loginType = e
      this.getImgCode()
    },
    changeFocus(e){
      this.formatFocus = e
    },
    register(){
      this.$router.push({
        name: 'register',
        query: {
          type: 1
        }
      })
    },
    getImgCode(){
      getimgcode({},res=>{
        if(res.code == 0){
          this.imgcode = res.data.imgcode + '?' + Math.random()
        }
      })
    },
    getPhoneCode(){
      if(!this.phone || this.phone.length != 11){
        this.$message({
          message: "请输入正确的手机号",
          type: "error"
        })
        return false
      }
      if(!this.iscodeing){
        getcode({
          phone: this.phone,
          type: '2'
        },res=>{
          var that = this
          if(res.code == 0){
            this.$message({
              message: "发送成功！请注意查收",
              type: "success"
            })
            this.iscodeing = true
            var daojishi;
            daojishi = setInterval(()=>{
              if(that.times > 0){
                that.times--
                that.codetext = '重新获取'+that.times+'s'
              }else{
                that.iscodeing = false
                clearInterval(daojishi)
                that.codetext = '获取验证码'
                that.times = 120
              }
            },1000)
          }
        })
      }
    },
    clearPhone(){
      this.phone = ""
    }
  },
};
</script>

<style scoped>
  #phone input:focus{
    border: #1C65B1 .01rem solid;
  }
  #imgcode:focus-within{
    border: #1C65B1 .01rem solid;
  }
  #code:focus-within{
    border: #1C65B1 .01rem solid;
  }
  .header{
    width: 100%;
    height: .98rem;
    background-color: #FFFFFF;
    transition: all .3s ease;
    position: relative;
  }

  .header:hover .container .listbox > span{
    color: #333333;
  }
  .container .listbox{
    display: flex;
    height: 100%;
    width: 1.37rem;
    position: relative;
    justify-content: center;
  }
  .container .listbox:nth-child(1){
    width: 1.17rem;
  }
  .container .listbox > div{
    width: 1.37rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    z-index: -1;
    transition: all .3s ease ;
    opacity: 0;
  }
  .container .listbox > div span{
    display: inline-block;
    width: 100%;
    height: 0;
    background-color: #F9F9F9;
    color: #666666;
    font-size: .16rem;
    text-align: center;
    line-height: .6rem;
    cursor: pointer;
    transition: all .3s ease;
  }
  .container .listbox > div span:hover{
    color: #1C65B1;
    position: relative;
    transition: all .3s ease;
  }
  .container .listbox > div span:hover::after{
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: .02rem;
    background-color: #1C65B1;
    left: 0;
    transition: all .3s ease;
  }

  .container .listbox > span:hover{
    border-top: .04rem solid #1C65B1;
  }


  .header .container .loginbox > .login_fill{
    background-color: #1C65B1;
    color: #FFFFFF;
  }
  .header:hover .container .userinfo span{
    color: #666666;
  }
  .container{
    margin: 0 auto;
    display: flex;
    position: relative;
    height: 100%;
    align-items: center;
  }
  .logo{
    width: 1.46rem;
    cursor: pointer;
  }
  .container .listbox > span{
    color: #333;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 100%;
  }
  .container .loginbox{
    width: fit-content;
    display: flex;
    color: #FFFFFF;
    font-size: .14rem;
    position: absolute;
    right: -1.9rem;
  }
  .container .loginbox div{
    width: .68rem;
    height: .23rem;
    background: rgba(255, 255, 255, .1);
    border-radius: .2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #FFFFFF;
    border: 1px solid #1C65B1;
    color: #1C65B1;
  }
  .container .loginbox .register{
    margin-left: .29rem;
    position: relative;
  }
  .container .loginbox .register::before{
    content: "";
    width: .01rem;
    height: .15rem;
    background: rgba(255, 255, 255, .2);
    position: absolute;
    left: -.15rem;
  }
  .container .loginbox div span{
    margin-left: .05rem;
  }
  .zhanwei{
    height: .98rem;
  }
  .container .userinfo{
    display: flex;
    align-items: center;
    right: -1rem;
  }
  .container .userinfo > img{
    width: .42rem;
    height: .42rem;
    cursor: pointer;
    border-radius: 50%;
  }
  .container .userinfo > span{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container .userinfo > span:nth-child(2){
    margin: 0 .1rem;
    cursor: pointer;
  }

  .loginbg{
    width: 100%;
    height: 73.6vh;
    background-image: url('../../assets/loginbg-s.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }
  .loginbg .loginbox{
    width: 5.23rem;
    height: 4.97rem;
    background-color: #FFFFFF;
    border-radius: .08rem;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    box-sizing: border-box;
    padding: .32rem .28rem;
  }
  .loginbg .loginbox .login_tabs{
    width: 100%;
    padding-bottom: .11rem;
    border-bottom: .01rem solid #EFEFEF;
    display: flex;
    justify-content: center;
    color: #666666;
    font-size: .16rem;
    margin-bottom: .15rem;
  }
  .loginbg .loginbox .login_tabs span{
    cursor: pointer;
  }
  .loginbg .loginbox .login_tabs > span:nth-child(1){
    margin-right: 1.15rem;
  }
  .loginbg .loginbox .login_tabs .active{
    color: #333333;
    font-size: .18rem;
    font-weight: bold;
    position: relative;
    display: flex;
    justify-content: center;
    cursor: inherit;
  }
  .loginbg .loginbox .login_tabs .active::after{
    width: 100%;
    height: .03rem;
    background-color: #1C65B1;
    position: absolute;
    bottom: -.11rem;
    content: "";
  }
  .code_login .code_phone{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: .26rem;
    align-items: center;
  }
  .code_login .code_phone > div > .phoneinp{
    width: 4.1rem;
    height: .5rem;
    border-radius: .04rem;
    border: .01rem solid #DCDCDC;
    box-sizing: border-box;
    padding: 0 .2rem;
  }
  .code_login .code_phone .code_right{
    width: 4.1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .code_login .code_phone .code_right > div{
    display: flex;
    border: .01rem solid #DCDCDC;
    border-radius: .04rem;
  }
  .code_login .code_phone .code_right > div input{
    box-sizing: border-box;
    width: 2.55rem;
    height: .5rem;
    padding: 0 .2rem;
    border: none;
  }
  .code_login .code_phone .code_right > div .imgcode{
    width: .98rem;
    height: .5rem;
    background-color: #EFEFEF;
  }
  .code_login .code_phone .code_right > span{
    color: #666666;
    font-size: .14rem;
    cursor: pointer;
  }
  .code_login .code_phone .code_right2{
    width: 4.1rem;
    display: flex;
    border-radius: .04rem;
    border: .01rem solid #DCDCDC;
  }
  .code_login .code_phone .code_right2 > input{
    width: 3rem;
    height: .5rem;
    border: none;
    box-sizing: border-box;
    padding: 0 .2rem;
  }
  .code_login .code_phone .code_right2 > div{
    width: 1.1rem;
    height: .5rem;
    background-color: #EFEFEF;
    border-radius: 0 .04rem .04rem 0;
    text-align: center;
    line-height: .5rem;
    color: #999999;
    font-size: .14rem;
    cursor: pointer;
  }
  .code_login .code_phone .submit{
    width: 4.1rem;
    height: .56rem;
    background-color: #1C65B1;
    border-radius: .04rem;
    color: #FFFFFF;
    font-size: .18rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .28rem;
    cursor: pointer;
  }
  .code_login .code_phone .bottom{
    width: 4.1rem;
    display: flex;
    justify-content: space-between;
    color: #999999;
    font-size: .14rem;
  }
  .code_login .code_phone .bottom > a:hover{
    color: #1C65B1;
  }
  #password > input{
    width: 4.1rem;
    height: .5rem;
    border-radius: .04rem;
    border: .01rem solid #DCDCDC;
    box-sizing: border-box;
    padding: 0 .2rem;
  }
  #password > input:focus{
    border: #1C65B1 .01rem solid;
  }
</style>