<template>
  <div class="yuyue_system">
    <div>
      <div class="pinfo">
        企业账号审批
      </div>
      <div class="nums">
        <div>
          <span class="num">{{nums[0]}}</span>
          <span>全部审批/个</span>
        </div>
        <div>
          <span class="num">{{nums[1]}}</span>
          <span>待审批/个</span>
        </div>
        <div>
          <span class="num">{{nums[2]}}</span>
          <span>已通过/个</span>
        </div>
        <div>
          <span class="num">{{nums[3]}}</span>
          <span>未通过/个</span>
        </div>
      </div>
    </div>
    <div>
      <div class="pinfo">全部审批</div>
      <div class="account_table">
        <el-table
          :data="tableData"
          style="width: 100%">

          <el-table-column
            label="预约人"
            prop="name"
            align="center"
            >
          </el-table-column>
          <el-table-column
            label="手机号"
            prop="phone"
            align="center">
          </el-table-column>
          <el-table-column
            label="申请时间"
            prop="reg_time"
            align="center">
          </el-table-column>
          <el-table-column
            label="状态"
            prop="formatstatus"
            align="center">
            
          </el-table-column>
          <el-table-column label="操作" align="center" width="200">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="success"
                v-if="!scope.row.comver"
                @click="handlePass(scope.$index, scope.row)">通过</el-button>
              
              <el-button
                size="mini"
                type="danger"
                v-if="!scope.row.comver"
                @click="handleBack(scope.$index, scope.row)">驳回</el-button>

              <el-button
                size="mini"
                type="danger"
                v-if="scope.row.comver == 1 && scope.row.type == 1"
                @click="handleXianzhi(scope.$index, scope.row)">限制</el-button>

              <el-button
                size="mini"
                type="success"
                v-if="scope.row.comver == 1 && scope.row.type == 1"
                @click="handleAdmin(scope.$index, scope.row)">指定为管理员</el-button>

              <el-button
                size="mini"
                type="warning"
                v-if="scope.row.comver == -1"
                @click="handleRe(scope.$index, scope.row)">恢复</el-button>

            </template>
          </el-table-column>
        </el-table>
        <div class="page" v-if="showpage" style="justify-content: flex-end;">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="count"
            @current-change="changePage">
          </el-pagination>
        </div>
      </div>
    </div>
    <ShowModal modalType="edityy" @close="close" @confirm="confirm" changeType="edityy" :editData="editData" :show="show" />
  </div>
</template>

<script>
import ShowModal from '../../components/ShowModal'
import { verusercom,vercomuser,getaccount,acchandle } from '../../utils/api.js'
export default {
  name: 'BaizeYuyueSystem',

  data() {
    return {
      tableData: [

      ],
      show: false,
      editData: {user: {}},
      status: "",
      nums: [],
      count: 1,
      nextp: 1,
      showpage: true
    };
  },

  mounted() {
    this.status = this.$route.query.spstatus
    this.getlist(this.status)
  },

  watch: {
    status: function(){
      var that = this
      this.nextp = 1
      this.showpage = false
      setTimeout(() => {
        that.showpage = true
      }, 1);
    }
  },

  methods: {
    handlePass(index,data){
      var that = this
      this.$prompt('请输入审批意见', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(({ value }) => {
          acchandle({
            type: 'verok',
            handleuid: data.uid,
            recontent: value,
            status: 1
          },res=>{
            that.getlist(this.status)
            
          })
        }).catch(() => {
                
        });
      

      // this.editData = data
      // this.show = true
    },
    handleBack(index,data){
      var that = this
      this.$prompt('请输入审批意见', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(({ value }) => {
          acchandle({
            type: 'verok',
            handleuid: data.uid,
            recontent: value,
            status: -1
          },res=>{
            that.getlist(this.status)
          })
        }).catch(() => {
                
        });
    },
    handleXianzhi(index,data){
      var that = this
      this.$confirm('确定限制该用户吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        acchandle({
          type: 'del',
          handleuid: data.uid
        },res=>{
          that.getlist(this.status)
        })
      }).catch(() => {
                  
      });
    },
    handleAdmin(index,data){
      var that = this
      this.$confirm('确定指定为管理员吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        acchandle({
          type: 'admin',
          handleuid: data.uid
        },res=>{
          that.getlist(this.status)
        })
      }).catch(() => {
                  
      });
      

    },
    handleRe(index,data){
      var that = this
      acchandle({
        type: 'reuser',
        handleuid: data.uid
      },res=>{
        that.getlist(this.status)
      })
    },
    close(){
      this.show = false
    },
    confirm(e){
      this.show = false
    },
    getlist(status){
      getaccount({
        p: this.nextp,
        status: status
      },res=>{
        if(res.code == 0){
          if(this.nextp == 1){
            this.nums = res.data.nums
          }
          this.tableData = res.data.list
          this.count = res.data.total * 1
        }
      })
    },
    changePage(e){
      this.nextp = e
      this.getlist(this.status)
    }
  },

  components: {
    ShowModal
  }
};
</script>

<style>
  .yuyue_system{
    width: 75%;
    height: fit-content;
    background-color: #fff;
    border: .01rem solid #EEEEEE;
    box-sizing: border-box;
    padding: .2rem .4rem;
  }
  .yuyue_system .pinfo{
    width: 100%;
    padding: .2rem 0;
    border-bottom: .01rem solid #EEEEEE;
    color: #333333;
    font-size: .2rem;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }
  .yuyue_system .nums{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: .4rem 0;
  }
  .yuyue_system .nums > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 .4rem;
    transition: all .3s ease;
  }
  .yuyue_system .nums > div span{
    color: #AEAEAE;
    font-size: .14rem;

  }
  .yuyue_system .nums > div .num{
    color: #1C65B1;
    font-size: .34rem;
  }
  .yuyue_system .nums > div:hover{
    margin-top: -.05rem;
    transition: all .3s ease;
  }
</style>