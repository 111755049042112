<template>
  <div>
    <WhiteHeader />
    <div class="w1200 uc_container">
      <div class="position">您所在的位置：个人中心 <i class="icon icon-next"></i> 基本信息</div>
      <div class="userinfo">
        <div class="leftbar">
          <img style="object-fit: cover;" v-if="user.headimgurl" :src="user.headimgurl" class="headimgurl" alt="">
          <div class="name_box" v-else>{{ user.showname }}</div>

          <div class="username">{{ user.name }}</div>
          <div class="yuyue">
            <i class="icon icon-fly"></i>
            <span>预约管理</span>
          </div>
          <div class="barlist">
            <span @click="yuyuelist('')" :class="yystatusindex === '' ? 'active' : ''">全部</span>
            <span @click="yuyuelist('0')" :class="yystatusindex == '0' ? 'active' : ''">处理中</span>
            <span @click="yuyuelist('1')" :class="yystatusindex == '1' ? 'active' : ''">预约成功</span>
            <span @click="yuyuelist('2')" :class="yystatusindex == '2' ? 'active' : ''">实施中</span>
            <!-- <span @click="yuyuelist('2')" :class="yystatusindex == '1' ? 'active' : ''">实施中</span> -->
            <span @click="yuyuelist('-1')" :class="yystatusindex == '-1' ? 'active' : ''">预约失败</span>
            <span @click="yuyuelist('3,4')" :class="yystatusindex == '3,4' ? 'active' : ''">预约完成</span>
          </div>

          <div class="yuyue" v-if="user.type == 2">
            <i class="icon icon-fly"></i>
            <span>企业账号审批</span>
          </div>
          <div class="barlist" v-if="user.type == 2">
            <span @click="goshenpi('')" :class="spstatusindex == '' ? 'active' : ''">全部</span>
            <span @click="goshenpi('0')" :class="spstatusindex == '0' ? 'active' : ''">待审批</span>
            <span @click="goshenpi('1')" :class="spstatusindex == '1' ? 'active' : ''">已通过</span>
            <span @click="goshenpi('-1')" :class="spstatusindex == '-1' ? 'active' : ''">未通过</span>
          </div>

          <div class="yuyue">
            <i class="icon icon-diaocha"></i>
            <span @click="diaocha" style="cursor: pointer;">平台满意度调查</span>
            <el-badge is-dot class="item"  v-if="oldval['q0']"></el-badge>
          </div>
        </div>
        <router-view ref="child"></router-view>

      </div>
    </div>
    <PublicFooter />
  </div>
</template>

<script>
import { getuser, getvolume } from '../../utils/api.js'
export default {
  name: 'BaizeUcenter',

  data() {
    return {
      user: {},
      oldval: {}
    };
  },

  mounted() {
    if (this.$route.query.yystatus || this.$route.query.yystatus === '') {
      this.$store.commit('changeYYstatus', this.$route.query.yystatus)
    }
    if (this.$route.query.spstatus || this.$route.query.spstatus === '') {
      this.$store.commit('changeSPstatus', this.$route.query.spstatus)
    }
    getuser({}, res => {
      this.user = res.data.data.user
    })
  },

  methods: {
    getVolume() {
      getvolume({}, res => {
        if (res.code == 0) {
          this.memo = res.data.vol
          var oldval = res.data.oldval;
          res.data.list.forEach(item => {
            item.type == 1 ? item.value = "" : item.value = []
            if (oldval['q' + item['id']]) item.value = oldval['q' + item['id']];
          });
          this.jianyi = oldval['q0'];
          this.list = res.data.list
          this.oldval = res.data.oldval
        }
      })
    },
    yuyuelist(e) {
      this.$store.commit('changeSPstatus', -99)
      this.$store.commit('changeYYstatus', e)
      // this.statusindex = e
      if (this.$route.name == 'yuyue_system') {
        this.$router.push({
          name: 'yuyue_system',
          query: {
            yystatus: e
          }
        })
        this.$refs.child.nextp = 1
        this.$refs.child.getList(e)
      } else {
        this.$router.push({
          name: 'yuyue_system',
          query: {
            yystatus: e
          }
        })
      }

    },
    goshenpi(e) {
      this.$store.commit('changeYYstatus', -99)
      this.$store.commit('changeSPstatus', e)
      // this.statusindex = -99
      // this.spindex = e
      if (this.$route.name == 'ucenter_shenpi') {
        this.$router.push({
          name: 'ucenter_shenpi',
          query: {
            spstatus: e
          }
        })
        this.$refs.child.nextp = 1
        this.$refs.child.getlist(e)
      } else {
        this.$router.push({
          name: 'ucenter_shenpi',
          query: {
            spstatus: e
          }
        })
      }

    },
    diaocha() {
      this.$store.commit('changeYYstatus', -99)
      this.$store.commit('changeSPstatus', -99)
      this.$router.push('ucenter_diaocha')
    }
  },
  computed: {
    yystatusindex() {
      return this.$store.state.yystatusindex
    },
    spstatusindex() {
      return this.$store.state.spstatusindex
    }
  }
};
</script>

<style>
.uc_container {
  margin: 0 auto;
}

.uc_container .position {
  padding: .3rem 0 .4rem;
  color: #999;
  font-size: .16rem;
}

.uc_container .userinfo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: .9rem;
}

.uc_container .userinfo .leftbar {
  /* width: 2.9rem; */
  width: 24%;
  border: .01rem solid #EEEEEE;
  background-color: #fff;
  height: fit-content;
  padding-bottom: 1rem;
}

.uc_container .userinfo .leftbar .headimgurl {
  width: 1.146rem;
  height: 1.146rem;
  border-radius: 50%;
  margin: .4rem auto .3rem;
  display: block;
}

.uc_container .userinfo .leftbar .username {
  color: #333333;
  font-size: .18rem;
  text-align: center;
}

.uc_container .userinfo .leftbar .yuyue {
  width: 100%;
  box-sizing: border-box;
  padding: 0 .3rem;
  margin-top: .4rem;
  display: flex;
  align-items: center;
  color: #333333;
  font-size: .18rem;
}

.uc_container .userinfo .leftbar .yuyue span {
  margin-left: .2rem;
  font-weight: bold;

}

.uc_container .userinfo .leftbar .barlist {
  display: flex;
  flex-direction: column;
  margin-top: .3rem;
  box-sizing: border-box;
  /* height: 1.74rem; */
  justify-content: space-between;
  color: #666;
  font-size: .14rem;
}

.uc_container .userinfo .leftbar .barlist span {
  width: 100%;
  display: inline-block;
  padding: 0 0 0 .68rem;
  cursor: pointer;
  box-sizing: border-box;
  line-height: 2.5;
}

.uc_container .userinfo .leftbar .barlist .active {
  background-color: #1C65B1;
  color: #fff;
}

.name_box {
  width: 1.146rem;
  height: 1.146rem;
  border-radius: .1rem;
  margin: .4rem auto .3rem;
  display: block;
  background-color: #1C65B1;
  color: #fff;
  text-align: center;
  line-height: 1.146rem;
  font-size: 30px
}
</style>