<template>
	<div>
		<div class="header" @mouseenter="hover" @mouseleave="showlogo = 1">
			<div class="w1200 container">
				<img :src="showlogo == 1 ? require('../assets/logo.png') : require('../assets/logo.png')" class="logo"
					alt="">
				<div class="listbox" :style="index == 0 ? 'margin-left: .46rem' : ''" v-for="(item, index) in list"
					:key="index">
					<!-- 标题 -->
					<span :class="selected == index ? 'active' : ''" @click="gourl(item.url, index)"
						@mouseenter="index == 2 ? showdrop = 1 : showdrop = 0"
						@mouseleave="showdrop = 0">{{ item.title }}</span>
					<!-- 下拉菜单 -->
					<div :style="showdrop == 1 ? 'opacity: 1;overflow: hidden' : 'opacity: 0;overflow: hidden'">
						<span @click="gourl2(items.url, items.id)"
							:style="showdrop == 1 ? 'height: .6rem' : 'height: 0'" @mouseenter="showdrop = 1"
							@mouseleave="showdrop = 0" v-for="(items, indexs) in item.list"
							:key="indexs">{{ items.title }}</span>
					</div>
				</div>

				<div class="loginbox" v-if="!islogin">
					<div class="login" @click="login">
						<i :class="showlogo == 1 ? 'icon icon-login' : 'icon icon-login2'"></i>
						<span>登录</span>
					</div>
					<div class="register" @click="register">
						<i
							:class="showlogo == 1 ? 'icon icon-reheadimgurlheadimgurlgister' : 'icon icon-register2'"></i>
						<span>注册</span>
					</div>
				</div>
				<div class="loginbox userinfo" v-else  @click="gocenter">
					<img class="headimgurl" @click="gourl('/ucenter')" :src="user.headimgurl" alt=""
						v-if="user.headimgurl">
					<div style="width:.42rem; height:.42rem; border-radius:50%; background-color:rgba(9, 89, 209, 0.2);"
						v-if="!user.headimgurl">{{ user.showname }}</div>
					<span v-if="oldval['q0']" @click="gourl('/ucenter')">
						<!-- <el-badge is-dot class="item"   style="border: none;background-color:none;">个人中心</el-badge> -->
					</span>
					<span v-else  @click="gocenter">
						<el-badge class="item" style="border: none;">个人中心</el-badge>
					</span>
					<!-- <span @click="gourl('/ucenter')" >个人中心</span> -->
					<span @click="logout" style="cursor: pointer;margin-left: 10px;">退出</span>
				</div>
			</div>
		</div>

	</div>

</template>

<script>
	import {
		getsupcatelist,
		getheaderuser,
		getvolume
	} from '../utils/api.js'
	export default {
		name: 'BaizePublicHeader',

		data() {
			return {
				list: [{
						title: "首页",
						url: '/index_content'
					},
					{
						title: "检测中心",
						url: '/jiance_center'
					},
					{
						title: "技术支持",
						list: [{
								title: '技术支持1',
								url: 'jishu',
								id: 1
							},
							{
								title: '技术支持2',
								url: 'jishu',
								id: 2
							},
							{
								title: '技术支持3',
								url: 'jishu',
								id: 3
							}
						]
					},
					{
						title: "新闻公告",
						url: '/news'
					},
					{
						title: "联系我们",
						url: '/contact_us'
					},
					{
						title: "关于我们",
						url: '/aboutus'
					},
					{
						title: "在线预约",
						url: '/yuyue'
					}
				],
				showlogo: 1,
				showdrop: 0,
				user: {},
				oldval: {}
			};
		},
		computed: {
			islogin() {
				return this.$store.state.token
			},
			selected() {
				return this.$store.state.selected
			}
		},
		props: {
			placeholder: {
				type: Boolean,
				default: false
			}
		},

		mounted() {

			getsupcatelist({}, res => {
				if (res.code == 0) {
					this.list[2]['list'] = res.data.list
					this.$store.commit('setQrcode', res.data.qrcode[0])
					let bottom = res.data
					this.$store.commit('setBottom', bottom)
				}
			})
			getheaderuser({}, res => {
				if (res.code == 0) {
					if (res.data.data.user) {
						this.user = res.data.data.user
						this.$store.commit('reFreshToken')
					} else {
						this.$store.commit('removeToken')
					}

				}
			})
		},

		methods: {
			getVolume() {
				getvolume({}, res => {
					if (res.code == 0) {
						this.memo = res.data.vol
						var oldval = res.data.oldval;
						res.data.list.forEach(item => {
							item.type == 1 ? item.value = "" : item.value = []
							if (oldval['q' + item['id']]) item.value = oldval['q' + item['id']];
						});
						this.jianyi = oldval['q0'];
						this.list = res.data.list
						this.oldval = res.data.oldval
					}
				})
			},
			hover() {
				this.showlogo = 2
			},
			gourl(url, index) {
				console.log("路由", url, index);
				this.$store.commit('setSelected', index)
				this.$router.push(url)
			},
			gourl2(url, id) {
				this.$store.commit('setSelected', 2)
				this.$router.push({
					name: 'jishu',
					query: {
						id
					}
				})
			},
			login() {
				this.$router.push('/login')
			},
			register() {
				this.$router.push({
					name: 'register',
					query: {
						type: 1
					}
				})
			},
			logout() {
				this.$confirm('确认退出登录吗?', '提示', {
					confrimButtonText: '确定',
					cancelButtonText: '取消',
					type: "warning",
				}).then((result) => {
					this.$store.commit('removeToken');
				}).catch((err) => {

				});
			},
			// 个人中心
			gocenter() {
				console.log("路由");
				this.$router.push('/ucenter/ucenter_info')
			}

		},
	};
</script>

<style>
	.header {
		width: 100%;
		height: .98rem;
		background-color: rgba(255, 255, 255, .02);
		position: absolute;
		top: 0;
		z-index: 99;
		transition: all .3s ease;
	}

	.header:hover {
		background-color: #FFFFFF;
		transition: all .3s ease;
	}

	.header:hover .container .listbox>span {
		color: #333333;
	}

	.container .listbox {
		display: flex;
		height: 100%;
		padding: 0 0.15rem;
		position: relative;
		justify-content: center;
	}

	.header:hover .listbox .active {
		color: #1C65B1 !important;
		font-weight: bold;
		border-top: .04rem solid #1C65B1;
	}

	.container .listbox:nth-child(1) {
		width: 1.17rem;
	}

	.container .listbox>div {
		width: 1.37rem;
		height: fit-content;
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 100%;
		z-index: -1;
		transition: all .3s ease;
		opacity: 0;
	}

	.container .listbox>div span {
		display: inline-block;
		width: 100%;
		height: 0;
		background-color: #F9F9F9;
		color: #666666;
		font-size: .16rem;
		text-align: center;
		line-height: .6rem;
		cursor: pointer;
		transition: all .3s ease;
	}

	.container .listbox>div span:hover {
		color: #1C65B1;
		position: relative;
		transition: all .3s ease;
	}

	.container .listbox>div span:hover::after {
		content: "";
		position: absolute;
		bottom: 0;
		width: 100%;
		height: .02rem;
		background-color: #1C65B1;
		left: 0;
		transition: all .3s ease;
	}

	.container .listbox>span:hover {
		border-top: .04rem solid #1C65B1;
	}

	.header:hover .container .loginbox {
		color: #1C65B1;
	}

	.header:hover .container .loginbox div {
		background-color: #FFFFFF;
		border: 1px solid #1C65B1;
	}

	.header:hover .container .userinfo span {
		color: #666666;
	}

	.container {
		margin: 0 auto;
		display: flex;
		position: relative;
		height: 100%;
		align-items: center;
	}

	.logo {
		width: 1.46rem;
		cursor: pointer;
	}

	.container .listbox>span {
		color: #FFFFFF;
		font-size: 0.16rem;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		width: fit-content;
		height: 100%;
	}

	.container .loginbox {
		width: fit-content;
		display: flex;
		color: #FFFFFF;
		font-size: .14rem;
		position: absolute;
		right: -1.9rem;
	}

	.container .loginbox div {
		width: .68rem;
		height: .23rem;
		background: rgba(255, 255, 255, .1);
		border-radius: .2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.container .loginbox .register {
		margin-left: .29rem;
		position: relative;
	}

	.container .loginbox .register::before {
		content: "";
		width: .01rem;
		height: .15rem;
		background: rgba(255, 255, 255, .2);
		position: absolute;
		left: -.15rem;
	}

	.container .loginbox div span {
		margin-left: .05rem;
	}

	.zhanwei {
		height: .98rem;
	}

	.container .userinfo {
		display: flex;
		align-items: center;
		right: -1rem;
		/* right: 0 !important; */
	}

	@media screen and (max-width: 1400px) {

		.container .loginbox,
		.container .userinfo {
			right: 0 !important;
		}
	}

	.container .userinfo>img {
		width: .42rem;
		height: .42rem;
		cursor: pointer;
		border-radius: 50%;
		margin-right: 10px;
	}

	.container .userinfo>span {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.container .userinfo>span:nth-child(2) {
		margin: 0 .1rem;
		cursor: pointer;
	}
</style>
