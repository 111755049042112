<template>
	<div v-show="show" class="modalBox">
		<div class="modal" v-if="modalType == 'modal'">
			<div class="title">温馨提示</div>
			<img class="status"
				:src="mType == 'success' ? require('../assets/success.png') : require('../assets/fail.png')" alt="">
			<div class="stitle">{{title}}</div>
			<div class="content" v-html="content"></div>
			<div class="confirm center" @click="confirm">确认</div>
			<img src="../assets/close.png" @click="close" class="close" alt="">
		</div>
		<div class="inp_modal" v-else>
			<div class="title">{{text}}</div>
			<!-- 编辑预约时显示 -->
			<div class="inp_box" v-if="modalType == 'edityy'">
				<div class="phone">
					<span>检测项目</span>
					<div class="rightbox">
						<input type="text" disabled v-model="editData.protitle" style="border-left: none;"
							placeholder="请输入检测项目" name="" id="">
					</div>
				</div>
				<div class="phone">
					<span>委托单号</span>
					<div class="rightbox">
						<input type="text" disabled v-model="editData.orderid" style="border-left: none;"
							placeholder="请输入委托单号" name="" id="">
					</div>
				</div>
				<div class="phone">
					<span>填单人</span>
					<div class="rightbox">
						<input type="text" disabled v-model="editData.user.name" style="border-left: none;"
							placeholder="请输入填单人" name="" id="">
					</div>
				</div>
				<div class="phone">
					<span>手机号</span>
					<div class="rightbox">
						<input type="text" disabled v-model="editData.user.phone" style="border-left: none;"
							placeholder="请输入手机号" name="" id="">
					</div>
				</div>
				<div class="phone">
					<span>预约时间</span>
					<div class="rightbox">
						<el-date-picker v-model="editData.starttime" type="datetime" placeholder="选择日期时间"
							value-format="yyyy-MM-dd HH:mm" :picker-options="pickerOptions" size="large"
							:readonly="editData.disabled">
						</el-date-picker>
					</div>
				</div>
				<div class="phone">
					<span>状态</span>
					<div class="rightbox">
						<input type="text" disabled v-model="editData.formatstatus" style="border-left: none;"
							placeholder="请输入状态" name="" id="">
					</div>
				</div>
			</div>
			<!-- 其他显示 -->
			<div class="inp_box" v-else>
				<div class="phone" v-show="changeType == 'email'">
					<span>邮箱</span>

					<div class="rightbox">
						<input type="text" v-model="editData.email" style="border-left: none;" placeholder="请登录邮箱获取验证码"
							name="" id="">
					</div>
				</div>

				<div class="phone" v-show="changeType == 'addAccount' || changeType == 'edit'">
					<span>姓名</span>
					<div class="rightbox">
						<input type="text" v-model="editData.name" style="border-left: none;" placeholder="请输入姓名"
							name="" id="">
					</div>
				</div>

				<div class="phone"
					v-show="changeType == 'phone' || changeType == 'addAccount' || changeType == 'edit' || changeType == 'password'">
					<span>手机号</span>
					<div class="rightbox">
						<i class="icon icon-login2"></i>
						<input :disabled="changeType == 'email' || changeType == 'password'" type="text"
							placeholder="请输入手机号" v-model="editData.phone" name="" id="">
					</div>
				</div>

				<div class="code"
					v-show="changeType == 'email' || changeType == 'phone' || changeType == 'password' || changeType == 'addAccount'">
					<span>验证码</span>
					<div class="rightbox">
						<input type="text" v-model="editData.code" placeholder="请输入短信验证码" name="" id="">
						<span @click="getCode">{{codetext}}</span>
					</div>
				</div>

				<div class="phone" v-show="changeType == 'addAccount' || changeType == 'edit'">
					<span>密码</span>
					<div class="rightbox">
						<input :type="changeType == 'edit' ? 'text' : 'password'" v-model="editData.password"
							style="border-left: none;" placeholder="请输入密码" name="" id="">
					</div>
				</div>

				<div class="phone" v-show="changeType == 'password'">
					<span>新密码</span>
					<div class="rightbox">
						<input type="password" v-model="editData.password" style="border-left: none;"
							placeholder="请输入新密码" name="" id="">
					</div>
				</div>

				<div class="phone" v-show="changeType == 'password'">
					<span>确认密码</span>
					<div class="rightbox">
						<input type="password" v-model="editData.repassword" style="border-left: none;"
							placeholder="请重复输入密码" name="" id="">
					</div>
				</div>
			</div>

			<div class="btns" v-if="!editData.disabled">
				<span class="cancel" @click="close">取消</span>
				<span class="confirm" @click="confirm">保存</span>
			</div>
			<div class="btns" v-else>
				<span class="cancel" @click="close">关闭</span>
				<span class="confirm" @click="download_baogao" v-if="editData.baogao">下载报告</span>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getcode,
		sendemailcode
	} from '../utils/api.js'
	export default {
		name: 'BaizeShowmodal',

		props: {
			myEmail: {
				type: String,
				default: '111'
			},
			mType: {
				type: String,
				default: 'success'
			},
			title: {
				type: String,
				default: ""
			},
			content: {
				type: String,
				default: ""
			},
			show: {
				type: Boolean,
				default: false
			},
			modalType: {
				type: String,
				default: 'modal'
			},
			changeType: {
				type: String,
				default: 'phone'
			},
			editData: {
				type: Object,
				default: function() {
					return {

					}
				}
			}
		},

		data() {
			return {
				text: "",
				codetext: "获取验证码",
				times: 120,
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() < Date.now()
					}
				},
				iscodeing: false,
				myEmail: ''
			};
		},
		created() {
			// .将props的值赋值给子组件里的数组容器从而展示父组件传过来的数据
			this.myEmail = this.myEmail
		},
		mounted() {
			this.$nextTick(() => {
				this.editData.password = ""
			});
		},

		methods: {
			download_baogao() {
				location.href = this.editData.baogao
			},
			confirm() {
				switch (this.changeType) {
					case 'phone':
						this.$emit('confirm', this.editData);
						break;
					case 'email':
						this.$emit('confirm', this.editData);
						break;
					case 'password':
						this.$emit('confirm', this.editData);
						break;
					case 'addAccount':
						this.$emit('confirm', this.editData);
						this.editData.password = ""
						this.editData.name = ''
						this.editData.phone = ''
						break;
					case 'edit':
						this.$emit('confirm', this.editData);
						break;
					case 'edityy':
						this.$emit('confirm', this.editData)
						break;
				}

				this.times = 0

			},
			close() {
				this.times = 0
				this.$emit('close')
			},
			getCode() {
				switch (this.changeType) {
					case 'phone':
						if (!this.editData.phone || this.editData.phone.length != 11) {
							this.$message({
								message: "请输入正确手机号码",
								type: "error"
							})
							return false
						}
						getcode({
							phone: this.editData.phone,
							type: 1
						}, res => {
							var that = this
							if (res.code == 0) {
								this.$message({
									message: "发送成功！请注意查收",
									type: "success"
								})
								this.iscodeing = true
								var daojishi;
								daojishi = setInterval(() => {
									if (that.times > 0) {
										that.times--
										that.codetext = '重新获取' + that.times + 's'
									} else {
										that.iscodeing = false
										clearInterval(daojishi)
										that.codetext = '获取验证码'
										that.times = 120
									}
								}, 1000)
							}
						})
						break;
					case 'email':
						// if(!this.editData.phone || this.editData.phone.length != 11){
						//   this.$message({
						//     message: "请输入正确手机号码",
						//     type: "error"
						//   })
						//   return false
						// }

						if (this.editData.email == this.myEmail) {
							console.log(this.myEmail);
							this.$message({
								message: '此邮箱已绑定',
								type: "error"
							})
							return
						}

						sendemailcode({
							email: this.editData.email
						}, res => {

							var that = this
							if (res.code == 0) {
								this.$message({
									message: "发送成功！请注意查收",
									type: "success"
								})
								this.iscodeing = true
								var daojishi;
								daojishi = setInterval(() => {
									if (that.times > 0) {
										that.times--
										that.codetext = '重新获取' + that.times + 's'
									} else {
										that.iscodeing = false
										clearInterval(daojishi)
										that.codetext = '获取验证码'
										that.times = 120
									}
								}, 1000)
							}
						})
						break;
					case 'addAccount':
						if (!this.editData.phone || this.editData.phone.length != 11) {
							this.$message({
								message: "请输入正确手机号码",
								type: "error"
							})
							return false
						}
						getcode({
							phone: this.editData.phone,
							type: 2
						}, res => {
							var that = this
							if (res.code == 0) {
								this.$message({
									message: "发送成功！请注意查收",
									type: "success"
								})
								this.iscodeing = true
								var daojishi;
								// 定时器
								daojishi = setInterval(() => {
									if (that.times > 0) {
										that.times--
										that.codetext = '重新获取' + that.times + 's'
									} else {
										that.iscodeing = false
										clearInterval(daojishi)
										that.codetext = '获取验证码'
										that.times = 120
									}
								}, 1000)
							}
						})
						break;
					case 'password':
						if (!this.editData.phone || this.editData.phone.length != 11) {
							this.$message({
								message: "请输入正确手机号码",
								type: "error"
							})
							return false
						}
						getcode({
							phone: this.editData.phone,
							type: 2
						}, res => {
							var that = this
							if (res.code == 0) {
								this.$message({
									message: "发送成功！请注意查收",
									type: "success"
								})
								this.iscodeing = true
								var daojishi;
								daojishi = setInterval(() => {
									if (that.times > 0) {
										that.times--
										that.codetext = '重新获取' + that.times + 's'
									} else {
										that.iscodeing = false
										clearInterval(daojishi)
										that.codetext = '获取验证码'
										that.times = 120
									}
								}, 1000)
							}
						})
						break;
				}
			}
		},

		watch: {
			changeType(value) {
				switch (value) {
					case 'phone':
						this.text = '修改手机'
						break;
					case 'email':
						this.text = '修改邮箱'
						break;
					case 'password':
						this.text = '修改密码'
						break;
					case 'addAccount':
						this.text = '增加账号'
						break;
					case 'edit':
						this.text = '编辑账号'
						break;
					case 'edityy':
						this.text = '预约编辑'
						break;
				}
			}
		}
	};
</script>

<style>
	.rightbox>.el-input--prefix .el-input__inner {
		border: none;
	}

	.modalBox {
		width: 100%;
		height: 100vh;
		position: fixed;
		z-index: 9;
		top: 0;
		left: 0;
		background: transparent;
		/* background: rgba(0, 0, 0, .5); */
	}

	.modal {
		width: 4.97rem;
		height: 3.24rem;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		margin: auto;
		background-color: #fff;
		box-shadow: 0 0 .2rem rgba(28, 101, 177, .2);
		box-sizing: border-box;
		padding: .22rem .42rem;
	}

	.modal .title {
		color: #242424;
		font-weight: bold;
		font-size: .16rem;
	}

	.modal .status {
		width: .5652rem;
		height: .5652rem;
		margin: .2rem auto;
		display: block;
	}

	.modal .stitle {
		width: 100%;
		color: #242424;
		font-size: .18rem;
		text-align: center;
		margin: .11rem 0;
	}

	.modal .content {
		text-align: center;
		width: 100%;
		line-height: .32rem;
		color: #656565;
		font-size: .14rem;
	}

	.modal .content a {
		color: #1C65B1;
	}

	.modal .confirm {
		width: 1.41rem;
		height: .44rem;
		background-color: #1C65B1;
		border-radius: .04rem;
		color: #fff;
		font-size: .16rem;
		margin: .3rem auto 0;
		cursor: pointer;
	}

	.modal .close {
		position: absolute;
		right: 0;
		top: -.42rem;
		width: .3rem;
		height: .3rem;
		cursor: pointer;
	}

	.inp_modal {
		width: 5.34rem;
		/* max-width: 534px; */
		min-height: 3.4rem;
		background-color: #fff;
		box-shadow: 0 0 .2rem rgba(28, 101, 177, .2);
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		margin: auto;
		height: fit-content;
		box-sizing: border-box;
		padding: .4rem;
	}

	.inp_modal .title {
		color: #333;
		font-size: .2rem;
		margin-bottom: .2rem;
	}

	.inp_modal .inp_box {
		width: 100%;
		color: #666666;
		font-size: .16rem;
	}

	.inp_box>div {
		margin-top: .2rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.inp_box .phone {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.inp_box>div .rightbox {
		width: 3.6rem;
		/* width: 360px; */
		height: .5rem;
		border: .01rem solid #EDEDED;
		border-radius: .05rem;
		display: flex;
		align-items: center;
	}

	.inp_box .phone .rightbox .icon {
		margin: 0 .22rem;
	}

	.inp_box .phone .rightbox>input {
		flex: .9;
		height: .28rem;
		border: none;
		border-left: .01rem solid #EDEDED;
		box-sizing: border-box;
		padding: 0 .2rem;
	}

	.inp_box .code>.rightbox {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.inp_box .code input {
		box-sizing: border-box;
		padding: 0 .2rem;
		border: none;
		width: calc(100% - 1.1rem);
	}

	.inp_box .code .rightbox span {
		width: 1.1rem;
		height: .28rem;
		border-left: .01rem solid #EDEDED;
		cursor: pointer;
		color: #1C65B1;
		font-size: .14rem;
		text-align: center;
		line-height: .28rem;
		white-space: nowrap;
	}

	.btns {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: .4rem;
	}

	.btns span {
		width: .94rem;
		height: .42rem;
		border-radius: .05rem;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: .16rem;
		cursor: pointer;
	}

	.btns .cancel {
		border: .01rem solid #1C65B1;
		color: #1C65B1;
	}

	.btns .confirm {
		background-color: #1C65B1;
		border: .01rem solid #1C65B1;
		color: #fff;
		margin-left: .2rem;
	}
</style>
