<template>
  <div class="js_content">
    <div class="top">
      <span>技术成员/及简介</span>
      <div class="position">
        <span>您所在的位置：<router-link to="/">首页 </router-link>> 技术支持</span>
      </div>
    </div>

    <div class="userlist">
      <div v-for="(item,index) in userlist" class="user_item" :key="index">
        <div class="imgbox">
          <img :src="item.thumb" alt="" style="cursor: pointer;" @click="goinfo(item.id)">
        </div>
        
        <div class="name" style="cursor: pointer;" @click="goinfo(item.id)">{{item.title}}</div>
        <div class="label">{{item.memo}}</div>
      </div>
      <div v-if="!userlist || userlist.length < 1" style="width: 100%;">
        <el-empty :image-size="200"></el-empty>
      </div>
    </div>
    <div class="page" v-if="count > 0">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="count"
        @current-change="changePage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getsuplist } from '../utils/api.js'
export default {
  name: 'BaizeJishuPeople',

  data() {
    return {
      userlist: [

      ],
      nextp: 1,
      count: 0,
      cateid: ""
    };
  },

  mounted() {
    if(this.$route.query.isback){
      this.getlist(this.$route.query.cateid,1)
    }
    // this.getlist()
  },

  methods: {
    getlist(id,p){
      getsuplist({p: p,cateid: id},res=>{
        if(res.code == 0){
          this.userlist = res.data.list
          this.count = res.data.total
        }
      })
    },
    goinfo(id){
      this.$router.push({
        name: 'jishu_content',
        query: {
          cid: id,
          id: this.$route.query.id
        }
      })
    },
    changePage(e){
      this.$emit('refresh', {nextp: e});
    }
  },
  watch: {
    $route(){
      this.cateid = this.$route.query.id
    },
    cateid(){
      this.getlist(this.cateid,1)
    }
  }
};
</script>

<style>
  .js_content .userlist{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .js_content .userlist .user_item{
    width: 4.32rem;
    height: 3.79rem;
    background-color: #F8F8FA;
    margin-bottom: .24rem;
  }
  .js_content .userlist .user_item > .imgbox{
    width: 100%;
    height: 2.35rem;
    overflow: hidden;
  }
  .js_content .userlist .user_item > img{
    width: 100%;
    height: auto;
  }
  .js_content .userlist .user_item .name{
    width: 100%;
    box-sizing: border-box;
    padding: .22rem .18rem;
    color: #333;
    font-size: .16rem;
  }
  .js_content .userlist .user_item .label{
    width: 100%;
    box-sizing: border-box;
    padding: 0 .18rem .22rem;
    color: #CCCCCC;
    font-size: .14rem;
    line-height: .24rem;
    height: .48rem;
    overflow: hidden;
  }
</style>