<template>
  <div>
    <div class="swiper-container" id="banner">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in imgs" :key="index">
          <div class="banner">
            <div
              class="progressive"
              style="width: 100%; height: auto; z-index: -1; position: absolute"
            >
              <img
                class="preview"
                :src="item.preview"
                alt=""
                v-progressive="item.banner"
                style="width: 100%"
              />
            </div>

            <div class="title">白泽检测中心</div>
            <div class="desc">为您提供全面系统，真实可靠的检测服务体系</div>
            <div class="inp">
              <input
                class="swiper-no-swiping"
                v-model="keyword"
                placeholder="请输入搜索关键词"
                type="text"
                name=""
                id=""
              />
              <div @click="search">
                <i class="icon icon-search"></i>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="swiper-slide">
          <img src="../assets/banner.png" class="banner" alt="">
        </div> -->
      </div>
      <div class="swiper-pagination"></div>
    </div>
    <div class="w1200 container">
      <div class="jiancexiangmu">
        <div class="title">我们的检测项目</div>
        <div class="stitle">TEST ITEMS</div>
        <div class="items">
          <span
            v-show="index < 5"
            :class="xiangmuindex == index ? 'active' : ''"
            @click="changexiangmu(index, item.id)"
            v-for="(item, index) in itemlist"
            :key="index"
            >{{ item.title }}</span
          >
          <div class="next" @click="learnMore">
            <span>了解更多</span>
            <i class="icon icon-next"></i>
          </div>
        </div>
        <div class="xiangmu">
          <div
            class="xiangmu_item"
            :style="index % 4 == 3 ? 'margin-right: 0' : ''"
            v-if="index < 4"
            v-for="(item, index) in xiangmulist"
            :key="index"
          >
            <div  class="thumb">
              <img
              :src="item.thumb"
              @click="gojiance(item.cateid, item.cateid2, item.id)" style="width: 100%;"
              alt=""
            />
            </div>
            <div class="info">
              <div
                class="stitle"
                @click="gojiance(item.cateid, item.cateid2, item.id)"
              >
                {{ item.title }}
              </div>
              <div class="desc">{{ item.memo }}</div>
              <div
                class="btn"
                @click="gojiance(item.cateid, item.cateid2, item.id)"
              >
                立即预约
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :style="
        'height: 100%;background-image: url(' +
        require('../assets/anlibg.png') +
        ');background-size: cover;padding-bottom: .6rem'
      "
    >
      <div class="w1200 container">
        <div class="jiancexiangmu case">
          <div class="title">案例展示</div>
          <div class="stitle">CASE DISPLY</div>
          <div class="case_content">
            <div class="case_left">
              <span
                @mouseleave="autoplay = true"
                @mouseenter="changeCase(index, item)"
                :class="caseindex == index ? 'active' : ''"
                v-for="(item, index) in caselist"
                :key="index"
                >{{ item.title }}</span
              >
            </div>
            <div
              class="case_center"
              :style="'background-image: url(' + case_content.thumb + ')'"
            >
              <div>{{ case_content.memo }}</div>
            </div>
            <div class="case_right" style="overflow: hidden">
              <img
                v-for="(item, index) in case_content.pics"
                :key="index"
                :src="item"
                alt=""
              />
              <!-- <img src="../assets/case3.png" alt=""> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w1200 container">
      <div class="jiancexiangmu aboutus">
        <div class="title">关于我们</div>
        <div class="stitle">ABOUT US</div>
        <div class="gongsijianjie">
          <img :src="aboutusData.thumb" alt="" />
          <div class="info">
            <div>公司简介</div>
            <div
              class="jianjie ql-editor"
              style="
                word-wrap: break-word;
                word-break: break-all;
                overflow: hidden;
              "
              v-html="aboutusData.content"
            ></div>
            <div class="btn" @click="goaboutus">了解更多</div>
          </div>
        </div>
      </div>
    </div>

    <div
      :style="
        'height: 100%;background-image: url(' +
        require('../assets/zhengshubg.png') +
        ');background-size: contain;padding-bottom: .6rem;background-position: bottom;background-repeat: no-repeat;'
      "
    >
      <div class="w1200 container">
        <div class="jiancexiangmu zizhizhengshu">
          <div class="title">资质证书</div>
          <div class="stitle">QU ALIFICATION CERTIFICATE</div>
          <div style="position: relative; height: fit-content">
            <div class="swiper-container" id="zizhi">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in zizhilist"
                  :key="index"
                >
                  <el-image
                    style="width: 2.648rem; height: 3.828rem"
                    :src="item.thumb[0]"
                    :preview-src-list="srcList"
                  >
                  </el-image>
                  <!-- <img class="zizhi"  alt="" @click="gozizhi(item.url)"> -->
                </div>
              </div>
            </div>
            <div class="swiper-button-prev" id="prev1"></div>
            <div class="swiper-button-next" id="next1"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="w1200 container">
      <div class="jiancexiangmu hezuo">
        <div class="title">合作伙伴</div>
        <div class="stitle">COOPERATIVE PARTNER</div>
        <div style="position: relative; height: fit-content">
          <div class="swiper-container" id="hezuo">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in partnerList"
                :key="index"
              >
                <img
                  class="huoban"
                  @click="gohezuo(item.url)"
                  :src="item.thumb"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="swiper-button-prev" id="prev"></div>
          <div class="swiper-button-next" id="next"></div>
        </div>
        <div
          style="margin: 0.4rem 0 0.6rem; color: #999999; font-size: 0.16rem"
        >
          累计多家知名企业
        </div>
      </div>
    </div>

    <div class="bottom">
      <div class="title">安全检测服务</div>
      <div class="stitle">
        10年服务经验积累，9种安全保障，8种服务场景，优质服务值得你信赖
      </div>
      <div class="btn" @click="learnMore">去查看 》</div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import {
  getarticle,
  certificate,
  partner,
  getcaselist,
  getcate,
  getprojectlist,
} from "../utils/api.js";
export default {
  name: "BaizeIndexContent",

  data() {
    return {
      banner: "",
      itemlist: [],
      xiangmulist: [],
      xiangmuindex: 0,
      caselist: [],
      caseindex: 0,
      aboutusData: "",
      zizhilist: [],
      partnerList: [],
      cateid: "",
      nextp: 1,
      case_content: {
        pics: [],
      },
      srcList: [],
      keyword: "",
      imgs: [
        {
          preview: require("../assets/banner-s.jpg"),
          banner: require("../assets/banner.png"),
        },
      ],
      autoplay: false,
      dsq: null,
	  
	   imageUrl: '',
	   
    };
  },

  mounted() {
    this.getCate();
    this.getArticle();
    this.getCertificate();
    this.getPartner();
    this.getCaseList();
    this.init();
    this.banner = new Swiper("#banner", {
      pagination: ".swiper-pagination",
      loop: false,
    });
  },

  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#F6F6F6");
  },

  watch: {
    autoplay(val) {
      var that = this;
      if (val) {
        clearInterval(this.dsq);
        this.dsq = setInterval(() => {
          if (that.caseindex < 3) {
            that.caseindex++;
            that.case_content = that.caselist[that.caseindex];
          } else {
            that.caseindex = 0;
            that.case_content = that.caselist[0];
          }
        }, 3000);
      } else {
        clearInterval(this.dsq);
      }
    },
  },

  methods: {

			
    init() {
      var that = this;
      this.dsq = setInterval(() => {
        if (that.caseindex < this.caselist.length - 1) {
          that.caseindex++;
          that.case_content = that.caselist[that.caseindex];
        } else {
          that.caseindex = 0;
          that.case_content = that.caselist[0];
        }
      }, 3000);
    },
    getCate() {
      getcate({}, (res) => {
				console.log("数据",res);
        if (res.code == 0) {
          this.itemlist = res.data.list;
          this.cateid = res.data.list[0].id;
          this.getlist(this.cateid, "");
          // this.$refs.child.getlist(this.cateid,this.cateid2,this.keyword)
        }
      });
    },
    getlist(cateid, keyword) {
      getprojectlist(
        {
          p: this.nextp,
          cateid: cateid,
          cateid2: "",
          keyword: keyword,
        },
        (res) => {
          if (res.code == 0) {
            this.xiangmulist = res.data.list;
						console.log("xiangmu", this.xiangmulist );
            // this.count = res.data.total * 1
          }
        }
      );
    },
    changexiangmu(index, id) {
      this.xiangmuindex = index;
      this.cateid = id;
      this.getlist(this.cateid);
    },
    getArticle() {
      getarticle(
        {
          name: "about",
        },
        (res) => {
          this.aboutusData = res.data.data;
        }
      );
    },
    getCertificate() {
      certificate({}, (res) => {
        if (res.code == 0) {
          this.zizhilist = res.data.list;
          res.data.list.forEach((item) => {
            this.srcList.push(item.thumb[0]);
          });
          this.$nextTick(() => {
            new Swiper("#zizhi", {
              slidesPerView: 4,
              spaceBetween: 23,
              prevButton: "#prev1",
              nextButton: "#next1",
            });
          });
        }
      });
    },
    getPartner() {
      partner({}, (res) => {
        this.partnerList = res.data.list;
        this.$nextTick(() => {
          new Swiper("#hezuo", {
            slidesPerView: 5,
            spaceBetween: 26,
            prevButton: "#prev",
            nextButton: "#next",
          });
        });
      });
    },
    getCaseList() {
      getcaselist({}, (res) => {
        this.caselist = res.data.list;
        this.case_content = res.data.list[0];
      });
    },
    changeCase(index, item) {
      this.caseindex = index;
      this.case_content = item;
      this.autoplay = false;
    },
    gozizhi() {
      // if(url){
      //   location.href = url
      // }else{
      // }
    },
    gohezuo(url) {
      location.href = url;
    },
    learnMore() {
      this.$router.push({
        name: "jiance_list",
      });
			// 滚动条置顶
				window.scrollTo(0,0);
    },
    gojiance(cateid, cateid2, id) {
			console.log(cateid, cateid2, id);
      this.$router.push({
        name: "jiance_info",
        query: {
          cateid,
          cateid2,
          id,
        },
      });
    },
    goaboutus() {
      this.$router.push({
        name: "company_info",
      });
    },
    search() {
      if (this.keyword) {
        this.$router.push({
          name: "search_result",
          query: {
            keyword: this.keyword,
          },
        });
      } else {
        this.$message({
          message: "请输入搜索内容",
          type: "error",
        });
      }
    },
  },
};
</script>

<style scoped>
.banner {
  width: 100%;
  height: 6rem;

  background-size: contain;
  background-repeat: no-repeat;
}

.banner .title {
  color: #fff;
  font-size: 0.42rem;
  padding-top: 2.9rem;
  text-align: center;
}

.banner .desc {
  color: #fff;
  font-size: 0.21rem;
  margin-top: 0.26rem;
  text-align: center;
}

.banner .inp {
  width: 5.43rem;
  height: fit-content;
  margin: 1rem auto 0;
  display: flex;
}

.banner .inp > input {
  width: 4.83rem;
  height: 0.52rem;
  box-sizing: border-box;
  padding: 0 0.18rem;
  border: none;
  background-color: #fff;
  border-radius: 0.4rem 0 0 0.4rem;
}

.banner .inp > div {
  flex: 1;
  background-color: #013b65;
  border-radius: 0 0.4rem 0.4rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.container .jiancexiangmu {
  text-align: center;
  width: 100%;
  padding-top: 0.6rem;
  color: #333333;
}

.container .jiancexiangmu .title {
  font-size: 0.38rem;
  padding-bottom: 0.2rem;
  position: relative;
  display: flex;
  justify-content: center;
}

.container .jiancexiangmu .title::after {
  content: "";
  width: 0.4rem;
  height: 0.02rem;
  background-color: #1c65b1;
  position: absolute;
  bottom: 0;
}

.container .jiancexiangmu .stitle {
  color: #999;
  font-size: 0.22rem;
  margin-top: 0.06rem;
}

.container .jiancexiangmu .items {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  margin-top: 0.38rem;
}

.container .jiancexiangmu .items > span {
  display: inline-block;
  padding: 0.14rem 0.26rem;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 0.01rem;
  color: #333333;
  font-size: 0.16rem;
  cursor: pointer;
  transition: all ease 0.3s;
  margin-right: 0.22rem;
}

.container .jiancexiangmu .items > .active {
  background-color: #1c65b1;
  color: #fff;
  font-weight: bold;
}

.container .jiancexiangmu .items > span:hover {
  background-color: #1c65b1;
  color: #fff;
  font-weight: bold;
  transition: all ease 0.3s;
}

.container .jiancexiangmu .items .next {
  position: absolute;
  right: 0;
  display: flex;
  cursor: pointer;
  align-items: center;
  color: #999999;
  font-size: 0.14rem;
}

.container .jiancexiangmu .items .next span {
  margin-right: 0.06rem;
}

.container .jiancexiangmu .xiangmu {
  width: 100%;
  margin-top: 0.4rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.container .jiancexiangmu .xiangmu .xiangmu_item {
  width: 24%;
  height: 100% !important;
  background-color: #fff;
  margin-right: 0 !important;
  padding: 0.13rem;
  margin-top: 0.26rem;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.container .jiancexiangmu .xiangmu .xiangmu_item:last-child {
  margin-right: 0;
}

.container .jiancexiangmu .xiangmu .xiangmu_item:hover {
  transition: all 0.3s ease;
  transform: scale(1.05);
  box-shadow: 0 -0.1rem 0.2rem rgba(19, 116, 222, 0.3);
}

.container .jiancexiangmu .xiangmu .xiangmu_item .thumb {
  width: 100%;
  height: 2.21rem;
  cursor: pointer;
}

.container .jiancexiangmu .xiangmu .xiangmu_item .info {
  flex: 1;
  box-sizing: border-box;
  padding: 0.2rem;
  text-align: left;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.container .jiancexiangmu .xiangmu .xiangmu_item .info .stitle {
  color: #333333;
  font-size: 0.17rem;
  font-weight: bold;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.container .jiancexiangmu .xiangmu .xiangmu_item .info .desc {
  color: #666666;
  font-size: 0.14rem;
  margin-top: 0.23rem;
  /* line-height: .22rem;
    height: .44rem; */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}

.container .jiancexiangmu .xiangmu .xiangmu_item .info .btn {
  /* width: 1.1rem; */
  padding: 0 0.22rem;
  height: 0.36rem;
  border-radius: 0.2rem;
  border: 1px solid #1c65b1;
  color: #1c65b1;
  font-size: 0.16rem;
  margin: 0.18rem auto 0;
  text-align: center;
  line-height: 0.36rem;
  cursor: pointer;
  transition: all ease 0.3s;
}

.container .jiancexiangmu .xiangmu .xiangmu_item .info .btn:hover {
  background-color: #1c65b1;
  color: #fff;
  transition: all 0.3s ease;
}

.container .aboutus .gongsijianjie {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 0.38rem;
}

.container .aboutus .gongsijianjie > img {
  /* width: 7.92rem; */
  width: 66%;
  transition: all 0.3s ease;
  height: 4.44rem;
}

.container .aboutus .gongsijianjie > img:hover {
  transform: scale(1.01);
  transition: all 0.3s ease;
}

.container .aboutus .gongsijianjie .info {
  /* width: 5.59rem;
    height: 3.33rem; */
  width: 46%;
  box-sizing: border-box;
  padding: 0.22rem;
  background-color: #fff;
  position: absolute;
  right: 0;
}

.container .aboutus .gongsijianjie .info > div:nth-child(1) {
  color: #333333;
  font-size: 0.22rem;
  text-align: left;
  position: relative;
}

.container .aboutus .gongsijianjie .info > div:nth-child(1)::after {
  content: "";
  width: 0.4rem;
  height: 0.02rem;
  background-color: #1c65b1;
  position: absolute;
  left: 0;
  bottom: -0.1rem;
}

.container .aboutus .gongsijianjie .info .jianjie {
  color: #666666;
  font-size: 0.16rem;
  margin-top: 0.35rem;
  text-align: left;
  /* height: 1.35rem; */
  margin-bottom: 0.5rem;
  padding: 0 !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
}

.container .aboutus .gongsijianjie .info .btn {
  /* width: 1.1rem; */
  padding: 0 0.3rem;
  height: 0.36rem;
  border: 1px solid #dfdfdf;
  border-radius: 0.2rem;
  color: #cfcdcd;
  font-size: 0.14rem;
  line-height: 0.36rem;
  float: right;
  clear: both;
  cursor: pointer;
}

.container .aboutus .gongsijianjie .info .btn:hover {
  border: 1px solid #1c65b1;
  background-color: #1c65b1;
  transition: all ease 0.3s;
  color: #fff;
}

#zizhi {
  margin-top: 0.38rem;
  position: relative;
  width: 94%;
}

.zizhizhengshu .zizhi {
  width: 2.648rem;
  height: 3.828rem;
}

#hezuo {
  margin-top: 0.38rem;
  position: relative;
  width: 90%;
}

#hezuo .huoban {
  width: 2.04rem;
  height: 2.01rem;
}

.case_content {
  width: 100%;
  height: 4.1rem;
  display: flex;
  justify-content: space-between;
  margin-top: 0.38rem;
}

.case_content .case_left {
  width: 13.8%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.case_content .case_left > span {
  display: inline-block;
  width: 100%;
  height: 0.5rem;
  color: #333333;
  font-size: 0.16rem;
  text-align: center;
  line-height: 0.5rem;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 1px;
  cursor: pointer;
  transition: all ease 0.3s;
  margin-bottom: 0.22rem;
}

.case_content .case_left > .active {
  color: #fff;
  font-size: 0.16rem;
  background-color: #1c65b1;
  transition: all ease 0.3s;
}

.case_content .case_center {
  /* width: 4.28rem; */
  width: 36%;
  height: 100%;
  background-image: url("../assets/case1.png");
  background-size: cover;
  opacity: 1 !important;
  transition: all ease 0.3s;
}

.case_content .case_center div {
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.42);
  color: #ffffff;
  font-size: 0.18rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

  .case_content .case_right {
    /* width: 5.52rem; */
    width: 46%;
    height: 100%;
    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
  }

  .case_content .case_right>img {
    width: 100%;
    height: 1.92rem;
    object-fit: cover;
    margin-bottom: 0.26rem;
    display: block;
  }
  .case_content .case_right>img:first-child{
    margin-top: 0;
  }

.bottom {
  width: 100%;
  /* height: 3.6rem; */
  background-image: url("../assets/footbg.png");
  background-size: contain;
  margin-top: 0.93rem;
  padding: 0.95rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bottom .title {
  color: #fff;
  font-size: 0.38rem;
  text-align: center;
}

.bottom .stitle {
  color: #fff;
  font-size: 0.18rem;
  padding-top: 0.36rem;
  text-align: center;
}

.bottom .btn {
  /* width: 1.2rem; */
  height: 0.36rem;
  margin: 0.43rem auto 0;
  color: #fff;
  background-color: #013b65;
  border-radius: 2px;
  text-align: center;
  line-height: 0.36rem;
  cursor: pointer;
  font-size: 0.14rem;
  padding: 0 0.34rem;
}

.hidden {
  opacity: 0;
}

</style>