<template>
  <div class="account">
    <div class="top">
      <div class="pinfo">
        账号管理
        <div @click="addAccount">
          <i class="icon icon-add"></i>
          <span>增加账号</span>
        </div>
      </div>
      <div class="admin">
        <div>
          <span>手机号:</span>
          <span>{{admin.hidephone}}</span>
        </div>
        <div style="margin-top: .23rem;">
          <span>密码:</span>
          <span>******** </span>
        </div>
        <span class="status">管理员</span>
      </div>
    </div>

    <div class="my_account">
      <div class="pinfo">
        我分配的账号
      </div>

      <div class="account_table">
        <el-table
          :data="tableData"
          style="width: 100%">
          <el-table-column
            label="序号"
            type="index"
            align="center"
            >
          </el-table-column>
          <el-table-column
            label="名字"
            prop="name"
            align="center"
            >
          </el-table-column>
          <el-table-column
            label="手机号"
            prop="phone"
            align="center">
          </el-table-column>
          <el-table-column
            label="状态"
            prop="formatstatus"
            align="center">
          </el-table-column>
          <el-table-column label="操作" align="center" width="200">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="success"
                v-if="scope.row.status == 0"
                @click="handlePass(scope.$index, scope.row)">通过</el-button>
              
              <el-button
                size="mini"
                type="danger"
                v-if="scope.row.status == 0"
                @click="handleBack(scope.$index, scope.row)">驳回</el-button>

              <el-button
                size="mini"
                type="danger"
                v-if="scope.row.status == 1 && scope.row.type == 1"
                @click="handleXianzhi(scope.$index, scope.row)">限制</el-button>

              <el-button
                size="mini"
                type="success"
                v-if="scope.row.status == 1 && scope.row.type == 1"
                @click="handleAdmin(scope.$index, scope.row)">指定为管理员</el-button>

              <el-button
                size="mini"
                type="warning"
                v-if="scope.row.status == -1"
                @click="handleRe(scope.$index, scope.row)">恢复</el-button>

            </template>
          </el-table-column>
        </el-table>
        <div class="page" style="justify-content: flex-end;">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="count"
            @current-change="changePage">
          </el-pagination>
        </div>
      </div>
    </div>
    <ShowModal modalType="add" :editData="editData" @close="show = false" @confirm="confirm" :show="show" :changeType="changeType" />
  </div>
</template>

<script>
import ShowModal from '../../components/ShowModal'
import { getaccount,acchandle,createaccount } from '../../utils/api.js'
export default {
  name: 'BaizeUcenterAccount',

  data() {
    return {
      tableData: [

      ],
      show: false,
      changeType: "",
      editData: {},
      count: 0,
      nextp: 1,
      editid: "",
    admin:{}

    };
  },

  mounted() {
    this.getAccount()
  },

  methods: {
    handlePass(index,data){
      var that = this
      this.$prompt('请输入审批意见', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(({ value }) => {
          acchandle({
            type: 'verok',
            handleuid: data.uid,
            recontent: value,
            status: 1
          },res=>{
            that.getAccount()
            
          })
        }).catch(() => {
                
        });
      

      // this.editData = data
      // this.show = true
    },
    handleBack(index,data){
      var that = this
      this.$prompt('请输入审批意见', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(({ value }) => {
          acchandle({
            type: 'verok',
            handleuid: data.uid,
            recontent: value,
            status: -1
          },res=>{
            that.getAccount()
          })
        }).catch(() => {
                
        });
    },
    handleXianzhi(index,data){
      var that = this
      this.$confirm('确定限制该用户吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        acchandle({
          type: 'del',
          handleuid: data.uid
        },res=>{
          that.getAccount()
        })
      }).catch(() => {
                  
      });
    },
    handleAdmin(index,data){
      var that = this
      this.$confirm('确定指定为管理员吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        acchandle({
          type: 'admin',
          handleuid: data.uid
        },res=>{
          that.getAccount()
        })
      }).catch(() => {
                  
      });
      

    },
    handleRe(index,data){
      var that = this
      acchandle({
          type: 'reuser',
          handleuid: data.uid
        },res=>{
          that.getAccount()
        })
    },
    getAccount(){
      getaccount({p: this.nextp,status: ''},res=>{
        if(res.code == 0){
          this.tableData = res.data.list
          this.count = res.data.total *1
          this.admin = res.data.admin
        }
      })
    },
    addAccount(){
      this.changeType = 'addAccount'
      this.show = true
    },
    confirm(e){
      switch (this.changeType){
        case "addAccount": 
          if(!e.name){
            this.$message({
              message: "请输入姓名",
              type: "error"
            })
            return false
          }
          if(!e.phone){
            this.$message({
              message: "请输入手机号",
              type: "error"
            })
            return false
          }
          if(!e.password){
            this.$message({
              message: "请输入密码",
              type: "error"
            })
            return false
          }

          createaccount({
            handleuid: 0,
            name: e.name,
            phone: e.phone,
            password: e.password,
            code: e.code
          },res=>{
            if(res.code == 0){
              this.$message({
                message: res.msg,
                type: "success"
              })
              this.show = false
              this.getAccount()
              
            }
          })
        break;
        case 'edit': 
          if(!e.name){
            this.$message({
              message: "请输入姓名",
              type: "error"
            })
            return false
          }
          if(!e.phone){
            this.$message({
              message: "请输入手机号",
              type: "error"
            })
            return false
          }
          if(!e.password){
            this.$message({
              message: "请输入密码",
              type: "error"
            })
            return false
          }

          createaccount({
            handleuid: this.editid,
            name: e.name,
            phone: e.phone,
            password: e.password
          },res=>{
            if(res.code == 0){
              this.$message({
                message: res.msg,
                type: "success"
              })
              this.show = false
              this.getAccount()
            }
          })
      }

      
    },
    handleEdit(index,data){
      this.changeType = 'edit'
      this.editid = data.uid
      this.editData = data
      this.show = true
    },
    handleDelete(index,data){
      var status = data.status == 1 ? 'del' : data.status == -1 ? 'restatus' : data.status == 0 ? 'verok' : ''
      acchandle({
        type: status,
        handleuid: data.uid
      },res=>{
        if(res.code == 0){
          this.$message({
            message: res.msg,
            type: "success"
          })
          this.getAccount()
        }
      })

    },
    changePage(e){
      this.nextp = e
      this.getAccount()
    }
  },

  components: {
    ShowModal
  }
};
</script>

<style>
  .account{
    width: 75%;
  }
  .account > div{
    width: 100%;
    box-sizing: border-box;
    padding: .4rem;
    background-color: #fff;
    border: .01rem solid #EEEEEE;
  }
  .top .pinfo{
    width: 100%;
    padding: .2rem 0;
    border-bottom: .01rem solid #EEEEEE;
    color: #333333;
    font-size: .2rem;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }
  .top .pinfo > div {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .top .pinfo > div span{
    color: #1C65B1;
    font-size: .16rem;
    font-weight: normal;
    margin: 0 .04rem;
  }
  .top .admin{
    width: 3.53rem;
    height: .94rem;
    border: .01rem dashed #1C65B1;
    margin-top: .4rem;
    box-sizing: border-box;
    padding: .2rem;
    position: relative;
  }
  .top .admin > div {
    display: flex;
    align-items: center;
  }
  .top .admin > div span:nth-child(1){
    color: #999999;
    font-size: .14rem;
    width: 1rem;
    display: inline-block;
  }
  .top .admin > div span:nth-child(2){
    color: #333333;
    font-size: .14rem;
  }
  .top .admin .status{
    color: #1C65B1;
    font-size: .14rem;
    position: absolute;
    top: .2rem;
    right: .2rem;
  }
  .my_account{
    margin-top: .1rem;
  }
  .my_account .pinfo{
    width: 100%;
    padding: 0 0 .2rem 0;
    border-bottom: .01rem solid #EEEEEE;
    color: #333333;
    font-size: .2rem;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }
</style>