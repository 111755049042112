<template>
  <div class="list">
   <div class="list_item" v-for="(item,index) in list" :key="index">
      <img :src="item.thumb" alt="">
      <div class="news_info">
        <div class="title" @click="gomsg(item.id)">{{item.title}}</div>
        <div class="desc">{{item.memo}}</div>
        <div class="ctime">{{item.ctime}}</div>
        <span style="color: #343434;">...</span>
      </div>
    </div>
   <div v-if="!list || list.length < 1" style="width: 100%;">
      <el-empty :image-size="200"></el-empty>
    </div>
    <div class="page" v-if="total > 0">
     <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="changePage">
      </el-pagination>
    </div>
  </div>
  
</template>

<script>
import { getnewslist } from '../utils/api.js'
export default {
  name: 'BaizeNewsList',

  data() {
    return {
      list: [

      ],
      total: 0
    };
  },

  mounted() {
    if(this.$route.query.isback){
      this.getlist(this.$route.query.cateid,1)
    }
  },

  methods: {
    gomsg(id){
      this.$router.push({
        name: 'news_content',
        query: {
          cid: id
        }
      })
    },
    getlist(id,p){
      getnewslist({cateid: id,p: p},res=>{
        if(res.code == 0){
          this.list = res.data.list
          this.total = res.data.total * 1
        }
      })
    },
    changePage(e){
      this.$emit('refresh', {nextp: e});
    }
  },
};
</script>

<style>
  .list{
    width: 100%;
  }
  .list .list_item{
    width: 100%;
    height: 2.6rem;
    background-color: #fff;
    display: flex;
    overflow: hidden;
    margin-top: .4rem;
    border-radius: .04rem;
    transition: all .3s ease;
  }
  .list .list_item:hover{
    background-color: #1C65B1;
    transition: all .3s ease;
  }
  .list .list_item:hover .news_info .title{
    color: #fff;
  }
  .list .list_item:hover .news_info .desc{
    color: #fff;
  }
  .list .list_item:hover .news_info .ctime{
    color: #fff;
  }
  .list .list_item:hover .news_info span{
    color: #fff!important;
  }
  .list .list_item img{
    width: 3.9rem;
    border-radius: .04rem 0 0 .04rem;
  }
  .list .list_item .news_info{
    flex: 1;
    border-radius: 0 .04rem .04rem 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: .43rem .3rem .27rem .4rem;
  }
  .list .list_item .news_info .title{
    width: 6.48rem;
    color: #000;
    font-size: .18rem;
    height: .48rem;
    line-height: .24rem;
    overflow: hidden;
    cursor: pointer;
  }
  .list .list_item .news_info .desc{
    width: 100%;
    line-height: .22rem;
    height: .44rem;
    color: #666666;
    font-size: .14rem;
    margin-top: .15rem;
    overflow: hidden;
  }
  .list .list_item .news_info .ctime{
    width: 100%;
    text-align: right;
    color: #666666;
    font-size: .14rem;
    margin-top: .5rem;
  }
  .page{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: .4rem 0;
  }
</style>