<template>
  <div>
    <div class="banner">
      <div v-for="(item,index) in imgs" class="progressive" style="width: 100%;height: auto;z-index: -1;position: relative">
        <img :src="item.preview" class="preview" alt="" v-progressive="item.banner" style="width: 100%;">
      </div>
      <div class="title"></div>
      <div class="desc"></div>
      <div class="inp">
        <input class="swiper-no-swiping" v-model="keyword" placeholder="请输入搜索关键词" type="text" name="" id="">
        <div @click="search">
          <i class="icon icon-search"></i>
        </div>
      </div>
    </div>
    <div class="w1200 jishu_content">
      <!-- 左侧菜单 -->
      <div class="menu">
        <div class="title">
          <span>技术支持</span>
          <span>Technical Support</span>
        </div>
        <div :class="id == item.id ? 'menu_list active' : 'menu_list'" v-for="(item,index) in menulist" :key="index">
          <span @click="changeId(item.id)">{{item.title}}</span>
          <!-- <div class="child_list">
            <span :class="child_active == indexs ? 'active' : ''" :style="menu_active == index ? 'height: .6rem' : 'height: 0'" v-for="(items,indexs) in item.list" :key="indexs">{{items.title}}</span>
          </div> -->
        </div>
        <!-- <div :class="id == -1 ? 'menu_list active' : 'menu_list'">
          <span @click="seePerson">技术人员</span>
        </div> -->
      </div>
      <keep-alive include="">
        <router-view ref="child" @refresh="refresh"></router-view>
      </keep-alive>
      
    </div>
  </div>
</template>

<script>
import { getsupcatelist } from '../utils/api.js'
export default {
  name: 'BaizeJishi',

  data() {
    return {
      menulist: [

      ],
      menu_active: 0,
      id: "",
      nextp: 1,
      keyword: "",
      imgs: [
        {
          preview: require('../assets/jishubg-s.jpg'),
          banner: require('../assets/jishubg.png')
        }
      ]
    };
  },

  mounted() {
    this.$store.commit('setSelected',2)
    getsupcatelist({},res=>{
      if(res.code == 0){
        this.menulist = res.data.list
      }
    })
    this.id = this.$route.query.id
    this.$refs.child.getlist(this.id,this.nextp)
  },

  methods: {
    seePerson(){
      this.id = -1
      this.$router.push({
        path: '/jishu/jishu_people',
        query: {
          id: -1
        }
      })
    },
    refresh(e){
      this.nextp = e.nextp
      this.$refs.child.getlist(this.id,this.nextp)
    },
    changeId(id){
      this.id = id
      this.$route.query.id = id
      if(this.$route.name === 'jishu_people'){
        this.$router.push({
          name: "jishu_people",
          query: {
            cateid: this.id,
            id: this.id
          }
        })
        this.$refs.child.getlist(id,this.nextp)
      }else{
        this.$router.push({
          name: "jishu_people",
          query: {
            isback: 1,
            cateid: this.id,
            id: this.id
          }
        })
      }
      
      // this.$router.push({
      //   path: '/jishu',
      //   query: {
      //     id
      //   }
      // })
    },
    search(){
      if(this.keyword){
        this.$router.push({
          name: "search_result",
          query: {
            keyword: this.keyword
          }
        })
      }else{
        this.$message({
          message: "请输入搜索内容",
          type: "error"
        })
      }
    }
  },

  watch: {
    $route(){
      this.id = this.$route.query.id
    }
  }
};
</script>

<style scoped>
  .banner{
    width: 100%;
    height: 4.2rem;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
  }
  .banner .title{
    color: #fff;
    font-size: .42rem;
    padding-top: 2.9rem;
    text-align: center;
  }
  .banner .desc{
    color: #fff;
    font-size: .16rem;
    margin-top: .26rem;
    text-align: center;
  }
  .banner .inp{
    width: 5.43rem;
    height: fit-content;
    margin: 0 auto 0;
    display: flex;
    position: absolute;
    left: 50%;
    margin-left: -2.715rem;
    top: 3.3rem;
  }
  .banner .inp > input{
    width: 4.83rem;
    height: .52rem;
    box-sizing: border-box;
    padding: 0 .18rem;
    border: none;
    background-color: #fff;
    border-radius: .4rem 0 0 .4rem;
  }
  .banner .inp > div{
    flex: 1;
    background-color: #013B65;
    border-radius: 0 .4rem .4rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .jishu_content{
    margin: .4rem auto;
    display: flex;
    justify-content: space-between;
  }
  .menu{
    /* width: 2.71rem; */
    width: 22.5%;
    height: fit-content;
  }
.menu .title{
    display: flex;
    flex-direction: column;
    height: 1.34rem;
    background-color: #F2F2F2;
    border-radius: 2px 2px 0 0;
    align-items: center;
    justify-content: center;
  }
.menu .title > span:nth-child(1){
    color: #333333;
    font-size: .26rem;
    font-weight: bold;
  }
.menu .title > span:nth-child(2){
    color: #333333;
    font-size: .18rem;
  }
  .menu .menu_list{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    background-color: #E9E9E9;
    color: #111111;
    font-size: .18rem;
    cursor: pointer;
    border-bottom: 1px solid #D6D4D4;
    overflow: hidden;
    position: relative;
    flex-direction: column;
  }
  .menu .menu_list > span{
    display: flex;
    width: 100%;
    height: .75rem;
    justify-content: center;
    align-items: center;
  }
  .menu > .active{
    background-color: #1C65B1;
    color: #fff;
    border-bottom: none;
    font-weight: bold;
  }
  .menu .menu_list .child_list{
    width: 100%;
    background-color: #F2F2F2;
    display: flex;
    flex-direction: column;
    height: fit-content;
    overflow: hidden;

  }
  .menu .menu_list .child_list > span{
    display: inline-block;
    height: 0;
    text-align: center;
    line-height: .6rem;
    color: #333333;
    font-size: .16rem;
    font-weight: normal;
    transition: all ease .3s;
  }
  .menu .menu_list .child_list > .active{
    color: #1C65B1;
    font-weight: bold;
  }
</style>