<template>
  <div class="table_content">
    <div class="pinfo">
      注册登记表
    </div>

    <div class="table">
      <div class="comname">
        <span>企业名称<span class="red">*</span></span>
        <input type="text" v-model="companyData.company" class="common_input" placeholder="请输入企业全称" name="" id="">
      </div>

      <div class="table_tr">
        <div class="left">
          <span>注册地址<span class="red">*</span></span>
          <input type="text" v-model="companyData.comaddress" class="common_input longinp" placeholder="请输入注册地址" name="" id="">
        </div>

        <div class="right">
          <span>组织机构代码<span class="red">*</span></span>
          <input type="text" v-model="companyData.companyno" class="common_input shortinp" placeholder="请输入组织机构代码号" name="" id="">
        </div>
      </div>

      <div class="table_tr">
        <div class="left">
          <span>通讯地址<span class="red">*</span></span>
          <input type="text" v-model="companyData.address" class="common_input longinp" placeholder="请输入通讯地址" name="" id="">
        </div>

        <div class="right">
          <span>邮政编码<span class="red">*</span></span>
          <input type="text" v-model="companyData.code" class="common_input shortinp" placeholder="请输入邮政编码" name="" id="">
        </div>
      </div>

      <div class="title">法定代表人</div>
      <div class="table_tr">
        <div class="left">
          <span>姓名<span class="red">*</span></span>
          <input type="text" v-model="companyData.fname" class="common_input shortinp" placeholder="请输入法定代表人姓名" name="" id="">
        </div>

        <div class="right">
          <span>职务<span class="red">*</span></span>
          <input type="text" v-model="companyData.fzhiwu" class="common_input shortinp" placeholder="请输入法定代表职务" name="" id="">
        </div>
      </div>
      <div class="table_tr">
        <div class="left">
          <span>电话<span class="red">*</span></span>
          <input type="text" v-model="companyData.fphone" class="common_input shortinp" placeholder="请输入法定代表人电话" name="" id="">
        </div>

        <div class="right">
          <span>传真<span class="red">*</span></span>
          <input type="text" v-model="companyData.ffax" class="common_input shortinp" placeholder="请输入传真号" name="" id="">
        </div>
      </div>


      <div class="title">系统管理员</div>
      <div class="table_tr">
        <div class="left">
          <span>姓名<span class="red">*</span></span>
          <input type="text" v-model="companyData.xname" class="common_input shortinp" placeholder="请输入系统管理员姓名" name="" id="">
        </div>

        <div class="right">
          <span>职务<span class="red">*</span></span>
          <input type="text" v-model="companyData.xzhiwu" class="common_input shortinp" placeholder="请输入系统管理员职务" name="" id="">
        </div>
      </div>
      <div class="table_tr">
        <div class="left">
          <span>电话<span class="red">*</span></span>
          <input type="text" v-model="companyData.xphone" class="common_input shortinp" placeholder="请输入系统管理员电话" name="" id="">
        </div>

        <div class="right">
          <span>身份证号<span class="red">*</span></span>
          <input type="text" v-model="companyData.xcardnumber" class="common_input shortinp" placeholder="请输入系统管理员身份证号" name="" id="">
        </div>
      </div>


      <div class="title">网址/邮箱</div>
      <div class="table_tr">
        <div class="left">
          <span>企业网址<span class="red">*</span></span>
          <input type="text" v-model="companyData.site" class="common_input shortinp" placeholder="请输入企业网址" name="" id="">
        </div>

        <div class="right">
          <span>E-mail<span class="red">*</span></span>
          <input type="text" v-model="companyData.email" class="common_input shortinp" placeholder="请输入邮箱" name="" id="">
        </div>
      </div>

      <div class="btns">
        <span class="center" @click="submit">确认填写</span>
        <span class="center">下载登记表<i class="icon icon-download"></i></span>
      </div>
    </div>
  </div>
</template>

<script>
import { getcentercompany,savecompany } from '../../utils/api.js'
export default {
  name: 'BaizeRegisterTable',

  data() {
    return {
      companyData: {
        company: "",
        comaddress: "",
        companyno: "",
        address: "",
        code: "",
        fname: "",
        fzhiwu: "",
        fphone: "",
        ffax: "",
        xname: "",
        xzhiwu: "",
        xphone: "",
        xcardnumber: "",
        email: "",
        site: ""
      }
    };
  },

  mounted() {
    this.getCompany()
  },

  methods: {
    getCompany(){
      getcentercompany({},res=>{
        if(res.code == 0){
          if(typeof res.data.data.data === 'object'){
            this.companyData = res.data.data.data
          }
        }
      })
    },
    submit(){
      var data = {
        company: this.companyData.company,
        comaddress: this.companyData.comaddress,
        companyno: this.companyData.companyno,
        address: this.companyData.address,
        code: this.companyData.code,
        fname: this.companyData.fname,
        fzhiwu: this.companyData.fzhiwu,
        fphone: this.companyData.fphone,
        ffax: this.companyData.ffax,
        xname: this.companyData.xname,
        xzhiwu: this.companyData.xzhiwu,
        xphone: this.companyData.xphone,
        xcardnumber: this.companyData.xcardnumber,
        email: this.companyData.email,
        site: this.companyData.site
      }
      savecompany(data,res=>{
        if(res.code == 0){
          this.$message({
            message: res.msg,
            type: "success"
          })
        }
      })
    }
  },
};
</script>

<style>
  .table_content{
    width: 75%;
    background-color: #fff;
    padding: .4rem;
    box-sizing: border-box;
    height: fit-content;
  }
  .table_content .pinfo{
    width: 100%;
    padding: .2rem 0;
    border-bottom: .01rem solid #EEEEEE;
    color: #333333;
    font-size: .2rem;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }
  .red{
    color: #D90606;
  }
  .table .comname{
    width: 100%;
    display: flex;

    align-items: center;
    margin-top: .4rem;
    margin-bottom: .24rem;
  }
  .table .comname > span{
    color: #333333;
    font-size: .16rem;
  }
  .table .comname > input{
    flex: 1;
  }
  .common_input{
    height: .44rem;
    padding: 0 .2rem;
    border: .01rem solid #EDEDED;
    box-sizing: border-box;
    margin-left: .2rem;
  }
  .table .table_tr{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: .24rem;
  }
  .longinp{
    width: 3.02rem;
    margin-left: .2rem;
  }
  .shortinp{
    width: 2.65rem;
    margin-left: .2rem;
  }
  .table .table_tr .left > span{
    color: #333333;
    font-size: .16rem;
    display: inline-block;
    min-width: .7rem;
    text-align: right;
  }
  .table .table_tr .right > span{
    color: #333333;
    font-size: .16rem;
    display: inline-block;
    min-width: .7rem;
    text-align: right;
  }
  .table > .title{
    color: #333333;
    font-size: .18rem;
    margin-bottom: .24rem;
    font-weight: bold;
  }
  .table .btns{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .table .btns > span{
    cursor: pointer;
  }
  .table .btns > span:nth-child(1){
    width: 1.73rem;
    height: .44rem;
    background-color: #1C65B1;
    border-radius: .04rem;
    color: #fff;
    font-size: .14rem;
  }
  .table .btns > span:nth-child(2){
    width: 1.1rem;
    height: .36rem;
    border-radius: .2rem;
    border: .01rem solid #1C65B1;
    color: #1C65B1;
    font-size: .14rem;
    margin-left: .38rem;
  }
  .table .btns > span > .icon{
    margin-left: .04rem;
  }
</style>