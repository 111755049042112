<template>
  <div>
    <div class="footer">
      <div class="w1200 footer_container">
        <div class="footer_left">
          <div class="name">{{bottom.title}}</div>
          <div class="phone">
            <i class="icon icon-phone"></i>
            <span>{{bottom.phone}}</span>
          </div>
          <div class="email">
            <i class="icon icon-email"></i>
            <span>{{bottom.email}}</span>
          </div>
        </div>

        <div class="footer_center">
          <div class="flist">
            <span v-for="(item,index) in list" :key="index"><router-link :to="item.url">{{item.title}}</router-link></span>
          </div>
          <div class="info">
            <div>©版权所有：{{bottom.domain}} {{bottom.icp}}</div>
            <div>技术支持:山东阔尔</div>
          </div>
        </div>

        <div class="footer_right">
          <img :src="qrcode" alt="">
          <div>微信公众号</div>
        </div>
      </div>
    </div>
    <!-- <div class="zhanwei"></div> -->
  </div>
</template>

<script>

export default {
  name: 'BaizePublicFooter',

  data() {
    return {
      list: [
        {
          title: "首页",
          url: '/index_content'
        },
        {
          title: "检测中心",
          url: '/jiance_center'
        },
        {
          title: "技术支持",
          url: '/jishu?id=1',
          list: [
            {
              title: '技术支持1',
              url: 'jishu',
              id: 1
            },
            {
              title: '技术支持2',
              url: 'jishu',
              id: 2
            },
            {
              title: '技术支持3',
              url: 'jishu',
              id: 3
            }
          ]
        },
        {
          title: "新闻公告",
          url: '/news'
        },
        {
          title: "联系我们",
          url: '/contact_us'
        },
        {
          title: "关于我们",
          url: '/aboutus'
        },
        {
          title: "在线预约",
          url: '/yuyue'
        }
      ]
    };
  },

  mounted() {
    
  },

  methods: {
    
  },

  computed: {
    qrcode(){
      return this.$store.state.qrcode
    },
    bottom() {
      return this.$store.state.bottom
    }
  }
};
</script>

<style scoped>
  .footer{
    width: 100%;
    /* height: 1.58rem; */
    padding: 0.2rem  0;
    background-color: #0C183B;
    /* position: absolute; */
    bottom: 0;
    display: flex;
    align-items: center;
    /* transform:translateY(120%); */
  }
  .footer_container{
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  /* .zhanwei{
    height: 1.58rem;
    width: 100%;
  } */
  .footer_container .footer_left{
    color: rgba(255, 255, 255, .9);
  }
  .footer_left .name{
    font-weight: bold;
    font-size: .18rem;
  }
  .footer_left .phone{
    margin-top: .2rem;
    color: rgba(255, 255, 255, .9);
    font-size: .18rem;
    display: flex;
    align-items: center;
  }
  .footer_left .email{
    margin-top: .06rem;
    font-size: .18rem;
    display: flex;
    align-items: center;
  }
  .footer_left .phone >span{
    margin-left: .1rem;
  }
  .footer_left .email >span{
    margin-left: .1rem;
  }
  .footer_center{
    /* width: 7.6rem; */
  }
  .footer_center .flist{
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    padding-bottom: .3rem;
    display: flex;
    justify-content: center;
  }
  .footer_center .flist span{
    display: flex;
    align-items: center;
    padding: 0 .2rem;
    color: rgba(255, 255, 255, .8);
    font-size: .14rem;
    cursor: pointer;
    position: relative;
  }
  .footer_center .flist span a{
    color: rgba(255, 255, 255, .8);
  }
  .footer_center .flist span::before{
    content: "";
    width: .01rem;
    height: .12rem;
    background-color: rgba(255, 255, 255, .2);
    position: absolute;
    left: 0;
  }
  .footer_center .flist span:nth-child(1):before{
    display: none;
  }
  .footer_center .info{
    width: 100%;
    text-align: center;
    font-size: .12rem;
    color: rgba(255, 255, 255, .3);
  }
  .footer_center .info > div:nth-child(1){
    margin: .17rem 0 .04rem 0;
  }
  .footer_right > img{
    width: .8rem;
    height: .8rem;
  }
  .footer_right > div{
    text-align: center;
    margin-top: .08rem;
    color: rgba(255, 255, 255, .6);
    font-size: .12rem;
  }
</style>