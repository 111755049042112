<template>
	<div>
		<div style="width: 100%;height: 4.2rem;overflow: hidden;">
			<div class="banner">
				<div v-for="(item,index) in imgs" class="progressive"
					style="width: 100%;height: auto;z-index: -1;position: relative;">
					<img class="preview" :src="item.preview" alt="" v-progressive="item.banner" style="width: 100%;">
				</div>
				<div class="title"></div>
				<div class="desc"></div>
				<div class="inp">
					<input v-model="keyword" placeholder="请输入搜索关键词" type="text" name="" id="">
					<div @click="search">
						<i class="icon icon-search"></i>
					</div>
				</div>
			</div>
		</div>

		<div class="w1200 jiance_content">
			<!-- 左侧菜单 -->
			<div class="menu">
				<div class="title">
					<span>检测中心</span>
					<span>Testing Center</span>
				</div>
				<div :class="cateid == item.id ? 'menu_list active' : 'menu_list'" v-for="(item,index) in menulist"
					:key="index">
					<span @click="changeMenu(item.id,index)">{{item.title}}</span>
					<!-- <div class="child_list">
            <span @click="changeMenuSmall(items.id,indexs)" :class="cateid2 == items.id ? 'active' : ''" :style="menu_active == index ? 'height: .6rem' : 'height: 0'" v-for="(items,indexs) in item.list" :key="indexs">{{items.title}}</span>
          </div> -->
				</div>
			</div>

			<keep-alive>
				<router-view ref="child" @refresh="refresh"> </router-view>
			</keep-alive>


		</div>
	</div>
</template>

<script>
	import {
		getcate
	} from '../utils/api.js'
	export default {
		name: 'BaizeJianceCenter',

		data() {
			return {
				menulist: [

				],
				menu_active: 0,
				child_active: -1,
				cateid: "",
				cateid2: "",
				keyword: "",
				nextp: 1,
				imgs: [{
					preview: require('../assets/jiancebg-s.jpg'),
					banner: require('../assets/jiancebg.png')
				}]
			};
		},


		mounted() {
			this.$store.commit('setSelected', 1)
			if (this.$route.query.cateid) {
				this.cateid = this.$route.query.cateid
			}
			if (this.$route.query.cateid2) {
				this.cateid2 = this.$route.query.cateid2
			}
			this.getCate()
		},

		methods: {
			getCate() {
				getcate({}, res => {
					console.log("检测中心",res);
					if (res.code == 0) {
						this.menulist = res.data.list
						if (!this.cateid) {
							this.cateid = res.data.list[0].id
						}
						this.$refs.child.getlist(this.cateid, this.cateid2, this.keyword, this.nextp)
					}
				})
			},
			refresh(e) {
				console.log(e)
				this.$refs.child.getlist(this.cateid, this.cateid2, this.keyword, e.nextp)
			},
			changeMenu(id, index) {
				console.log(id, index)
				var that = this
				this.menu_active = index
				this.cateid = id
				this.cateid2 = ""
				this.child_active = -1
				if (this.$route.name === 'jiance_list') {
					this.$refs.child.getlist(this.cateid, this.cateid2, this.keyword)
				} else {
					this.$router.push({
						name: "jiance_list",
						query: {
							isback: 1,
							cateid: this.cateid,
							cateid2: this.cateid2,
							keyword: this.keyword,

						}
					})


				}

			},
			changeMenuSmall(id, index) {
				var that = this
				this.child_active = index
				this.cateid2 = id
				if (this.$route.name === 'jiance_list') {
					this.$refs.child.getlist(this.cateid, this.cateid2, this.keyword)
				} else {
					this.$router.push({
						name: "jiance_list",
						query: {
							isback: 1,
							cateid: this.cateid,
							cateid2: this.cateid2,
							keyword: this.keyword
						}
					})

				}
			},
			search() {
				if (this.keyword) {
					this.$router.push({
						name: "search_result",
						query: {
							keyword: this.keyword
						}
					})
				} else {
					this.$message({
						message: "请输入搜索内容",
						type: "error"
					})
				}
			}
		},

		beforeCreate() {
			document.querySelector('body').setAttribute('style', 'background-color: #fff')
		},

		watch: {
			// $route(){
			//   if(this.$route.query.isback){
			//     this.$refs.child.getlist(this.cateid,this.cateid2,this.keyword)
			//   }
			// }
		},
		beforeCreate() {
			document.querySelector('body').setAttribute('style', 'background-color:#fff')
		},

	};
</script>

<style scoped>
	.banner {
		width: 100%;
		height: 4.2rem;
		background-size: contain;
		background-repeat: no-repeat;
	}

	.banner .title {
		color: #fff;
		font-size: .42rem;
		padding-top: 2.9rem;
		text-align: center;
	}

	.banner .desc {
		color: #fff;
		font-size: .16rem;
		margin-top: .26rem;
		text-align: center;
	}

	.banner .inp {
		width: 5.43rem;
		height: fit-content;
		margin: 0 auto 0;
		display: flex;
		position: absolute;
		left: 50%;
		margin-left: -2.715rem;
		top: 3.3rem;
	}

	.banner .inp>input {
		width: 4.83rem;
		height: .52rem;
		box-sizing: border-box;
		padding: 0 .18rem;
		border: none;
		background-color: #fff;
		border-radius: .4rem 0 0 .4rem;
	}

	.banner .inp>div {
		flex: 1;
		background-color: #013B65;
		border-radius: 0 .4rem .4rem 0;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.container {
		margin: 0 auto;
	}

	.jiance_content {
		display: flex;
		justify-content: space-between;
		margin: .4rem auto;
	}

	.menu {
		/* width: 2.71rem; */
		width: 22.5%;
		height: fit-content;
	}

	.menu .title {
		display: flex;
		flex-direction: column;
		height: 1.34rem;
		background-color: #F2F2F2;
		border-radius: 2px 2px 0 0;
		align-items: center;
		justify-content: center;
	}

	.menu .title>span:nth-child(1) {
		color: #333333;
		font-size: .26rem;
		font-weight: bold;
	}

	.menu .title>span:nth-child(2) {
		color: #333333;
		font-size: .18rem;
	}

	.menu .menu_list {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		height: fit-content;
		background-color: #E9E9E9;
		color: #111111;
		font-size: .18rem;
		cursor: pointer;
		border-bottom: 1px solid #D6D4D4;
		overflow: hidden;
		position: relative;
		flex-direction: column;
	}

	.menu .menu_list>span {
		display: flex;
		width: 100%;
		height: .75rem;
		justify-content: center;
		align-items: center;
	}

	.menu>.active {
		background-color: #1C65B1;
		color: #fff;
		border-bottom: none;
		font-weight: bold;
	}

	.menu .menu_list .child_list {
		width: 100%;
		background-color: #F2F2F2;
		display: flex;
		flex-direction: column;
		height: fit-content;
		overflow: hidden;

	}

	.menu .menu_list .child_list>span {
		display: inline-block;
		height: 0;
		text-align: center;
		line-height: .6rem;
		color: #333333;
		font-size: .16rem;
		font-weight: normal;
		transition: all ease .3s;
	}

	.menu .menu_list .child_list>.active {
		color: #1C65B1;
		font-weight: bold;
	}
</style>
