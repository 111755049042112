<template>
  <div>
    <PublicHeader :placeholder="false" />

    <router-view />



    <PublicFooter />
  </div>

</template>

<script>

export default {

  name: 'BaizeIndex',

  data() {
    return {
      
    };
  },

  mounted() {

  },

  methods: {

  },
  
  components: {

  },
};
</script>

<style>


</style>