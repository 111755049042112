<template>
  <div class="info_container">
    <div class="right_content">
      <div class="pinfo">
        个人信息
        <div @click="goaccount" v-if="user.type == 2">
          <i class="icon icon-register2"></i>
          <span>账号管理</span>
        </div>
      </div>
      <div class="info">
        <div class="uinfo">
          <div class="item">
            <span>用户名:</span>
            <span>{{ user.name }}</span>
          </div>
          <div class="item">
            <span>登录密码:</span>
            <span>******</span>
            <div class="edit">
              <i class="icon icon-edit"></i>
              <span @click="editInfo('password')">修改密码</span>
            </div>
          </div>
          <div class="item">
            <span>手机号码:</span>
            <span>{{ user.phone }}</span>
            <div class="edit">
              <i class="icon icon-edit"></i>
              <span @click="editInfo('phone')">修改手机号</span>
            </div>
          </div>
          <div class="item">
            <span>联系邮箱:</span>
            <span>{{ user.email || "无" }}</span>
            <div class="edit">
              <i class="icon icon-edit"></i>
              <span @click="editInfo('email')">修改邮箱</span>
            </div>
          </div>
          <div class="item">
            <span>微信绑定:</span>
            <span>{{ user.wechat ? "已绑定" : "未绑定" }}</span>
            <div class="edit" @click="bindwx">
              <i class="icon icon-edit"></i>
              <span>{{ user.wechat ? "修改微信" : "绑定微信" }}</span>
            </div>
          </div>

          <div class="item">
            <span>所属企业:</span>
            <div v-if="!user.comid">
              <el-select v-model="user.company" filterable remote placeholder="请输入关键词" :remote-method="search">
                <el-option v-for="item in companylist" :key="item.uid" :label="item.company" :value="item.uid">
                </el-option>
              </el-select>
              <el-button type="primary" @click="changeCompany" style="position: absolute; right: 0">提交</el-button>
            </div>
            <div v-else style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #333333;
                font-size: 0.14rem;
              ">
              <span>{{ user.company || "暂无信息" }}</span>
              <div v-if="user.company" id="status">
                ({{
                    user.comver == 0
                      ? "审核中"
                      : user.comver == 1
                        ? "已通过"
                        : "未通过"
                }})
              </div>
            </div>
          </div>
        </div>
        <div class="changeheader">
          <!-- <img src="../../assets/headimgurl.png" alt="">
          <div class="shadow">
            <i class="icon icon-edit2"></i>
            <span>点击修改头像</span>
          </div> -->
          <el-upload class="avatar-uploader" :action="upfileUrl" :show-file-list="false"
            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
      <div class="download">
        <span class="download_item" @click="download">下载"检验检测机构资质认定模板证书附表(模板下载)"</span>
        <el-upload class="upload-demo" action="https://bezova.com/addons/execute/upload-action-upload.html"
          :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" multiple :limit="3"
          :on-exceed="handleExceed" :file-list="fileList">
          <el-button type="primary">点击上传</el-button>
        </el-upload>
      </div>

      <ShowModal modalType="inp" @close="close" @confirm="confirm" :myEmail='user.email' :editData="editData" :changeType="changeType"
        :show="show" />

      <div class="pop_wx" v-show="show_wx">
        <div class="shadow" @click="show_wx = false"></div>
        <div class="wx_content">
          <div class="wx_top">微信绑定</div>
          <div class="wx_pic">
            <img :src="qrcode" alt="" />
            <span>扫一扫上面的二维码图案，绑定微信</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 企业信息 v-if="showCompany" -->
    <div class="cominfo" v-if="showCompany">
      <div class="pinfo">
        单位基本信息
        <div @click="goTable">
          <i class="icon icon-edit3"></i>
          <span>注册登记表</span>
        </div>
      </div>

      <div class="baseinfo">
        <div>
          <span>企业名称:</span>
          <span>{{ companyData.company }}</span>
        </div>
        <div>
          <span>注册地址:</span>
          <span>{{ companyData.comaddress }}</span>
        </div>
        <div>
          <span>组织机构代码:</span>
          <span>{{ companyData.code }}</span>
        </div>
        <div>
          <span>通讯地址:</span>
          <span>{{ companyData.address }}</span>
        </div>
        <div>
          <span>法定代表人:</span>
          <span>{{ companyData.fname }}</span>
        </div>
        <div>
          <span>法人电话:</span>
          <span>{{ companyData.fphone }}</span>
        </div>
        <div>
          <span>系统管理员:</span>
          <span>{{ companyData.xname }}</span>
        </div>
        <div>
          <span>管理员电话:</span>
          <span>{{ companyData.xphone }}</span>
        </div>
        <div>
          <span>企业网址:</span>
          <span>{{ companyData.site }}</span>
        </div>
        <div>
          <span>E-mail:</span>
          <span>{{ companyData.email }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShowModal from "../../components/ShowModal";
import {
  getuser,
  change,
  getqrcode,
  getcentercompany,
  getcompany,
  qrCodeOk,
} from "../../utils/api.js";
export default {
  name: "BaizeUcenterInfo",
  dialogVisible: true,
  data() {
    return {
      changeType: "",
      show: false,
      show_wx: false,
      user: {},
      editData: {
        phone: "",
        code: ''
      },
      qrcode: "",
      showCompany: false,
      companyData: {},
      companylist: [],
      imageUrl: "",
      upfileUrl: "",
      fileList: [],
      dsq: null,
      oldval: {}
    };
  },

  watch: {
    show_wx(val) {
      var that = this;
      if (val) {
        this.dsq = setInterval(() => {
          qrCodeOk({}, (res) => {
            if (res.data.closeok == 1) {
              clearInterval(that.dsq);
              that.$message({
                message: res.msg,
                type: "success",
              });
              that.show_wx = false;
              this.getUser();
            }
          });
        }, 1000);
      } else {
        clearInterval(this.dsq);
      }
    },
  },

  mounted() {
    this.getUser();
    getcentercompany({}, (res) => {
      if (res.code == 0) {
        if (Array.isArray(res.data.data.data)) {
          this.showCompany = false;
        } else {
          this.companyData = res.data.data.data;
          if (
            this.companyData.comid > 0 &&
            this.companyData.type == 2 &&
            this.companyData.status == 1
          ) {
            this.showCompany = true;
          } else {
            this.showCompany = false;
          }
        }
      }
    });
    getcompany({}, (res) => {
      if (res.code == 0) {
        this.companylist = res.data.list;
      }
    });
    // this.dialogVisible();
    if (this.jianyi != this.oldval['q0']) {
      this.dialogVisible();
    }
  },

  methods: {
    getVolume() {
      getvolume({}, res => {
        if (res.code == 0) {
          this.memo = res.data.vol
          var oldval = res.data.oldval;
          res.data.list.forEach(item => {
            item.type == 1 ? item.value = "" : item.value = []
            if (oldval['q' + item['id']]) item.value = oldval['q' + item['id']];
          });
          this.jianyi = oldval['q0'];
          this.list = res.data.list
          this.oldval = res.data.oldval
        }
      })
    },
    dialogVisible() {
      this.$confirm('您有新的满意度调查文件未填写', '提示', {
        confrimButtonText: '去填写',
        cancelButtonText: '取消',
        // type: "warning",
      }).then((result) => {
        this.$router.replace('/ucenter/ucenter_diaocha')
      }).catch((err) => {

      });
    },
    search(query) {
      if (query !== "") {
        getcompany(
          {
            keyword: query,
          },
          (res) => {
            this.companylist = res.data.list;
          }
        );
      } else {
        this.options = [];
      }
    },
    download() {
      location.href = this.$store.state.bottom.comtmp[0];
    },
    handleRemove(file, fileList) { },
    handlePreview(file) { },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    editInfo(e) {
      this.changeType = e;
      if (e == "email" || e == "password") {
        this.editData.phone = this.user.phone;
      } else {
        this.editData.phone = "";
      }
      this.show = true;
    },
    close() {
      this.show = false;
    },
    confirm(e) {
      switch (this.changeType) {
        case "phone":
          if (!e.phone) {
            this.$message({
              message: "请输入手机号",
              type: "error",
            });
            return false;
          }
          if (!e.code) {
            this.$message({
              message: "请输入验证码",
              type: "error",
            });
          }
          change(
            {
              type: "phone",
              phone: e.phone,
              code: e.code,
            },
            (res) => {
              if (res.code == 0) {
                this.show = false;
                this.editData.code = '';
                this.getUser();
              }
            }
          );
          break;
        case "email":
          if (!e.email) {
            this.$message({
              message: "请输入邮箱",
              type: "error",
            });
            return false;
          }
					
          if (!e.code) {
            this.$message({
              message: "请输入验证码",
              type: "error",
            });
          }
          change(
            {
              type: "email",
              email: e.email,
              code: e.code,
            },
            (res) => {
              if (res.code == 0) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.getUser();
                this.show = false;
              }
            }
          );
          break;
        case "password":
          if (!e.code) {
            this.$message({
              message: "请输入验证码",
              type: "error",
            });
            return false;
          }
          if (!e.password) {
            this.$message({
              message: "请输入新密码",
              type: "error",
            });
            return false;
          }
          if (!e.repassword) {
            this.$message({
              message: "请再次输入新密码",
              type: "error",
            });
            return false;
          }
          if (e.password != e.repassword) {
            this.$message({
              message: "前后两次输入密码不一致，请检查后重试",
              type: "error",
            });
            return false;
          }
          change(
            {
              type: "password",
              code: e.code,
              password: e.password,
              repassword: e.repassword,
            },
            (res) => {
              if (res.code == 0) {
                this.show = false;
                this.$store.commit("removeToken");
                this.$router.replace("/");
              }
            }
          );
      }
    },
    bindwx() {
      if (!this.qrcode) {
        getqrcode({}, (res) => {
          if (res.code == 0) {
            this.qrcode = res.data.path;
            this.show_wx = true;
          }
        });
      } else {
        this.show_wx = true;
      }
    },
    goTable() {
      this.$router.push("register_table");
    },
    goaccount() {
      this.$router.push("ucenter_account");
    },
    getUser() {
      getuser({}, (res) => {
				console.log('个人信息',res);
        if (res.code == 0) {
          this.user = res.data.data.user;
          this.upfileUrl = res.data.data.user.upavatarurl;
          this.imageUrl = res.data.data.user.headimgurl;
			
        }
      });
    },
		// 提交
    changeCompany() {
      if (!this.user.company) {
        this.$message({
          message: "未选择公司",
          type: "error",
        });
        return false;
      }
      change({
        type: "comver",
        comid: this.user.company,
      },
        (res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getUser()
        }
      );
    },
    handleAvatarSuccess(res, file) {
      var url = res.data.url;
      this.imageUrl = URL.createObjectURL(file.raw);
      change(
        {
          type: "avatar",
          avatar: url,
        },
        (res) => {
          if (res.code == 0) {
            return;
            this.$router.go(0);
          }
        }
      );
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!');
      // }
      // return isJPG && isLt2M;
    },
  },

  components: {
    ShowModal,
  },
};
</script>

<style>
.info_container {
  width: 75%;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 1.3rem;
  height: 1.3rem;
  line-height: 1.3rem;
  text-align: center;
}

.avatar {
  width: 1.3rem;
  height: 1.3rem;
  display: block;
  border-radius: 50%;
  object-fit: cover;
}

.el-icon-plus {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.right_content {
  /* width: 9rem; */
  /* width: 75%; */
  height: fit-content;
  background-color: #fff;
  border: 0.01rem solid #eeeeee;
  box-sizing: border-box;
  padding: 0.2rem 0.4rem;
}

.right_content .pinfo {
  width: 100%;
  padding: 0.2rem 0;
  border-bottom: 0.01rem solid #eeeeee;
  color: #333333;
  font-size: 0.2rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.right_content .pinfo>div {
  cursor: pointer;
}

.right_content .pinfo>div span {
  color: #1c65b1;
  font-size: 0.16rem;
  font-weight: normal;
  margin: 0 0.04rem;
}

.right_content .info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 0.4rem;
}

.right_content .info .uinfo {
  display: flex;
  flex-direction: column;
  width: 58%;
  min-width: 350px;
}

.right_content .info .uinfo .item {
  /* width: 4.8rem; */
  /* width: 480px; */
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
  position: relative;
}

.right_content .info .uinfo .item>span:nth-child(1) {
  display: inline-block;
  /* width: .83rem; */
  margin-right: 10px;
  color: #999;
  font-size: 0.14rem;
}

.right_content .info .uinfo .item>span:nth-child(2) {
  display: inline-block;
  /* width: 3.7rem; */
  color: #333333;
  font-size: 0.14rem;
}

.right_content .info .uinfo .item .edit {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  color: #1c65b1;
  font-size: 0.14rem;
  cursor: pointer;
  width: 100px;
}

.right_content .info .uinfo .item .edit span {
  margin-left: 0.1rem;
}

.right_content .info .uinfo .item #status {
  color: #ecbf55;
  position: absolute;
  right: 1rem;
  font-size: 0.14rem;
}

.right_content .info .changeheader {
  width: 1.3rem;
  height: 1.3rem;
  position: relative;
}

.right_content .info .changeheader>img {
  width: 1.3rem;
  border-radius: 50%;
}

.right_content .info .changeheader>.shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.right_content .info .changeheader>.shadow span {
  color: #fffefe;
  font-size: 0.14rem;
  margin-top: 0.07rem;
}

.pop_wx {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

.pop_wx .shadow {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
}

.pop_wx .wx_content {
  width: 4.54rem;
  height: fit-content;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 102;
}

.pop_wx .wx_content .wx_top {
  width: 100%;
  height: 0.94rem;
  background: linear-gradient(90deg, #1c65b1, #0653a7);
  box-shadow: 0 0.1rem 0.1rem rgba(28, 101, 177, 0.2);
  border-radius: 0.1rem 0.1rem 50% 50%;
  color: #fff;
  font-size: 0.16rem;
  display: flex;
  align-items: center;
  z-index: 9;
  justify-content: center;
  position: absolute;
}

.pop_wx .wx_content .wx_pic {
  width: 100%;
  height: 3.88rem;
  border-radius: 0 0 0.1rem 0.1rem;
  background-color: #fff;
  z-index: -1;
  padding-top: 0.65rem;
  margin-top: 0.65rem;
}

.pop_wx .wx_content .wx_pic>img {
  width: 2.59rem;
  height: 2.59rem;
  display: flex;
  margin: 0 auto 0.26rem;
}

.pop_wx .wx_content .wx_pic>span {
  color: #999999;
  font-size: 0.12rem;
  display: block;
  margin: 0 auto;
  text-align: center;
}

/* 公司信息 */
.cominfo {
  /* width: 9rem; */
  height: fit-content;
  background-color: #fff;
  border: 0.01rem solid #eeeeee;
  box-sizing: border-box;
  padding: 0.2rem 0.4rem;
  margin-top: 0.1rem;
}

.cominfo .pinfo {
  width: 100%;
  padding: 0.2rem 0;
  border-bottom: 0.01rem solid #eeeeee;
  color: #333333;
  font-size: 0.2rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.cominfo .pinfo>div {
  cursor: pointer;
}

.cominfo .pinfo>div span {
  color: #1c65b1;
  font-size: 0.16rem;
  font-weight: normal;
  margin: 0 0.04rem;
}

.cominfo .baseinfo {
  margin-top: 0.4rem;
}

.cominfo .baseinfo>div {
  margin-bottom: 0.27rem;
}

.cominfo .baseinfo>div span:nth-child(1) {
  color: #999999;
  font-size: 0.14rem;
  display: inline-block;
  width: 100px;
  text-align: right;
}

.cominfo .baseinfo>div span:nth-child(2) {
  color: #333333;
  font-size: 0.14rem;
  margin-left: 0.34rem;
}

.download {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.download_item {
  color: #999999;
  font-size: 0.14rem;
  margin-bottom: 0.05rem;
}

.download_item:hover {
  color: #1c65b1;
  cursor: pointer;
}
</style>