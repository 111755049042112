<template>
  <div class="yuyue_system">
    <div>
      <div class="pinfo">
        预约管理
      </div>
      <div class="nums">
        <div>
          <span class="num">{{nums[0]}}</span>
          <span>处理中/个</span>
        </div>
        <div>
          <span class="num">{{nums[1]}}</span>
          <span>预约成功/个</span>
        </div>
        <div>
          <span class="num">{{nums[2]}}</span>
          <span>全部预约/个</span>
        </div>
        <div>
          <span class="num">{{nums[3]}}</span>
          <span>预约失败/个</span>
        </div>
        <div>
          <span class="num">{{nums[4]}}</span>
          <span>预约完成/个</span>
        </div>
      </div>
    </div>
    <div>
      <div class="pinfo">全部预约</div>
      <div class="account_table">
        <el-table
          :data="tableData"
          style="width: 100%">
          <el-table-column
            label="委托单号"
            align="center">
            <template slot-scope="scope">
              <span class="orderid" @click="handleEdit(scope.$index, scope.row, true)">{{ scope.row.orderid }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="检测项目"
            prop="protitle"
            align="center"
            >
          </el-table-column>
          <el-table-column
            label="填单人"
            prop="user.name"
            align="center"
            >
          </el-table-column>

          <el-table-column
            label="预约时间"
            prop="starttime"
            align="center">
          </el-table-column>
          <el-table-column
            label="状态"
            prop="formatstatus"
            align="center">
          </el-table-column>
          <el-table-column label="操作" align="center" width="200">
            <template slot-scope="scope">
              <el-button
                size="mini"
                v-if="scope.row.status == 0"
                @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
              
              <el-button
                size="mini"
                type="danger"
                v-if="scope.row.status < 2"
                @click="handleDelete(scope.$index, scope.row)">撤回</el-button>

               <el-button
                size="mini"
                v-if="scope.row.status == 1"
                @click="handleWeituo(scope.$index, scope.row)">下载委托书</el-button>
              <el-button
                size="mini"
                type="danger"
                v-if="scope.row.baogao"
                @click="handleDownload(scope.$index, scope.row)">检测报告</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="page" v-if="showpage" style="justify-content: flex-end;">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="count"
            @current-change="changePage"
            >
          </el-pagination>
        </div>
      </div>
    </div>
    <ShowModal modalType="edityy" @close="close" @confirm="confirm" changeType="edityy" :editData="editData" :show="show" />
  </div>
</template>

<script>

import ShowModal from '../../components/ShowModal'
import { getmyorderlist,editorder,backorder } from '../../utils/api.js'
export default {
  name: 'BaizeYuyueSystem',

  data() {
    return {
      tableData: [

      ],
      show: false,
      editData: {user: {}},
      nextp: 1,
      status: "",
      nums: [],
      count: 1,
      currentpage: 1,
      showpage: true
    };
  },

  mounted() {
    this.status = this.$route.query.yystatus
    this.getList(this.status)
  },

  watch: {
    status: function(){
      var that = this
      this.nextp = 1
      this.currentpage = 1
      this.showpage = false
      setTimeout(() => {
        that.showpage = true
      }, 1);
    }
  },

  methods: {
    handleWeituo(index,data){
      // location.href = data.weituo
	  console.log(data,"11111");
    },
    handleEdit(index,data,disabled=false){
      data.disabled = disabled
      this.editData = data
      this.show = true
    },
    close(){
      this.show = false
    },
    confirm(e){
      editorder({id: e.orderid,starttime: e.starttime},res=>{
        if(res.code == 0){
          this.$message({
            message: res.msg,
            type: "success"
          })
          this.show = false
          this.getList(this.status)
        }
      })

    },
    getList(e){
      this.status = e
      getmyorderlist({
        p: this.nextp,
        status: e
      },res=>{
        if(res.code == 0){
          if(this.nextp == 1){
            this.nums = res.data.nums
          }
          this.tableData = res.data.list
          this.count = res.data.total * 1
        }
      })
    },
    handleDelete(index,data){
      this.$confirm('确定撤回该预约吗？','提示',{
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: 'warning'
      }).then(() => {
        backorder({
          id: data.orderid
        },res=>{
          if(res.code == 0){
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.getList(this.status)
          }
        })
      }).catch(() => {
        
      });
    },
    handleDownload(index,data){
      location.href = data.baogao
    },
    changePage(e){
      this.nextp = e
      this.getList(this.status)
    }
  },

  components: {
    ShowModal
  }
};
</script>

<style>

  .yuyue_system{
    /* width: 9rem; */
    width: 75% !important;
    height: fit-content;
    background-color: #fff;
    border: .01rem solid #EEEEEE;
    box-sizing: border-box;
    padding: .2rem .4rem;
  }
  .yuyue_system .pinfo{
    width: 100%;
    padding: .2rem 0;
    border-bottom: .01rem solid #EEEEEE;
    color: #333333;
    font-size: .2rem;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }
  .yuyue_system .nums{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: .4rem 0;
  }
  .yuyue_system .nums > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 .4rem;
    transition: all .3s ease;
  }
  .yuyue_system .nums > div span{
    color: #AEAEAE;
    font-size: .14rem;

  }
  .yuyue_system .nums > div .num{
    color: #1C65B1;
    font-size: .34rem;
  }
  .yuyue_system .nums > div:hover{
    margin-top: -.05rem;
    transition: all .3s ease;
  }
  .orderid:hover{
    cursor: pointer;
    color: #1C65B1;
    text-decoration: underline;
  }
</style>