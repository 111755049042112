import Vue from 'vue'
import VueRouter from 'vue-router'

import Index from '../views/index.vue'
import IndexContent from '../views/index_content.vue'
import JianceCenter from '../views/jiance_center.vue'
import JianceList from '../views/jiance_list.vue'
import JianceInfo from '../views/jiance_info.vue'
import Jishu from '../views/jishu.vue'
import JishuContent from '../views/jishu_content.vue'
import JishuPeople from '../views/jishu_people.vue'
import News from '../views/news.vue'
import NewsList from '../views/news_list.vue'
import NewsContent from '../views/news_content.vue'
import ContactUs from '../views/contact_us.vue'
import AboutUs from '../views/aboutus.vue'
import Yuyue from '../views/yuyue.vue'
import Login from '../views/login/login.vue'
import Register from '../views/register/register.vue'
import RegisterUser from '../views/register/register_user.vue'
import RegisterQiye from '../views/register/register_qiye.vue'
import Ucenter from '../views/ucenter/ucenter.vue'
import UcenterInfo from '../views/ucenter/ucenter_info.vue'
import RegisterTable from '../views/ucenter/register_table.vue'
import UcenterAccount from '../views/ucenter/ucenter_account.vue'
import YuyueSystem from '../views/ucenter/yuyue_system.vue'
import UcenterShenpi from '../views/ucenter/ucenter_shenpi.vue'
import UcenterDiaocha from '../views/ucenter/ucenter_diaocha.vue'
import FindPwd from '../views/login/find_pwd.vue'
import SearchResult from '../views/search_result.vue'
import CompanyInfo from '../views/company_info.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    redirect: '/index_content',
    children: [
      {
        path: 'index_content',
        name: 'index_content',
        component: IndexContent
      },
      {
        path: 'jiance_center',
        name: 'jiance_center',
        component: JianceCenter,
        redirect: '/jiance_center/jiance_list',
        children: [
          {
            path: 'jiance_list',
            name: 'jiance_list',
            component: JianceList
          },
          {
            path: 'jiance_info',
            name: 'jiance_info',
            component: JianceInfo
          }
        ]
      },
      {
        path: "/search_result",
        name: "search_result",
        component: SearchResult
      },
      {
        path: "/company_info",
        name: "company_info",
        component: CompanyInfo
      },
      {
        path: "jishu",
        name: 'jishu',
        component: Jishu,
        redirect: '/jishu/jishu_people',
        children: [
          {
            path: 'jishu_content',
            name: 'jishu_content',
            component: JishuContent
          },
          {
            path: 'jishu_people',
            name: 'jishu_people',
            component: JishuPeople
          }
        ]
      },
      {
        path: '/news',
        name: 'news',
        component: News,
        redirect: '/news/news_list',
        children: [
          {
            path: 'news_list',
            name: 'news_list',
            component: NewsList
          },
          {
            path: 'news_content',
            name: 'news_content',
            component: NewsContent
          }
        ]
      },
      {
        path: '/contact_us',
        name: 'contact_us',
        component: ContactUs
      },
      {
        path: '/aboutus',
        name: 'aboutus',
        component: AboutUs
      },
      {
        path: '/yuyue',
        name: 'yuyue',
        component: Yuyue
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/find_pwd',
    name: 'find_pwd',
    component: FindPwd
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    redirect: '/register/register_user',
    children: [
      {
        path: 'register_user',
        name: 'register_user',
        component: RegisterUser
      },
      {
        path: 'register_qiye',
        name: 'register_qiye',
        component: RegisterQiye
      }
    ]
  },
  {
    path: '/ucenter',
    name: 'ucenter',
    component: Ucenter,
    redirect: '/ucenter/ucenter_info',
    children: [
      {
        path: 'ucenter_info',
        name: 'ucenter_info',
        component: UcenterInfo
      },
      {
        path: 'register_table',
        name: 'regitser_table',
        component: RegisterTable
      },
      {
        path: 'ucenter_account',
        name: 'ucenter_account',
        component: UcenterAccount
      },
      {
        path: 'yuyue_system',
        name: 'yuyue_system',
        component: YuyueSystem
      },
      {
        path: 'ucenter_shenpi',
        name: 'ucenter_shenpi',
        component: UcenterShenpi
      },
      {
        path: 'ucenter_diaocha',
        name: 'ucenter_diaocha',
        component: UcenterDiaocha
      }
    ]
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to,from,next)=>{
  if(to.name == 'jiance_list'){

  }
  next()
})

export default router
