<template>
  <div id="app">
    <router-link to="/"></router-link>
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'app',
}
</script>

<style>
.progressive {
  position: relative;
  display: block;
  overflow: hidden;
}
.progressive img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  border: 0 none;
}
.progressive img.preview {
  filter: blur(2vw);
  transform: scale(1.05);
}
.progressive img.hide {
  opacity: 0;
}
.progressive img.origin {
  position: absolute;
  left: 0;
  top: 0;
  animation: origin 1.5s ease-out;
}
.progressive img.origin-scale {
  position: absolute;
  left: 0;
  top: 0;
  animation: origin-scale 1s ease-out;
}
@-moz-keyframes origin {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes origin {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes origin {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes origin {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes origin-scale {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes origin-scale {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-o-keyframes origin-scale {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes origin-scale {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
  body::-webkit-scrollbar {
    width: 0!important;
  }
  body{
    background-color: #F6F6F6;
  }
  *{
    margin: 0;
    padding: 0;
  }
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
    position: relative;
  }
  body, dl, dt, dd, ul, ol, li, pre, form, fieldset, input, p, blockquote, th, td {
  font-weight:normal;
  margin:0;
  padding:0;
}
h1, h2, h3, h4, h4, h5 {
  margin:0;
  padding:0;
}
body {
  color:#333333;
  font-family:Helvetica,Arial,sans-serif;
  font-size:16px;
  text-align:left;
}
select {
  font-size:12px;
}
table {
  border-collapse:collapse;
}
fieldset, img {
  border:0 none;
}
fieldset {
  margin:0;
  padding:0;
}
fieldset p {
  margin:0;
  padding:0 0 0 8px;
}
legend {
  display:none;
}
address, caption, em, strong, th, i {
  font-style:normal;
  font-weight:400;
}
table caption {
  margin-left:-1px;
}
hr {
  border-bottom:1px solid #FFFFFF;
  border-top:1px solid #E4E4E4;
  border-width:1px 0;
  clear:both;
  height:2px;
  margin:5px 0;
  overflow:hidden;
}
ol, ul {
  list-style-image:none;
  list-style-position:outside;
  list-style-type:none;
}
caption, th {
  text-align:left;
}
q:before, q:after, blockquote:before, blockquote:after {
  content:””;
}
a {
	text-decoration: none;
	outline: none;
  color: #333333;
}
a:hover {
  color: #1C65B1;
}
input{  
	background:none;  
	outline:none;  
	border:1px solid #ccc;
}
input:focus{   
	border:1px solid #ccc;
}
textarea{resize:none;outline:none}
.w1200{
  max-width: 1200px;
  width: 94%;
}
.icon{
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}
.icon-login{
  width: .1286rem;
  height: .1229rem;
  background-image: url('./assets/login.png');
}
.icon-register{
  width: .1377rem;
  height: .1271rem;
  background-image: url('./assets/register.png');
}
.icon-login2{
  width: .1286rem;
  height: .1229rem;
  background-image: url('./assets/login2.png');
}
.icon-register2{
  width: .1377rem;
  height: .1271rem;
  background-image: url('./assets/register2.png');
}
.icon-search{
  width: .1714rem;
  height: .1822rem;
  background-image: url('./assets/search.png');
}
.icon-next{
  width: .0665rem;
  height: .1104rem;
  background-image: url('./assets/icon-next.png');
}
.icon-phone{
  width: .1692rem;
  height: .1749rem;
  background-image: url('./assets/icon-phone.png');
}
.icon-email{
  width: .1951rem;
  height: .1537rem;
  background-image: url('./assets/icon-email.png');
}
.icon-userphone{
  width: .36rem;
  height: .36rem;
  background-image: url('./assets/phone.png');
}
.icon-code1{
  width: .36rem;
  height: .36rem;
  background-image: url('./assets/code1.png');
}
.icon-code2{
  width: .36rem;
  height: .36rem;
  background-image: url('./assets/code2.png');
}
.icon-lock{
  width: .36rem;
  height: .36rem;
  background-image: url('./assets/lock.png');
}
.icon-userphone-b{
  width: .36rem;
  height: .36rem;
  background-image: url('./assets/phone-b.png');
}
.icon-code1-b{
  width: .36rem;
  height: .36rem;
  background-image: url('./assets/code1-b.png');
}
.icon-code2-b{
  width: .36rem;
  height: .36rem;
  background-image: url('./assets/code2-b.png');
}
.icon-lock-b{
  width: .36rem;
  height: .36rem;
  background-image: url('./assets/lock-b.png');
}
.icon-close{
  width: .2304rem;
  height: .2304rem;
  background-image: url('./assets/close.png');
}
.icon-closeeyes{
  width: .18rem;
  height: .0792rem;
  background-image: url('./assets/closeeyes.png');
}
.icon-openeyes{
  width: .19rem;
  height: .1222rem;
  background-image: url('./assets/openeyes.png');
}
.relative{
  position: relative;
}
.center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-next-w{
  width: .0665rem;
  height: .1165rem;
  background-image: url('./assets/next-w.png');
}
.icon-download{
  width: .0955rem;
  height: .1433rem;
  background-image: url('./assets/download.png');
}
.icon-fly{
  width: .19rem;
  height: .1771rem;
  background-image: url('./assets/feiji.png');
}
.icon-diaocha{
  width: .17rem;
  height: .17rem;
  background-image: url('./assets/diaocha.png');
}
.icon-edit{
  width: .1433rem;
  height: .1696rem;
  background-image: url('./assets/edit.png');
}
.icon-edit2{
  width: .1733rem;
  height: .2051rem;
  background-image: url('./assets/edit2.png');
}
.icon-edit3{
  width: .1482rem;
  height: .1482rem;
  background-image: url('./assets/edit3.png');
}
.icon-yumao{
  width: .1615rem;
  height: .1823rem;
  background-image: url('./assets/yumao.png');
}
.icon-add{
  width: .1534rem;
  height: .1534rem;
  background-image: url('./assets/add.png');
}
.icon-add2{
  width: .1835rem;
  height: .1835rem;
  background-image: url('./assets/add2.png');
  cursor: pointer;
}
.icon-reduce{
  width: .1835rem;
  height: .1835rem;
  background-image: url('./assets/reduce.png');
  cursor: pointer;
}
</style>
