<template>
	<div class="diaocha">
		<div class="pinfo">
			平台满意度调查
		</div>
		<div class="table" style="margin-left: 40px;">
			<div class="title">{{ memo.title }}</div>
			<div class="stitle ql-editor" v-html="memo.memo"></div>
			<!-- <div class="stitle">您好!感谢您在百忙之中填写问卷，请您根据自己的实际感受和看法如实填写，本问卷采用匿名形式，所有根据仅供了解整体员工满意度情况，不针对个别数据进行研究。</div> -->
			<div class="one"></div>
			<div v-for="(item, index) in list" :key="index">
				<div class="sstitle">
					<span class="red">*</span>{{ index + 1 }}、{{ item.title }}:
				</div>
				<div class="options">
					<el-radio-group v-model="item.value[0]" @change="changeOptions" v-if="item.type == 1">
						<!-- 选项 -->
						<el-radio v-for="(items, indexs) in item.options" :label="items.value" :key="indexs">{{ items.title }}
						</el-radio>
						<!-- <el-radio :label="6">B 已婚未生育</el-radio>
            <el-radio :label="9">C 已婚已生育</el-radio> -->
					</el-radio-group>

					<el-checkbox-group v-model="item.value" v-else>
						<el-checkbox v-for="(items, indexs) in item.options" :label="items.value" :key="indexs">{{ items.title }}
						</el-checkbox>
					</el-checkbox-group>
				</div>
			</div>

			<div class="sstitle">
				<span class="red"></span>您对公司目前还有哪些建议和意见:
			</div>
			<div class="options">
				<textarea class="jianyi" placeholder="请填写建议和意见.." v-model="jianyi"></textarea>
			</div>
		</div>
		<!--   -->
		<div class="submit center" @click="submit" v-if="!oldval['q8']">提交问卷</div>
		<!-- <div class="submit center" @click="submit">提交问卷</div> -->
	</div>
</template>

<script>
	import {
		getvolume,
		savevolume
	} from '../../utils/api.js'
	export default {
		name: 'BaizeUcenterDiaocha',

		data() {
			return {
				memo: {},
				list: [],
				jianyi: '',
				oldval: {}
			};
		},

		mounted() {
			this.getVolume()

		},

		methods: {
			getVolume() {
				getvolume({}, res => {
					console.log(res, "1");
					if (res.code == 0) {
						this.memo = res.data.vol
						var oldval = res.data.oldval;
						res.data.list.forEach(item => {
							item.type == 1 ? item.value = "" : item.value = []
							if (oldval['q' + item['id']]) item.value = oldval['q' + item['id']];
							item.value=JSON.parse(item.value)
						});
						this.jianyi = JSON.parse(oldval['q0'])
						this.list = res.data.list
						this.oldval = res.data.oldval
	console.log(this.list, "222");
					}
				
				})
				// console.log(res.data.oldval.q0,"3");
			},
			changeOptions(e) {

			},
			submit() {
				var data = {
					list: []
				}
				this.list.forEach((item, index) => {
					data.list.push({
						id: item.id,
						volid: item.volid,
						title: item.title,
						value: item.value
					})
				})
				data.list.push({
					id: 0,
					value: this.jianyi,
					title: '建议和意见'
				})
				savevolume(data, res => {
					if (res.code == 0) {
						this.$message({
							message: res.msg,
							type: 'success'
						})
					}
				})
			}
		},
	};
</script>

<style>
	.diaocha {
		/* width: 9rem; */
		width: 75% !important;
		height: fit-content;
		background-color: #fff;
		border: .01rem solid #EEEEEE;
		box-sizing: border-box;
		padding: .2rem .4rem;
	}

	.diaocha .pinfo {
		width: 100%;
		padding: .2rem 0;
		border-bottom: .01rem solid #EEEEEE;
		color: #333333;
		font-size: .2rem;
		font-weight: bold;
		display: flex;
		justify-content: space-between;
	}

	.diaocha .table .title {
		color: #1C65B1;
		font-size: .22rem;
		font-weight: bold;
		padding: .3rem 0 0;
		text-align: center;
	}

	.diaocha .table .stitle {
		color: #666;
		font-size: .16rem;
		line-height: .27rem;
	}

	.diaocha .table .one {
		color: #333333;
		font-size: .16rem;
		font-weight: bold;
		margin-top: .4rem;
	}

	.diaocha .table .sstitle {
		color: #333333;
		font-size: .16rem;
		margin-top: .3rem;
	}

	.diaocha .table .options {
		margin-top: .2rem;
		box-sizing: border-box;
		padding-left: .1rem;
	}

	.diaocha .table .options .jianyi {
		width: 100%;
		height: 1.57rem;
		border: .01rem solid #EDEDED;
		background-color: #fff;
		box-sizing: border-box;
		padding: .14rem .2rem;
	}

	.diaocha .table .submit {
		width: 4rem;
		height: .5rem;
		background-color: #1C65B1;
		border-radius: .04rem;
		color: #fff;
		font-size: .16rem;
		margin: .4rem auto;
		cursor: pointer;
	}

	.center:hover {
		cursor: pointer;
	}
</style>
