<template>
  <div class="js_content">
    <div class="top">
      <span>技术支持列表</span>
      <div class="position">
        <span>您所在的位置：<router-link to="/">首页 </router-link>> 技术支持</span>
      </div>
    </div>

    <div class="js_msg">
      <div class="msg_top">
        <div class="imgbox">
          <img :src="content.thumb" alt="">
        </div>
        <div>
          {{content.memo}}
        </div>
      </div>
      <div class="msg_middle ql-editor" v-html="content.content">
      </div>

      <!-- <div class="teams">
        <div>
          <img src="../assets/team.png" alt="">
          <div class="line"></div>
          <span>技术研究团队</span>
        </div>
        <div>
          <img src="../assets/team.png" alt="">
          <div class="line"></div>
          <span>技术研究团队</span>
        </div>
        <div>
          <img src="../assets/team.png" alt="">
          <div class="line"></div>
          <span>技术研究团队</span>
        </div>
        <div>
          <img src="../assets/team.png" alt="">
          <div class="line"></div>
          <span>技术研究团队</span>
        </div>
      </div>

      <div class="jiagou">
        <div>组织架构</div>
        <div>Organizational structure</div>
        <div>
          这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明.
这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明这里是架构内容说明
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { getsupinfo } from '../utils/api.js'
export default {
  name: 'BaizeJishuContent',

  data() {
    return {
      id: "",
      cid: "",
      content: ""
    };
  },

  mounted() {
    this.id = this.$route.query.id
    this.cid = this.$route.query.cid
    this.getinfo()
  },

  methods: {
    getinfo(){
      getsupinfo({id: this.cid},res=>{
        if(res.code == 0){
          this.content = res.data.data
        }
      })
    }
  },

  watch: {
    $route(){
      location.reload()
    }
  }
};
</script>

<style>
  .js_content{
    /* width: 8.89rem; */
    width: 74%;
  }
  .js_content .top{
    display: flex;
    justify-content: space-between;
    padding-bottom: .13rem;
    border-bottom: 1px solid #E9E9E9;
    margin-bottom: .3rem;
  }
  .js_content .top > span{
    color: #333333;
    font-size: .18rem;
    font-weight: bold;
    position: relative;
  }
  .js_content .top > span::after{
    content: "";
    width: 100%;
    height: 2px;
    background-color: #1C65B1;
    position: absolute;
    left: 0;
    bottom: -.14rem;
  }
  .js_content .top > .position{
    color: #999999;
    font-size: .16rem;
  }
  .js_content .top > .position a{
    color: #999999;
    font-size: .16rem;
  }
  .js_content .js_msg{
    color: #666;
    font-size: .18rem;
  }
  .js_msg .msg_top{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .js_msg .msg_top > .imgbox{
    width: 3.43rem;
    height: fit-content;
  }
  .js_msg .msg_top > .imgbox img{
    width: 100%;
    height: auto;
  }
  .js_msg .msg_top > div{
    width: 5.2rem;
    line-height: .32rem;
  }
  .js_msg .msg_middle {
    width: 100%;
    line-height: .32rem;
    margin-top: .26rem;
  }
  .js_msg .teams{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: .4rem 0;
  }
  .js_msg .teams > div{
    width: 2.07rem;
    height: 2.7rem;
    border: .01rem solid #999999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .js_msg .teams > div > img{
    width: .44rem;
    height: .44rem;
  }
  .js_msg .teams > div > .line{
    width: .83rem;
    height: .01rem;
    background-color: #707070;
    margin: .12rem auto .07rem;
  }
  .js_msg .teams > div > span{
    color: #333333;
    font-size: .18rem;
    font-weight: bold;
  }
  .js_msg .jiagou > div:nth-child(1){
    font-weight: bold;
    font-size: .26rem;
    color: #333333;
  }
  .js_msg .jiagou > div:nth-child(2){
    color: #C9C9C9;
    font-size: .16rem;
    margin: .1rem 0 0 0;
  }
  .js_msg .jiagou > div:nth-child(3){
    width: 100%;
    margin-top: .4rem;
    line-height: .36rem;
  }
</style>