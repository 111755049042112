<template>
	<div>
		<div class="banner">
			<div v-for="(item, index) in imgs" :key="index" class="progressive"
				style="width: 100%;height: auto;z-index: -1;position: relative">
				<img :src="item.preview" class="preview" alt="" v-progressive="item.banner" style="width: 100%;">
			</div>
			<div class="title">在线预约</div>
			<div class="desc">为您提供全面系统，真实可靠的检测服务体系</div>
		</div>
		<div class="w1200 yuyue_content">
			<div class="title">【 预约提交 】 </div>
			<div class="yyinps">
				<div>
					<div class="inpbox">
						<div class="name">样品名称<span>*</span></div>
						<input type="text" v-model="ytitle" placeholder="请输入样品名称" name="" id="">
					</div>
					<div class="inpbox right">
						<div class="name longname">样品规格型号<span>*</span></div>
						<input style="width: 3.67rem;" v-model="yxinghao" type="text" placeholder="请输入样品规格型号" />
						<!-- <el-select v-model="xinghao_value" placeholder="请选择">
              <el-option
                v-for="item in xinghao"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select> -->
					</div>

					<div class="inpbox">
						<div class="name">样品数量<span>*</span></div>
						<input type="text" v-model="ynum" placeholder="请输入样品数量" name="" id="">
					</div>

					<div class="inpbox right">
						<div class="name longname">样品状态<span>*</span></div>
						<!-- <input style="width: 3.67rem;" v-model="ystatus" type="text" placeholder="请输入样品状态" /> -->
						<el-select v-model="ystatus" placeholder="请选择">
							<el-option v-for="item in statuslist" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
				</div>



				<!-- 预约开始 -->
				<div v-for="(item, index) in line_list" :key="index">
					<div style="display: flex;justify-content: space-between;width: 100%;">
						<div style="padding-top: 0; display: flex;align-items: center">
							<div class="name">预约项目<span style="color: red;">*</span></div>
							<!-- <el-cascader
                v-model="item.name"
                :options="item.options"
                @change="changeXiangmu(index)"
                :props="{checkStrictly: true}"              
                ></el-cascader> -->
							<el-cascader v-model="item.name" :options="item.options" @change="changeXiangmu(index)"
								style="width: 190px;margin-left: 30px;"></el-cascader>
							<div class="name longname" style="margin-left: 40px;">预约时间<span style="color: red;">*</span>
							</div>
							<el-date-picker v-model="item.time" type="datetimerange" placeholder="选择日期时间"
								value-format="yyyy-MM-dd HH:mm" :picker-options="pickerOptionsOne"
								:default-time="['08:00:00', '10:00:00']" style="width: 300px;
    margin-left: 20px;">
							</el-date-picker>
						</div>
						<div style="padding-top: 0; display: flex;">
							<!-- 
              <el-date-picker
                v-model=""
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions">
              </el-date-picker> -->
							<div style="width: 310px;height: 100%; display: flex;align-items: center">
								<div style="margin-right: 10px;">辅助设备</div>
								<el-select v-model="item.index" multiple placeholder="请选择"
									:disable=" item.other.num  === 0">
									<el-option v-for="(item, index) in item.other" :key="index" :label="item.title"
										:value="item.id">
									</el-option>
								</el-select>
							</div>
							<div style="display: flex;align-items: center">
								<i v-show="index == line_list.length - 1" class="icon icon-add2"
									style="margin-right: .15rem; margin-left: .15rem; width: 25px;height: 30px;"
									@click="add_xiangmu(index)"></i>
								<i class="icon icon-reduce" style="width: 25px;height: 30px;"
									@click="del_xiangmu(index)" v-if="line_list.length > 1"></i>
							</div>
						</div>
					</div>
					<div class="imglist" v-show="item.list && item.list.length > 0">
						<div class="imgbox" :key="indexs" v-for="(items, indexs) in item.list">
							<img :src="items.thumb" alt="">
							<span>{{ items.title }}</span>
						</div>
					</div>

				</div>
				<!-- 预约结束 -->


				<div style="flex-direction: column;">
					<!-- <div class="inpbox" style="padding-top: 0;">
            <div class="name">辅助设备</div>
            <input type="text" v-model="fdevice" placeholder="请输入辅助设备" name="" id="">
          </div> -->
					<div class="inpbox" style="width: 100%;">
						<div class="name">备注说明</div>
						<textarea name="" v-model="memo" placeholder="请填写备注说明内容." id="" cols="30" rows="10"></textarea>
					</div>

					<div class="submit" @click="submit">提交</div>
				</div>
			</div>
		</div>

		<div class="poptan" @touchmove.prevent @mousewheel.prevent v-if="show">
			<div class="popshadow"></div>
			<div class="loginbox" :style="loginType == 1 ? 'height: fit-content' : ''">
				<div class="login_tabs">
					<span :class="loginType == '2' ? 'active' : ''" @click="changeLoginType('2')">用户登录</span>
					<span :class="loginType == '1' ? 'active' : ''" @click="changeLoginType('1')">密码登录</span>
				</div>

				<div class="code_login">
					<div class="code_phone">
						<i :class="formatFocus == 'phone' ? 'icon icon-userphone-b' : 'icon icon-userphone'"></i>
						<div id="phone" class="relative center">
							<input class="phoneinp" v-model="phone" @focus="changeFocus('phone')" type="text"
								placeholder="请输入手机号" name="">
							<i class="icon icon-close" @click="clearPhone"
								style="position: absolute;right: .2rem;cursor: pointer;" v-show="phone"></i>
						</div>
					</div>
					<div class="code_phone" v-show="loginType == 2">
						<i :class="formatFocus == 'imgcode' ? 'icon icon-code1-b' : 'icon icon-code1'"></i>
						<div class="code_right">
							<div id="imgcode">
								<input type="text" v-model="imgcodetext" @focus="changeFocus('imgcode')"
									placeholder="请输入图形验证码" name="">
								<img :src="imgcode" class="imgcode" alt="">
							</div>
							<span @click="getImgCode">换一张</span>
						</div>
					</div>
					<div class="code_phone" v-show="loginType == 1"></div>
					<div class="code_phone" v-show="loginType == '2'">
						<i :class="formatFocus == 'code' ? 'icon icon-code2-b' : 'icon icon-code2'"></i>
						<div class="code_right2" id="code">
							<input type="text" v-model="code" @focus="changeFocus('code')" placeholder="请输入手机验证码"
								name="">
							<div @click="getPhoneCode">{{ codetext }}</div>
						</div>
					</div>

					<div class="code_phone" v-show="loginType == '1'">
						<i :class="formatFocus == 'password' ? 'icon icon-lock-b' : 'icon icon-lock'"></i>
						<div id="password" class="relative center">
							<input :type="showpwd ? 'text' : 'password'" v-model="password" maxlength="16"
								@focus="changeFocus('password')" placeholder="请输入密码">
							<i class="icon icon-close" v-show="password" @click="password = ''"
								style="position: absolute; right: .5rem;cursor: pointer;"></i>
							<i :class="showpwd ? 'icon icon-openeyes' : 'icon icon-closeeyes'"
								@click="showpwd = !showpwd" v-show="password"
								style="position: absolute; right: .2rem;cursor: pointer;"></i>
						</div>

					</div>

					<div class="code_phone">
						<span></span>
						<div class="submit" @click="login">登录</div>
					</div>

					<div class="code_phone">
						<span></span>
						<div class="bottom">
							<router-link to="/register?type=1">新用户注册</router-link>
							<router-link to="/find_pwd">忘记密码?</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pop_wx" v-show="show_wx">
			<div class="shadow" @click="show_wx = false"></div>
			<div class="wx_content">
				<div class="wx_top">微信绑定</div>
				<div class="wx_pic">
					<img :src="qrcode" alt="">
					<span>扫一扫上面的二维码图案，绑定微信</span>
				</div>
			</div>
		</div>

		<el-dialog
  :title="msgbox.title"
  :visible.sync="msgbox.show"
  width="30%"
  :before-close="handleClose">
  <span v-html="msgbox.content"></span>
  <span slot="footer" class="dialog-footer">
    <el-button @click="msgbox.show = false">关闭</el-button>
    <!-- <el-button type="primary" @click="dialogVisible = false">确 定</el-button> -->
  </span>
</el-dialog>

	</div>
</template>

<script>
	import {
		yuyue,
		getdevice,
		saveyuyue,
		getimgcode,
		getcode,
		login,
		getuser,
		getqrcode,
		qrCodeOk
	} from '../utils/api.js'
	export default {
		name: 'BaizeYuyue',

		data() {
			return {
				msgbox:{
					show:false
				},
				pickerOptionsOne: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
					},
				},
				xinghao_value: "",
				xinghao: ['型号1', '型号二'],
				time_value: "",
				options: [],
				xiangmu: "",
				proids: [],
				line_list: [{
					name: "",
					time: "",
					cateid: "",
					cateid2: "",
					proid: "",
					options: [],
					list: [],
					other: [],
				}],
				ytitle: "",
				yxinghao: "",
				ynum: "",
				ystatus: "正常",
				fdevice: [],
				memo: "",
				canclick: true,
				statuslist: [{
						value: "正常",
						label: "正常"
					},
					{
						value: "部分功能正常",
						label: "部分功能正常"
					},
				],
				imgs: [{
					preview: require('../assets/yuyuebg-s.jpg'),
					banner: require('../assets/yuyuebg.jpg')
				}],
				showdrop: 0,
				loginType: "2", //登陆类型 1密码 2验证码
				formatFocus: "",
				phone: '',
				imgcode: '',
				code: '',
				password: '',
				imgcodetext: "",
				iscodeing: false,
				times: 120,
				codetext: "获取验证码",
				backurl: "",
				showpwd: false,
				show: false,
				show_wx: false,
				qrcode: "",
				dsq: null
			};
		},

		watch: {
			show_wx(val) {
				var that = this
				if (val) {
					this.dsq = setInterval(() => {
						qrCodeOk({}, res => {
							if (res.data.closeok == 1) {
								clearInterval(that.dsq)
								that.$message({
									message: res.msg,
									type: "success"
								})
								that.show_wx = false
								// that.$router.push({
								//   name: "ucenter/yuyue_system",
								//   query: {
								//     yystatus: "0"
								//   }
								// })
								location.href = "/#/ucenter/yuyue_system?yystatus=0"
							}
						})
					}, 1000)

				} else {
					clearInterval(this.dsq)
				}
			}
		},

		mounted() {
			if (this.$route.query.cateid && this.$route.query.proid) {
				this.line_list[0].name = [this.$route.query.cateid * 1, this.$route.query.proid * 1, this.$route.query
					.proid * 1
				]
				this.line_list[0]['cateid'] = this.$route.query.cateid * 1
				this.line_list[0]['cateid2'] = this.$route.query.proid * 1
				this.line_list[0]['proid'] = this.$route.query.proid * 1
			}
			this.$store.commit('setSelected', 6)
			this.init(0)

		},

		methods: {
			init(index) {
				yuyue({
					proids: this.proids
				}, res => {
					if (res.openbox == 1) {
						this.show = true
						this.getImgCode()
					} else {
						this.line_list[index]['options'] = res.data.list
						this.msgbox=res.data.msgbox
					}
				})
			},
			add_xiangmu(index) {
				if (!this.line_list[index]['name']) {
					this.$message({
						message: "请先选择当前预约项目",
						type: "error",
					})
					return false
				}
				this.line_list.push({
					name: "",
					time: "",
					cateid: "",
					cateid2: "",
					proid: "",
					options: [],
					list: [],
					other: []
				})
				this.init(index + 1)
			},
			del_xiangmu(index) {
				this.line_list.splice(index, 1)
				this.proids.splice(index, 1)
				yuyue({
					proids: this.proids
				}, res => {
					this.line_list[index]['options'] = res.data.list

				})
			},
			changeXiangmu(e) {
				this.line_list.forEach((item, index) => {
					console.log(item)
					if (index == e) {
						if (item.name.length == 2) {
							item.cateid = item.name[0]
							item.cateid2 = item.name[1]
							item.proid = item.name[1]
							this.proids.push(item.name[1])
							this.getDevice(item.name[1], e)
						} else if (item.name.length == 3) {
							item.cateid = item.name[0]
							item.cateid2 = item.name[1]
							item.proid = item.name[2]
							this.proids.push(item.name[2])
							this.getDevice(item.name[2], e)
						} else if (item.name.length == 1) {
							item.cateid = item.name[0]
							item.cateid2 = ''
							item.proid = ''
						}
					}

				});
			},
			getDevice(id, index) {
				getdevice({
					proid: id
				}, res => {
					if (res.code == 0) {
						this.line_list[index]['list'] = res.data.list
						// console.log(res.data)
						this.line_list[index]['other'] = res.data.other
						console.log(this.line_list[index]['other'][index].num)
					}
				})
			},
			time() {
				console.log(Date.now())
				console.log(Date.now() - 86400)
			},
			submit() {
				var data = {
					ytitle: this.ytitle,
					yxinghao: this.yxinghao,
					ynum: this.ynum,
					ystatus: this.ystatus,
					list: this.line_list,
					fdevice: this.fdevice,
					memo: this.memo
				}
				for (const key in data) {
					if (!data[key] && key != 'fdevice' && key != 'memo') {
						this.$message({
							message: "请填写完整预约信息",
							type: "error"
						})
						return false
					}
				}

				if (!this.line_list[this.line_list.length - 1].name) {
					this.$message({
						message: "请选择预约项目",
						type: "error"
					})
					return false
				}
				if (!this.line_list[this.line_list.length - 1].time) {
					this.$message({
						message: "请选择预约时间",
						type: "error"
					})
					return false
				}
				if (this.canclick) {
					saveyuyue(data, res => {
						if (res.code == 0) {
							this.canclick = false
							this.$message({
								message: res.msg,
								type: "success",
							})
							getuser({}, res => {
								if (res.code == 0) {
									if (res.data.data.user.wechat) {
										this.$router.push({
											name: "yuyue_system",
											query: {
												yystatus: ""
											}
										})
									} else {
										getqrcode({}, resx => {
											if (resx.code == 0) {
												this.qrcode = resx.data.path
												this.show_wx = true
											}
										})
									}
								}
							})
						}
					})
				}

			},
			gourl(url) {
				this.$router.push(url)
			},
			gourl2(url, id) {
				this.$router.push({
					name: 'jishu',
					query: {
						id
					}
				})
			},
			login() {
				login({
					type: this.loginType,
					phone: this.phone,
					imgcode: this.imgcodetext,
					password: this.password,
					code: this.code
				}, res => {
					if (res.code == 0) {
						localStorage.setItem('token', res.data.token)
						this.$store.commit('reFreshToken')
						this.$router.go(0)

					}
				})

			},
			changeLoginType(e) {
				if (this.loginType == e) {
					return false
				}
				this.loginType = e
				this.getImgCode()
			},
			changeFocus(e) {
				this.formatFocus = e
			},
			register() {
				this.$router.push({
					name: 'register',
					query: {
						type: 1
					}
				})
			},
			getImgCode() {
				getimgcode({}, res => {
					if (res.code == 0) {
						this.imgcode = res.data.imgcode + '?' + Math.random()
					}
				})
			},
			getPhoneCode() {
				if (!this.phone || this.phone.length != 11) {
					this.$message({
						message: "请输入正确的手机号",
						type: "error"
					})
					return false
				}
				if (!this.iscodeing) {
					getcode({
						phone: this.phone,
						type: '2'
					}, res => {
						var that = this
						if (res.code == 0) {
							this.$message({
								message: "发送成功！请注意查收",
								type: "success"
							})
							this.iscodeing = true
							var daojishi;
							daojishi = setInterval(() => {
								if (that.times > 0) {
									that.times--
									that.codetext = '重新获取' + that.times + 's'
								} else {
									that.iscodeing = false
									clearInterval(daojishi)
									that.codetext = '获取验证码'
									that.times = 120
								}
							}, 1000)
						}
					})
				}
			},
			clearPhone() {
				this.phone = ""
			}
		},
	};
</script>

<style scoped>
	.banner {
		width: 100%;
		height: 4.2rem;
		overflow: hidden;
		background-size: contain;
		background-repeat: no-repeat;
	}

	.banner .title {
		color: #fff;
		font-size: .42rem;
		padding-top: 1.9rem;
		text-align: center;
	}

	.banner .desc {
		color: #fff;
		font-size: .16rem;
		margin-top: .26rem;
		text-align: center;
	}

	.banner .inp {
		width: 5.43rem;
		height: fit-content;
		margin: 1rem auto 0;
		display: flex;

	}

	.banner .inp>input {
		width: 4.83rem;
		height: .52rem;
		box-sizing: border-box;
		padding: 0 .18rem;
		border: none;
		background-color: #fff;
		border-radius: .4rem 0 0 .4rem;
	}

	.banner .inp>div {
		flex: 1;
		background-color: #013B65;
		border-radius: 0 .4rem .4rem 0;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.imglist {
		width: 100%;
		height: 1.2rem;
		margin: .2rem 0;
		display: flex;
		overflow: hidden;
	}

	.imgbox {
		width: 1.3rem;
		height: 1.2rem;
		overflow: hidden;
		position: relative;
		margin-right: .2rem;
	}

	.imgbox>img {
		width: 100%;
		height: auto;
	}

	.imgbox>span {
		display: inline-block;
		width: 100%;
		height: .22rem;
		background: rgba(0, 0, 0, .5);
		color: #fff;
		font-size: .14rem;
		box-sizing: border-box;
		padding: .04rem .1rem;
		overflow: hidden;
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.yuyue_content {
		margin: -.7rem auto .9rem;
		box-sizing: border-box;
		padding: .4rem .52rem;
		background-color: #fff;
		box-shadow: 0 0 .2rem rgba(28, 101, 177, .2);
	}

	.yuyue_content .title {
		color: #333;
		font-size: .32rem;
		text-align: center;
	}

	.yuyue_content .yyinps {
		width: 103%;
	}

	.yyinps>div {
		width: 100%;
		padding: .33rem 0;
		border-bottom: .01rem solid #EDEDED;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.yyinps .inpbox {
		/* width: 5.22rem; */
		width: 46.6%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: .3rem;
	}

	.yyinps .inpbox .name {
		color: #333;
		font-size: .18rem;
	}

	.yyinps .inpbox .longname {
		width: 120px;
		text-align: right;
	}

	.yyinps .inpbox .name span {
		color: #D90606;
	}

	.yyinps .inpbox input {
		width: calc(100% - 145px) !important;
		/* width: 4.03rem; */
		height: .44rem;
		border: .01rem solid #EDEDED;
		box-sizing: border-box;
		padding: 0 .2rem;
	}

	.yyinps .right .el-select {
		width: calc(100% - 145px) !important;
	}

	/* .yyinps .left .el-select{
    width: calc(100% - 190px) !important;
  } */
	.yyinps .right .el-date-editor.el-input {
		width: calc(100% - 190px) !important;
	}

	.left .el-cascader {
		/* width: 4.03rem; */
		width: calc(100% - 145px) !important;
	}

	.yyinps .inpbox>textarea {
		display: inline-block;
		/* width: 9.76rem; */
		width: calc(100% - 145px) !important;
		height: 1.57rem;
		border: .01rem solid #EDEDED;
		box-sizing: border-box;
		padding: .14rem .2rem;
	}

	.yyinps .submit {
		width: 4rem;
		height: .5rem;
		border-radius: .04rem;
		background-color: #1C65B1;
		color: #fff;
		font-size: .16rem;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: .4rem auto;
		cursor: pointer;
	}

	.loginbox {
		width: 5.23rem;
		height: 4.97rem;
		background-color: #FFFFFF;
		border-radius: .08rem;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		margin: auto;
		box-sizing: border-box;
		padding: .32rem .28rem;
	}

	.loginbox .login_tabs {
		width: 100%;
		padding-bottom: .11rem;
		border-bottom: .01rem solid #EFEFEF;
		display: flex;
		justify-content: center;
		color: #666666;
		font-size: .16rem;
		margin-bottom: .15rem;
	}

	.loginbox .login_tabs span {
		cursor: pointer;
	}

	.loginbox .login_tabs>span:nth-child(1) {
		margin-right: 1.15rem;
	}

	.loginbox .login_tabs .active {
		color: #333333;
		font-size: .18rem;
		font-weight: bold;
		position: relative;
		display: flex;
		justify-content: center;
		cursor: inherit;
	}

	.loginbox .login_tabs .active::after {
		width: 100%;
		height: .03rem;
		background-color: #1C65B1;
		position: absolute;
		bottom: -.11rem;
		content: "";
	}

	.code_login .code_phone {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-top: .26rem;
		align-items: center;
	}

	.code_login .code_phone>div>.phoneinp {
		width: 4.1rem;
		height: .5rem;
		border-radius: .04rem;
		border: .01rem solid #DCDCDC;
		box-sizing: border-box;
		padding: 0 .2rem;
	}

	.code_login .code_phone .code_right {
		width: 4.1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.code_login .code_phone .code_right>div {
		display: flex;
		border: .01rem solid #DCDCDC;
		border-radius: .04rem;
	}

	.code_login .code_phone .code_right>div input {
		box-sizing: border-box;
		width: 2.55rem;
		height: .5rem;
		padding: 0 .2rem;
		border: none;
	}

	.code_login .code_phone .code_right>div .imgcode {
		width: .98rem;
		height: .5rem;
		background-color: #EFEFEF;
	}

	.code_login .code_phone .code_right>span {
		color: #666666;
		font-size: .14rem;
		cursor: pointer;
	}

	.code_login .code_phone .code_right2 {
		width: 4.1rem;
		display: flex;
		border-radius: .04rem;
		border: .01rem solid #DCDCDC;
	}

	.code_login .code_phone .code_right2>input {
		width: 3rem;
		height: .5rem;
		border: none;
		box-sizing: border-box;
		padding: 0 .2rem;
	}

	.code_login .code_phone .code_right2>div {
		width: 1.1rem;
		height: .5rem;
		background-color: #EFEFEF;
		border-radius: 0 .04rem .04rem 0;
		text-align: center;
		line-height: .5rem;
		color: #999999;
		font-size: .14rem;
		cursor: pointer;
	}

	.code_login .code_phone .submit {
		width: 4.1rem;
		height: .56rem;
		background-color: #1C65B1;
		border-radius: .04rem;
		color: #FFFFFF;
		font-size: .18rem;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: .28rem;
		cursor: pointer;
	}

	.code_login .code_phone .bottom {
		width: 4.1rem;
		display: flex;
		justify-content: space-between;
		color: #999999;
		font-size: .14rem;
	}

	.code_login .code_phone .bottom>a:hover {
		color: #1C65B1;
	}

	#password>input {
		width: 4.1rem;
		height: .5rem;
		border-radius: .04rem;
		border: .01rem solid #DCDCDC;
		box-sizing: border-box;
		padding: 0 .2rem;
	}

	#password>input:focus {
		border: #1C65B1 .01rem solid;
	}

	.poptan {
		width: 100%;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 0;
	}

	.popshadow {
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, .6);
	}


	.pop_wx {
		width: 100%;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 100;
	}

	.pop_wx .shadow {
		width: 100%;
		height: 100vh;
		background: rgba(0, 0, 0, .5);
		position: fixed;
		top: 0;
		left: 0;
		z-index: 101;
	}

	.pop_wx .wx_content {
		width: 4.54rem;
		height: fit-content;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 102;
	}

	.pop_wx .wx_content .wx_top {
		width: 100%;
		height: .94rem;
		background: linear-gradient(90deg, #1C65B1, #0653A7);
		box-shadow: 0 .1rem .1rem rgba(28, 101, 177, .2);
		border-radius: .1rem .1rem 50% 50%;
		color: #fff;
		font-size: .16rem;
		display: flex;
		align-items: center;
		z-index: 9;
		justify-content: center;
		position: absolute;
	}

	.pop_wx .wx_content .wx_pic {
		width: 100%;
		height: 3.88rem;
		border-radius: 0 0 .1rem .1rem;
		background-color: #fff;
		z-index: -1;
		padding-top: .65rem;
		margin-top: .65rem;
	}

	.pop_wx .wx_content .wx_pic>img {
		width: 2.59rem;
		height: 2.59rem;
		display: flex;
		margin: 0 auto .26rem;
	}

	.pop_wx .wx_content .wx_pic>span {
		color: #999999;
		font-size: .12rem;
		display: block;
		margin: 0 auto;
		text-align: center;
	}
</style>