import post from './request'

export const getimgcode = (params,callback) =>{ post.post('/api/imgcode',params).then(res=>{callback(res)}) }
export const backpassword = (params,callback) =>{ post.post('/api/backpassword',params).then(res=>{callback(res)}) }
export const getcompany = (params,callback) => { post.post('/api/getcompany',params).then(res=>{callback(res)}) }
export const register = (params,callback) => { post.post('/api/register',params).then(res=>{callback(res)}) }
export const getcode = (params,callback) => { post.post('/api/getcode',params).then(res=>{callback(res)}) }
export const login = (params,callback) => { post.post('/api/login',params).then(res=>{callback(res)}) }
export const getcate = (params,callback) => { post.post('/api/getcate',params).then(res=>{callback(res)}) }
export const getprojectlist = (params,callback) => { post.post('/api/projectlist',params).then(res=>{callback(res)}) }
export const getprojectinfo = (params,callback) => { post.post('/api/projectinfo',params).then(res=>{callback(res)}) }
export const getsupcatelist = (params,callback) => { post.post('/api/supcatelist',params).then(res=>{callback(res)}) }
export const getsuplist = (params,callback) => { post.post('/api/suplist',params).then(res=>{callback(res)}) }
export const getsupinfo = (params,callback) => { post.post('/api/supinfo',params).then(res=>{callback(res)}) }
export const getnewscate = (params,callback) => { post.post('/api/newscate',params).then(res=>{callback(res)}) }
export const getnewslist = (params,callback) => { post.post('/api/newslist',params).then(res=>{callback(res)}) }
export const getnewsinfo = (params,callback) => { post.post('/api/newsinfo',params).then(res=>{callback(res)}) }
export const getarticle = (params,callback) => { post.post('/api/article',params).then(res=>{callback(res)}) }
export const contact = (params,callback) => { post.post('/api/contact',params).then(res=>{callback(res)}) }
export const certificate = (params,callback) => { post.post('/api/certificate',params).then(res=>{callback(res)}) }
export const partner = (params,callback) => { post.post('/api/partner',params).then(res=>{callback(res)}) }
export const getcaselist = (params,callback) => { post.post('/api/caselist',params).then(res=>{callback(res)}) }
export const yuyue = (params,callback) => { post.post('/center/yuyue',params).then(res=>{callback(res)}) }
export const getdevice = (params,callback) => { post.post('/center/getdevice',params).then(res=>{callback(res)}) }
export const saveyuyue = (params,callback) => { post.post('/center/saveyuyue',params).then(res=>{callback(res)}) }
export const getuser = (params,callback) => { post.post('/center/getuser',params).then(res=>{callback(res)}) }
export const getheaderuser = (params,callback) => { post.post('/api/getuser',params).then(res=>{callback(res)}) }
export const change = (params,callback) => { post.post('/center/change',params).then(res=>{callback(res)}) }
export const getqrcode = (params,callback) => { post.post('/center/getqrcode',params).then(res=>{callback(res)}) }
export const getcentercompany = (params,callback) => { post.post('/center/getcompany',params).then(res=>{callback(res)}) }
export const savecompany = (params,callback) => { post.post('/center/savecompany',params).then(res=>{callback(res)}) }
export const getaccount = (params,callback) => { post.post('/center/account',params).then(res=>{callback(res)}) }
export const acchandle = (params,callback) => { post.post('/center/acchandle',params).then(res=>{callback(res)}) }
export const createaccount = (params,callback) => { post.post('/center/createaccount',params).then(res=>{callback(res)}) }
export const getmyorderlist = (params,callback) => { post.post('/center/myorderlist',params).then(res=>{callback(res)}) }
export const editorder = (params,callback) => { post.post('/center/editorder',params).then(res=>{callback(res)}) }
export const verusercom = (params,callback) => { post.post('/center/verusercom',params).then(res=>{callback(res)}) } //审批列表
export const vercomuser = (params,callback) => { post.post('/center/vercomuser',params).then(res=>{callback(res)}) } //审批 uid recontent status 1 -1
export const getvolume = (params,callback) => { post.post('/center/getvolume',params).then(res=>{callback(res)}) }
export const savevolume = (params,callback) => { post.post('/center/savevolume',params).then(res=>{callback(res)}) }
export const backorder = (params,callback) => { post.post('/center/backorder',params).then(res=>{callback(res)}) }
export const getComtmp = (params,callback) => { post.post('/api/getcomtmp',params).then(res=>{callback(res)}) }
export const sendemailcode = (params,callback) => { post.post('/center/sendemailcode',params).then(res=>{callback(res)}) }
export const qrCodeOk = (params,callback) => { post.post('/center/qrcodeok',params,false).then(res=>{callback(res)}) }