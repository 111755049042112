<template>
  <div>
    <div class="banner">
      <div v-for="(item,index) in imgs" class="progressive"
        style="width: 100%;height: auto;z-index: -1;position: relative">
        <img :src="item.preview" class="preview" alt="" v-progressive="item.banner" style="width: 100%;">
      </div>
      <div class="title">关于我们</div>
      <div class="desc">为您提供全面系统，真实可靠的检测服务体系</div>
    </div>

    <div class="w1200 container">
      <div class="jiancexiangmu aboutus">
        <div class="title">关于我们</div>
        <div class="stitle">ABOUT US</div>
        <div class="gongsijianjie">
          <img :src="aboutusData.thumb" alt="">
          <div class="info">
            <div>公司简介</div>
            <div class="jianjie ql-editor" style="word-wrap: break-word;word-break: break-all;overflow: hidden;"
              v-html="aboutusData.content">
            </div>
            <div class="btn" @click="gocompany">了解更多</div>
          </div>
        </div>
      </div>
    </div>
    <div
      :style="'height: 100%;background-image: url(' + require('../assets/zhengshubg.png') + ');background-size: contain;padding-bottom: .6rem;background-position: bottom;background-repeat: no-repeat;'">
      <div class="w1200 container">
        <div class="jiancexiangmu zizhizhengshu">
          <div class="title">资质证书</div>
          <div class="stitle">QU ALIFICATION CERTIFICATE</div>
          <div style="position: relative; height: fit-content;">
            <div class="swiper-container" id="zizhi">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item,index) in zizhilist">
                  <img class="zizhi" :src="item.thumb" alt="" @click="gozizhi(item.url)">
                </div>

              </div>
            </div>
            <div class="swiper-button-prev" id="prev1"></div>
            <div class="swiper-button-next" id="next1"></div>
          </div>
        </div>
      </div>
    </div>



    <div class="w1200 container">
      <div class="jiancexiangmu hezuo">
        <div class="title">合作伙伴</div>
        <div class="stitle">COOPERATIVE PARTNER</div>
        <div style="position: relative; height: fit-content;">
          <div class="swiper-container" id="hezuo">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item,index) in partnerList" :key="index">
                <!-- <img class="huoban" @click="gohezuo(item.url)" :src="item.thumb + item.logo[0].thumb" alt=""> -->
                <img class="huoban" @click="gohezuo(item.url)" :src="item.thumb" alt="">
              </div>
            </div>
          </div>
          <div class="swiper-button-prev" id="prev"></div>
          <div class="swiper-button-next" id="next"></div>
        </div>
        <div style="margin: .4rem 0 .6rem;color: #999999;font-size: .16rem">累计多家知名企业</div>
      </div>

    </div>
    <div
      :style="'height: 100%;background-image: url(' + require('../assets/anlibg.png') + ');background-size: cover;padding-bottom: .6rem'">
      <div class="w1200 container">
        <div class="jiancexiangmu case">
          <div class="title">案例展示</div>
          <div class="stitle">CASE DISPLY</div>
          <div class="case_content">
            <div class="case_left">
              <span @mouseenter="changeCase(index,item)" :class="caseindex == index ? 'active' : ''"
                v-for="(item,index) in caselist" :key="index">{{item.title}}</span>
            </div>
            <div class="case_center" :style="'background-image: url(' + case_content.thumb + ')'">
              <div>{{case_content.memo}}</div>
            </div>
            <div class="case_right" style="overflow: hidden;">
              <img v-for="(item,index) in case_content.pics" :src="item" alt="">
              <!-- <img src="../assets/case3.png" alt=""> -->
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
  import Swiper from 'swiper'
  import {
    getarticle,
    certificate,
    partner,
    getcaselist
  } from '../utils/api.js'
  export default {
    name: 'BaizeAboutus',

    data() {
      return {
        xiangmuindex: 0,
        caselist: [],
        caseindex: 0,
        aboutusData: "",
        zizhilist: [],
        partnerList: [],
        case_content: {
          pics: []
        },
        imgs: [{
          preview: require('../assets/aboutusbg-s.jpg'),
          banner: require('../assets/contactbg.jpg')
        }]
      };
    },

    mounted() {
      this.$store.commit('setSelected', 5)
      this.getArticle()
      this.getCertificate()
      this.getPartner()
      this.getCaseList()
    },

    methods: {
      getArticle() {
        getarticle({
          name: 'about'
        }, res => {
          this.aboutusData = res.data.data
        })
      },
      changeCase(index, item) {
        this.caseindex = index
        this.case_content = item
      },
      getCertificate() {
        certificate({}, res => {
          if (res.code == 0) {
            this.zizhilist = res.data.list
						console.log("资质证书连接",res.data.list);
            this.$nextTick(() => {
              new Swiper('#zizhi', {
                slidesPerView: 4,
                spaceBetween: 23,
                prevButton: '#prev1',
                nextButton: '#next1',
              })
            })
          }
        })
      },
      gocompany() {
        this.$router.push('company_info')
      },
      getPartner() {
        partner({}, res => {
          this.partnerList = res.data.list
          this.$nextTick(() => {
            new Swiper('#hezuo', {
              slidesPerView: 5,
              spaceBetween: 26,
              prevButton: '#prev',
              nextButton: '#next',
            })
          })
        })
      },
      getCaseList() {
        getcaselist({}, res => {
          this.caselist = res.data.list
          this.case_content = res.data.list[0]
        })
      },
      gozizhi(url) {
        if (url) {
          location.href = url
        } else {

        }
      },
      gohezuo(url) {
        location.href = url
      }
    },
  };
</script>

<style scoped>
  .banner {
    width: 100%;
    height: 4.2rem;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  .banner .title {
    color: #fff;
    font-size: .42rem;
    padding-top: 2.9rem;
    text-align: center;
  }

  .banner .desc {
    color: #fff;
    font-size: .16rem;
    margin-top: .26rem;
    text-align: center;
  }

  .banner .inp {
    width: 5.43rem;
    height: fit-content;
    margin: .5rem auto 0;
    display: flex;

  }

  .banner .inp>input {
    width: 4.83rem;
    height: .52rem;
    box-sizing: border-box;
    padding: 0 .18rem;
    border: none;
    background-color: #fff;
    border-radius: .4rem 0 0 .4rem;
  }

  .banner .inp>div {
    flex: 1;
    background-color: #013B65;
    border-radius: 0 .4rem .4rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .banner .title {
    padding-top: 1.9rem;
  }

  .container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .container .jiancexiangmu {
    text-align: center;
    width: 100%;
    padding-top: .6rem;
    color: #333333;
  }

  .container .jiancexiangmu .title {
    font-size: .38rem;
    padding-bottom: .2rem;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .container .jiancexiangmu .title::after {
    content: "";
    width: .4rem;
    height: .02rem;
    background-color: #1C65B1;
    position: absolute;
    bottom: 0;
  }

  .container .jiancexiangmu .stitle {
    color: #999;
    font-size: .22rem;
    margin-top: .06rem;
  }

  .container .jiancexiangmu .items {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    margin-top: .38rem;
  }

  .container .jiancexiangmu .items>span {
    display: inline-block;
    padding: .14rem .26rem;
    background-color: #fff;
    border: 1px solid #F1F1F1;
    border-radius: .01rem;
    color: #333333;
    font-size: .16rem;
    cursor: pointer;
    transition: all ease .3s;
    margin-right: .22rem;
  }

  .container .jiancexiangmu .items>.active {
    background-color: #1C65B1;
    color: #fff;
    font-weight: bold;
  }

  .container .jiancexiangmu .items>span:hover {
    background-color: #1C65B1;
    color: #fff;
    font-weight: bold;
    transition: all ease .3s;
  }

  .container .jiancexiangmu .items .next {
    position: absolute;
    right: 0;
    display: flex;
    cursor: pointer;
    align-items: center;
    color: #999999;
    font-size: .14rem;
  }

  .container .jiancexiangmu .items .next span {
    margin-right: .06rem;
  }

  .container .jiancexiangmu .xiangmu {
    width: 100%;
    margin-top: .4rem;
    display: flex;
    flex-wrap: wrap;
  }

  .container .jiancexiangmu .xiangmu .xiangmu_item {
    width: 2.8rem;
    height: 4.15rem;
    background-color: #fff;
    margin-right: .26rem;
    margin-top: .26rem;
    display: flex;
    flex-direction: column;
    transition: all .3s ease;
  }

  .container .jiancexiangmu .xiangmu .xiangmu_item:hover {
    transition: all .3s ease;
    transform: scale(1.05);
    box-shadow: 0 -.1rem .2rem rgba(19, 116, 222, .3);
  }

  .container .jiancexiangmu .xiangmu .xiangmu_item .thumb {
    width: 100%;
    height: 2.21rem;
    cursor: pointer;
  }

  .container .jiancexiangmu .xiangmu .xiangmu_item .info {
    flex: 1;
    box-sizing: border-box;
    padding: .2rem;
    text-align: left;
  }

  .container .jiancexiangmu .xiangmu .xiangmu_item .info .stitle {
    color: #333333;
    font-size: .17rem;
    font-weight: bold;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .container .jiancexiangmu .xiangmu .xiangmu_item .info .desc {
    color: #666666;
    font-size: .14rem;
    margin-top: .23rem;
    line-height: .22rem;
    height: .44rem;
    overflow: hidden;
  }

  .container .jiancexiangmu .xiangmu .xiangmu_item .info .btn {
    /* width: 1.1rem; */
    height: .36rem;
    border-radius: .2rem;
    border: 1px solid #1C65B1;
    color: #1C65B1;
    font-size: .16rem;
    margin: .18rem auto 0;
    text-align: center;
    line-height: .36rem;
    cursor: pointer;
    transition: all ease .3s;
    padding: 0 0.3rem;
  }

  .container .jiancexiangmu .xiangmu .xiangmu_item .info .btn:hover {
    background-color: #1C65B1;
    color: #fff;
    transition: all .3s ease;
  }

  .container .aboutus .gongsijianjie {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: .38rem;
  }

  .container .aboutus .gongsijianjie>img {
    /* width: 7.92rem; */
    width: 66%;
    height: 4.44rem;
  }

  .container .aboutus .gongsijianjie .info {
    /* width: 5.59rem; */
    width: 46%;
    /* height: 3.33rem; */
    box-sizing: border-box;
    padding: .22rem;
    background-color: #fff;
    position: absolute;
    right: 0;
  }

  .container .aboutus .gongsijianjie .info>div:nth-child(1) {
    color: #333333;
    font-size: .22rem;
    text-align: left;
    position: relative;
  }

  .container .aboutus .gongsijianjie .info>div:nth-child(1)::after {
    content: "";
    width: .4rem;
    height: .02rem;
    background-color: #1C65B1;
    position: absolute;
    left: 0;
    bottom: -.1rem;
  }

  .container .aboutus .gongsijianjie .info .jianjie {
    color: #666666;
    font-size: .16rem;
    margin-top: .35rem;
    text-align: left;
    /* height: 1.35rem; */
    margin-bottom: .5rem;
    padding: 0 !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    text-overflow: ellipsis;
  }

  .container .aboutus .gongsijianjie .info .btn {
    /* width: 1.1rem; */
    height: .36rem;
    border: 1px solid #DFDFDF;
    border-radius: .2rem;
    color: #CFCDCD;
    font-size: .14rem;
    line-height: .36rem;
    float: right;
    clear: both;
    padding: 0 0.3rem;
    cursor: pointer;
  }

  .container .aboutus .gongsijianjie .info .btn:hover {
    background-color: #1C65B1;
    border: 1px solid #1C65B1;
    transition: all ease .3s;
    color: #fff;
  }

  #zizhi {
    margin-top: .38rem;
    position: relative;
    width: 94%;
  }

  .zizhizhengshu .zizhi {
    width: 2.648rem;
    height: 3.828rem;
    cursor: pointer;
    transition: all .3s ease;
  }

  .zizhizhengshu .zizhi:hover {
    transform: scale(1.02);
    transition: all .3s ease;
  }

  #hezuo {
    margin-top: .38rem;
    position: relative;
    width: 90%;
  }

  #hezuo .huoban {
    width: 2.04rem;
    height: 2.01rem;
    cursor: pointer;
  }

  .case_content {
    width: 100%;
    height: 4.1rem;
    display: flex;
    justify-content: space-between;
    margin-top: .38rem;
    margin-bottom: .38rem;
  }

  .case_content .case_left {
    width: 13.8%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
  }

  .case_content .case_left>span {
    display: inline-block;
    width: 100%;
    height: .5rem;
    color: #333333;
    font-size: .16rem;
    text-align: center;
    line-height: .5rem;
    background-color: #fff;
    border: 1px solid #F1F1F1;
    border-radius: 1px;
    cursor: pointer;
    transition: all ease .3s;
    margin-bottom: .22rem;
  }

  .case_content .case_left>.active {
    color: #fff;
    font-size: .16rem;
    background-color: #1C65B1;
    transition: all ease .3s;
  }

  .case_content .case_center {
    width: 36%;
    height: 100%;
    background-image: url('../assets/case1.png');
    background-size: cover;
  }

  .case_content .case_center div {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .42);
    color: #FFFFFF;
    font-size: .18rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .case_content .case_right {
    /* width: 5.52rem; */
    width: 46%;
    height: 100%;
    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
  }

  .case_content .case_right>img {
    width: 100%;
    height: 1.92rem;
    object-fit: cover;
    margin-top: 0.26rem;
    display: block;
  }
  .case_content .case_right>img:first-child{
    margin-top: 0;
  }
</style>