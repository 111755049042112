<template>

	<!-- 右侧内容 -->

	<div class="jc_list">
		<div class="top">
			<span>检测项目列表</span>
			<div class="position">
				<span>您所在的位置：<router-link to="/">首页 </router-link>> 检测中心</span>
			</div>
		</div>
		<div class="xiangmu">
			<div class="xiangmu_item" :style="index % 3 == 2 ? 'margin-right: 0' : ''"
				v-for="(item,index) in xiangmulist" :key="index">
				<div class="thumb"><img :src="item.thumb" style="width: 100%; height: auto;" alt=""></div>
				<div class="info">
					<div class="stitle" @click="goinfo(item.id)">{{item.title}}</div>
					<div class="desc">{{item.memo}}</div>
					<div class="btn" @click="goinfo(item.id)">立即预约</div>
				</div>
			</div>
			<div v-if="!xiangmulist || xiangmulist.length < 1" style="width: 100%;">
				<el-empty :image-size="200"></el-empty>
			</div>
		</div>
		<div class="page" v-if="count > 0">
			<el-pagination background layout="prev, pager, next" :total="count" :current-page.sync='currentPage'
				@current-change="changePage">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		getprojectlist
	} from '../utils/api.js'
	export default {

		name: 'BaizeJianceList',

		data() {
			return {
				xiangmulist: [],
				xiangmuindex: 0,
				nextp: 1,
				cateid: "",
				cateid2: "",
				count: 0,
				isback: 0,
				currentPage: 1
			};
		},

		mounted() {

			// this.cateid = this.$route.query.cateid
			// this.cateid2 = this.$route.query.cateid2
			// this.getlist()
			if (this.$route.query.isback) {
				this.isback = 1
				this.getlist(this.$route.query.cateid, this.$route.query.cateid2, this.$route.query.keyword)
			}

		},

		methods: {
			goinfo(id) {

				this.$router.push({
					name: 'jiance_info',
					query: {
						id: id,
					}
				})

			},
			getlist(cateid, cateid2, keyword, nextp) {
				if (!nextp) {
					this.currentPage = 1
				}
				getprojectlist({
					p: nextp,
					cateid: cateid,
					cateid2: cateid2,
					keyword: keyword
				}, res => {

					if (res.code == 0) {
						this.xiangmulist = res.data.list
						this.count = res.data.total * 1
					}
				})
			},
			changePage(e) {
				this.$emit('refresh', {
					nextp: e
				});
			}
		},
		watch: {


			//     $route(){
			//       if(this.$route.query.isback){
			//         this.isback = 1

			//       }
			//     }
		}
	};
</script>

<style>
	.jc_list {
		/* width: 8.89rem; */
		width: 74%;
	}

	.jc_list .top {
		display: flex;
		justify-content: space-between;
		padding-bottom: .13rem;
		border-bottom: 1px solid #E9E9E9;
		margin-bottom: .3rem;
	}

	.jc_list .top>span {
		color: #333333;
		font-size: .18rem;
		font-weight: bold;
		position: relative;
	}

	.jc_list .top>span::after {
		content: "";
		width: 100%;
		height: 2px;
		background-color: #1C65B1;
		position: absolute;
		left: 0;
		bottom: -.14rem;
	}

	.jc_list .top>.position {
		color: #999999;
		font-size: .16rem;
	}

	.jc_list .top>.position a {
		color: #999999;
		font-size: .16rem;
	}

	.xiangmu {
		width: 100%;
		margin-top: .4rem;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.xiangmu .xiangmu_item {
		/* width: 2.8rem; */
		width: 31.5%;
		height: 4.15rem;
		background-color: #fff;
		/* margin-right: .24rem; */
		margin-top: .26rem;
		display: flex;
		flex-direction: column;
		transition: all .3s ease;
	}

	.xiangmu .xiangmu_item:hover {
		transition: all .3s ease;
		transform: scale(1.05);
		box-shadow: 0 -.1rem .2rem rgba(19, 116, 222, .3);
	}

	.xiangmu .xiangmu_item .thumb {
		width: 100%;
		height: 2.21rem;
		cursor: pointer;
		overflow: hidden;
	}

	.xiangmu .xiangmu_item .info {
		flex: 1;
		box-sizing: border-box;
		padding: .2rem;
		text-align: left;
		background-color: #F6F6F6;
		display: flex;
		flex-direction: column;
	}

	.xiangmu .xiangmu_item .info .stitle {
		color: #333333;
		font-size: .17rem;
		font-weight: bold;
		cursor: pointer;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.xiangmu .xiangmu_item .info .desc {
		color: #666666;
		font-size: .14rem;
		margin-top: .23rem;
		/* line-height: .22rem;
    height: .44rem; */
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		text-overflow: ellipsis;
	}

	.xiangmu .xiangmu_item .info .btn {
		/* width: 1.1rem; */
		padding: 0 0.22rem;
		height: .36rem;
		border-radius: .2rem;
		border: 1px solid #1C65B1;
		color: #1C65B1;
		font-size: .16rem;
		margin: .18rem auto 0;
		text-align: center;
		line-height: .36rem;
		cursor: pointer;
		transition: all ease .3s;
	}

	.xiangmu .xiangmu_item .info .btn:hover {
		background-color: #1C65B1;
		color: #fff;
		transition: all .3s ease;
	}

	.jc_list .page {
		display: flex;
		margin: .4rem 0;
		justify-content: center;
	}
</style>
