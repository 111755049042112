<template>
  <div>
    <div class="header">
      <div class="w1200 container">
        <img src="../../assets/logo.png" class="logo" alt="">
        <div class="listbox" :style="index == 0 ? 'margin-left: .46rem' : ''" v-for="(item,index) in list" :key="index">
          <!-- 标题 -->
          <span @click="gourl(item.url,index)" @mouseenter=" index == 2 ? showdrop = 1 : showdrop = 0" @mouseleave="showdrop = 0">{{item.title}}</span>
          <!-- 下拉菜单 -->
          <div :style="showdrop == 1 ? 'opacity: 1;overflow: hidden' : 'opacity: 0;overflow: hidden'">
            <span @click="gourl2(items.url,items.id)" :style="showdrop == 1 ? 'height: .6rem' : 'height: 0'" @mouseenter="showdrop = 1" @mouseleave="showdrop=0" v-for="(items,indexs) in item.list" :key="indexs">{{items.title}}</span>
          </div>
        </div>

        <div class="loginbox">
          <div class="login" @click="login">
            <i class="icon icon-login2"></i>
            <span>登录</span>
          </div>
          <div class="register login_fill">
            <i class="icon icon-register"></i>
            <span>注册</span>
          </div>
        </div>
        <!-- <div class="loginbox userinfo" v-else>
          <img class="headimgurl" src="../../assets/headimgurl.png" alt="">
          <span>个人中心</span>
          <span>退出</span>
        </div> -->
      </div>
    </div>

    <div class="register_content w1200">
      <div class="top">
        <span><i class="icon icon-next-w"></i></span>
        <div class="title">注册账号/认证</div>
        <div class="position">您所在的位置： <router-link to="/">首页</router-link> > 注册</div>
      </div>
      <div class="content">
        <div class="tabs">
          <span :class="tabindex == 1 ? 'active' : ''" @click="changeTab(1)">个人注册</span>
          <span :class="tabindex == 2 ? 'active' : ''" @click="changeTab(2)">企业注册</span>
        </div>

        <router-view ref="register"></router-view>

        <div class="drawer" :style="tabindex == 2 ? 'right: 0' : 'right: -100%'">
          <div class="title">模板下载</div>
          <div class="intro">
            <div>注:</div>
            <div class="info">模版下载后填写完成，拍照上传认证即可。</div>
          </div>
          <div class="file">
            <div>平台预约注册登记表</div>
            <div class="download center" @click="getcomtmp">
              <span>下载</span>
              <i class="icon icon-download"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getsupcatelist,getComtmp} from '../../utils/api.js'
export default {
  name: 'BaizeRegister',

  data() {
    return {
      list: [
        {
          title: "首页",
          url: '/index_content'
        },
        {
          title: "检测中心",
          url: '/jiance_center'
        },
        {
          title: "技术支持",
          list: [
            {
              title: '技术支持1',
              url: 'jishu',
              id: 1
            },
            {
              title: '技术支持2',
              url: 'jishu',
              id: 2
            },
            {
              title: '技术支持3',
              url: 'jishu',
              id: 3
            }
          ]
        },
        {
          title: "新闻公告",
          url: '/news'
        },
        {
          title: "联系我们",
          url: '/contact_us'
        },
        {
          title: "关于我们",
          url: '/aboutus'
        },
        {
          title: "在线预约",
          url: '/yuyue'
        }
      ],
      showdrop: 0,
      tabindex: 1,
      showdrawer: false
    };
  },

  mounted() {
    this.tabindex = this.$route.query.type
    getsupcatelist({},res=>{
      if(res.code == 0){
        this.list[2]['list'] = res.data.list
      }
    })
  },

  computed: {
    comtmp(){
      return this.$store.state.comtmp
    }
  },

  methods: {
    getcomtmp(){
      var data = this.$refs.register.registerData
      getComtmp(data,res=>{
        this.$store.commit('setComtmp',res.data.comtmp)
        this.download()
      })
    },
    gourl(url,index){
      this.$store.commit('setSelected', index)
      this.$router.push(url)
    },
    gourl2(url,id){
      this.$store.commit('setSelected', 2)
      this.$router.push({
        name: url,
        query: {
          id
        }
      })
    },
    login(){
      this.$router.push('/login')
    },
    changeTab(e){
      if(e == this.tabindex){
        return false
      }
      if(e == 2){
        this.showdrawer = true
        this.$router.replace({
          name: 'register_qiye',
          query: {
            type: e
          }
        })
      }else{
        this.showdrawer = false
        this.$router.replace({
          name: 'register_user',
          query: {
            type: e
          }
        })
      }

    },
    download(){
      location.href = this.comtmp
    }
  },
  watch: {
    $route(){
      this.tabindex = this.$route.query.type
    }
  }
};
</script>

<style scoped>
  .header{
    width: 100%;
    height: .98rem;
    background-color: #FFFFFF;
    transition: all .3s ease;
    position: relative;
  }

  .header:hover .container .listbox > span{
    color: #333333;
  }
  .container .listbox{
    display: flex;
    height: 100%;
    width: 1.37rem;
    position: relative;
    justify-content: center;
  }
  .container .listbox:nth-child(1){
    width: 1.17rem;
  }
  .container .listbox > div{
    width: 1.37rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    z-index: -1;
    transition: all .3s ease ;
    opacity: 0;
  }
  .container .listbox > div span{
    display: inline-block;
    width: 100%;
    height: 0;
    background-color: #F9F9F9;
    color: #666666;
    font-size: .16rem;
    text-align: center;
    line-height: .6rem;
    cursor: pointer;
    transition: all .3s ease;
  }
  .container .listbox > div span:hover{
    color: #1C65B1;
    position: relative;
    transition: all .3s ease;
  }
  .container .listbox > div span:hover::after{
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: .02rem;
    background-color: #1C65B1;
    left: 0;
    transition: all .3s ease;
  }

  .container .listbox > span:hover{
    border-top: .04rem solid #1C65B1;
  }


  .header .container .loginbox > .login_fill{
    background-color: #1C65B1;
    color: #FFFFFF;
  }
  .header:hover .container .userinfo span{
    color: #666666;
  }
  .container{
    margin: 0 auto;
    display: flex;
    position: relative;
    height: 100%;
    align-items: center;
  }
  .logo{
    width: 1.46rem;
    cursor: pointer;
  }
  .container .listbox > span{
    color: #333;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 100%;
  }
  .container .loginbox{
    width: fit-content;
    display: flex;
    color: #FFFFFF;
    font-size: .14rem;
    position: absolute;
    right: -1.9rem;
  }
  .container .loginbox div{
    width: .68rem;
    height: .23rem;
    background: rgba(255, 255, 255, .1);
    border-radius: .2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #FFFFFF;
    border: 1px solid #1C65B1;
    color: #1C65B1;
  }
  .container .loginbox .register{
    margin-left: .29rem;
    position: relative;
  }
  .container .loginbox .register::before{
    content: "";
    width: .01rem;
    height: .15rem;
    background: rgba(255, 255, 255, .2);
    position: absolute;
    left: -.15rem;
  }
  .container .loginbox div span{
    margin-left: .05rem;
  }
  .register_content {
    margin: .4rem auto .33rem;
  }
  .register_content .top{
    height: .56rem;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: .33rem;
  }
  .register_content .top > span{
    width: .17rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1C65B1;
  }
  .register_content .top > .title{
    color: #333333;
    font-size: .18rem;
    margin-left: .18rem;
  }
  .register_content .top > .position{
    position: absolute;
    right: .2rem;
    color: #999999;
    font-size: .16rem;
  }
  .register_content .top > .position a{
    color: #999999;
  }
  .register_content .top > .position a:hover{
    color: #1C65B1;
  }
  .register_content .content{
    width: 100%;
    background-color: #FFFFFF;
    border: .01rem solid #EEEEEE;
    overflow: hidden;
    position: relative;
  }
  .register_content .content .tabs{
    width: 100%;
    padding: .64rem 0 .15rem;
    border-bottom: .01rem solid #EDEDED;
    display: flex;
    justify-content: center;
  }
  .register_content .content .tabs > span{
    padding: 0 .4rem;
    color: #666666;
    font-size: .16rem;
    cursor: pointer;
  }
  .register_content .content .tabs > .active{
    color: #1C65B1;
    font-weight: bold;
    position: relative;
    font-size: .18rem;
    transition: all .3s ease;
    display: flex;
    cursor: inherit;
    justify-content: center
  }
  .register_content .content .tabs > .active::after{
    content: "";
    width: 100%;
    height: .02rem;
    background-color: #1C65B1;
    position: absolute;
    bottom: -.16rem;
  }

  .register_content .content .drawer{
    width: 2.4rem;
    height: 8.3rem;
    background-color: #FFFFFF;
    border: .01rem solid #EEEEEE;
    box-shadow: -.06rem 0 .06rem rgba(28, 101, 177, .06);
    position: absolute;
    right: 0;
    top: 0;
    padding-top: .64rem;
    box-sizing: border-box;
    padding: .64rem .21rem 0;
    transition: all ease .3s;
  }
  .register_content .content .drawer .title{
    color: #333;
    font-size: .16rem;
    padding-bottom: .12rem;
    border-bottom: .01rem solid #1C65B1;
    width: fit-content;
  }
  .register_content .content .drawer .intro{
    width: 100%;
    color: #C9C3C3;
    font-size: .12rem;
    margin-top: .24rem;
    line-height: .18rem;
  }
  .register_content .content .drawer .intro > .info{
    padding: 0;
  }
  .register_content .content .drawer .file{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #666666;
    font-size: .12rem;
    margin: .8rem 0 .2rem;
  }
  .register_content .content .drawer .file .download{
    width: 1.1rem;
    height: .36rem;
    border-radius: .2rem;
    border: .01rem solid #1C65B1;
    margin: .2rem auto;
    color: #1C65B1;
    font-size: .16rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .register_content .content .drawer .file .download > span{
    display: inline-block;
    margin: 0 .08rem 0 0;

  }
</style>