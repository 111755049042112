import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token'),
    yystatusindex: -99,
    spstatusindex: -99,
    selected: 0,
    qrcode: "",
    comtmp: "",
    bottom: {}
  },
  // commit 提交更改状态
  mutations: {
    reFreshToken(state,token){
      state.token = localStorage.getItem('token')
    },
    removeToken(state){
      localStorage.removeItem('token')
      state.token = ''
    },
    changeYYstatus(state,status){
      state.yystatusindex = status
    },
    changeSPstatus(state,status){
      state.spstatusindex = status
    },
    setSelected(state,value){
      state.selected = value
    },
    setQrcode(state,qrcode){
      state.qrcode = qrcode
    },
    setComtmp(state,comtmp){
      state.comtmp = comtmp
    },
    setBottom(state,bottom){
      state.bottom = bottom
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {

  }
})
