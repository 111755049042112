<template>
  <div>
    <div class="banner">
      <div class="title">公司介绍</div>
      <div class="desc">为您提供全面系统，真实可靠的检测服务体系</div>
      <div class="inp">
        <input class="swiper-no-swiping" placeholder="请输入搜索关键词" type="text" name="" id="" v-model="keyword">
        <div @click="search">
          <i class="icon icon-search"></i>
        </div>
      </div>
    </div>

    <div class="w1200 news_content">
      <div class="position">
        您所在的位置：<router-link to="/">首页</router-link> > 公司介绍
      </div>
      <!-- <div class="tabs">
        <span @click="changeCate(item.id,index)" :class="actindex == index ? 'active' : ''" v-for="(item,index) in catelist" :key="index">{{item.title}}</span>
      </div> -->
      <div class="news_msg">
        <div class="news_msg_content">
          <div class="title">{{content.title}}</div>
          <div class="time">
            <span>{{content.zuozhe}}</span>
            <span>发布时间:{{content.updatetime}}</span>
          </div>
          <div class="news_html ql-editor" v-html="content.content">
            
          </div>
        </div>
    
        <!-- <div class="page2">
          <div class="prev">
            上一篇:<span @click="goprev">{{prev.title}}</span>
          </div>
          <div class="next">
            下一篇:<span @click="gonext">{{next.title}}</span>
          </div>
        </div> -->
      </div>
      
    </div>

  </div>
</template>

<script>
import { getarticle } from '../utils/api.js'
export default {
  name: 'BaizeNews',

  data() {
    return {
      catelist: [],
      actindex: 0,
      nextp: 1,
      cateid: "",
      content: "",
      keyword: ""
    };
  },

  mounted() {
    this.$store.commit('setSelected',-1)
    this.getinfo()
  },

  methods: {
    search(){
      if(this.keyword){
        this.$router.push({
          name: "search_result",
          query: {
            keyword: this.keyword
          }
        })
      }else{
        this.$message({
          message: "请输入搜索内容",
          type: "error"
        })
      }
    },
    getinfo(){
      getarticle({name: 'about'},res=>{
				console.log("公司简介",res);
        if(res.code == 0){
          this.content = res.data.data
        }
      })
    }
  },
  beforeCreate () {
    document.querySelector('body').setAttribute('style','background-color: #F6F6F6')
  }
};
</script>

<style scoped>
  .banner{
    width: 100%;
    height: 4.2rem;
    background-image: url('../assets/banner.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
  .banner .title{
    color: #fff;
    font-size: .42rem;
    padding-top: 1.9rem;
    text-align: center;
  }
  .banner .desc{
    color: #fff;
    font-size: .16rem;
    margin-top: .26rem;
    text-align: center;
  }
  .banner .inp{
    width: 5.43rem;
    height: fit-content;
    margin: .5rem auto 0;
    display: flex;

  }
  .banner .inp > input{
    width: 4.83rem;
    height: .52rem;
    box-sizing: border-box;
    padding: 0 .18rem;
    border: none;
    background-color: #fff;
    border-radius: .4rem 0 0 .4rem;
  }
  .banner .inp > div{
    flex: 1;
    background-color: #013B65;
    border-radius: 0 .4rem .4rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .news_content{
    margin: 0 auto;
  }
  .news_content .position{
    color: #999999;
    font-size: .16rem;
    margin: .3rem 0 .4rem;
  }
  .news_content .position a{
    color: #999999;
  }
  .news_content .tabs{
    display: flex;
    width: 100%;
    background-color: #EFEFEF;
    height: .72rem;
  }
  .news_content .tabs > span{
    display: inline-block;
    width: 4rem;
    height: 100%;
    color: #666666;
    font-size: .18rem;
    text-align: center;
    line-height: .72rem;
    cursor: pointer;
    transition: all .3s ease;
  }
  .news_content .tabs > .active{
    background-color: #1C65B1;
    font-weight: bold;
    color: #fff;
    transition: all .3s ease;
  }
  .news_msg{
    width: 100%;
    height: fit-content;
    background-color: #fff;
    box-sizing: border-box;
    padding: .38rem .36rem;
    margin-top: .4rem;
    margin-bottom: .9rem;
  }
  .news_msg_content{
    width: 100%;
    padding-bottom: .3rem;
    border-bottom: 1px solid #EEE7E7;
  }
  .news_msg_content .title{
    color: #000000;
    font-size: .3rem;
    text-align: center;
  }
  .news_msg_content .time{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: #999999;
    font-size: .14rem;
    margin: .36rem 0;
  }
  .news_msg_content .time > span:nth-child(1){
    margin-right: .4rem;
  }
  .page2{
    width: 100%;
    color: rgba(17, 17, 17, 0.8);
    font-size: .16rem;
  }
  .page2 .prev span{
    margin-top: .35rem;
    cursor: pointer;
    color: #999999;
    display: inline-block;
  }
  .page2 .next span{
    margin-top: .2rem;
    cursor: pointer;
    color: #999999;
    display: inline-block;
  }
  .news_html{
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
  }
</style>