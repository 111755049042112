import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
import PublicHeader from './components/Public_Header.vue'
import PublicFooter from './components/Public_Footer.vue'
import axios from 'axios'
import WhiteHeader from './components/White_header.vue'
import '../theme/index.css'
import './assets/css/quill.css'
import ElementUI from 'element-ui'
import progressive from 'progressive-image/dist/vue'; // 渐进式

Vue.config.productionTip = false
Vue.component('PublicHeader',PublicHeader)
Vue.component('PublicFooter',PublicFooter)
Vue.component('WhiteHeader',WhiteHeader)
Vue.prototype.$axios = axios
Vue.use(ElementUI)
Vue.use(progressive,{
  removePreview: true,
  scale: true
})
new Vue({
  router,
  store,
  PublicHeader,
  PublicFooter,
  WhiteHeader,
  render: h => h(App)
}).$mount('#app')
