<template>
  <div>
    <div class="banner">
      <div v-for="(item,index) in imgs" class="progressive" style="width: 100%;height: auto;z-index: -1;position: relative">
        <img :src="item.preview" alt="" class="preview" v-progressive="item.banner" style="width: 100%;">
      </div>
      <div class="title"></div>
      <div class="desc"></div>
      <div class="inp">
        <input class="swiper-no-swiping" v-model="keyword" placeholder="请输入搜索关键词" type="text" name="" id="">
        <div @click="search">
          <i class="icon icon-search"></i>
        </div>
      </div>
    </div>

    <div class="w1200 news_content">
      <div class="position">
        您所在的位置：<router-link to="/">首页</router-link> > 新闻公告
      </div>
      <div class="tabs">
        <span @click="changeCate(item.id,index)" :class="actindex == index ? 'active' : ''" v-for="(item,index) in catelist" :key="index">{{item.title}}</span>
      </div>
      <keep-alive include="">
        <router-view ref="child" @refresh="refresh"></router-view>
      </keep-alive>
      
    </div>

  </div>
</template>

<script>
import { getnewscate } from '../utils/api.js'
export default {
  name: 'BaizeNews',

  data() {
    return {
      catelist: [],
      actindex: 0,
      nextp: 1,
      cateid: "",
      keyword: "",
      imgs: [
        {
          preview: require('../assets/newsbg-s.jpg'),
          banner: require('../assets/newsbg.png')
        }
      ]
    };
  },

  mounted() {
    this.$store.commit('setSelected',3)
    getnewscate({},res=>{
      if(res.code == 0){
        this.catelist = res.data.list
        this.cateid = res.data.list[0].id
        this.$refs['child'].getlist(this.cateid,this.nextp)
      }
    })
  },

  methods: {
    changeCate(id,index){
      this.actindex = index
      if(this.$route.name.indexOf("news_list") >= 0){
        this.$refs.child.getlist(id,1)
      }else{
        this.$router.push({
          name: "news_list",
          query: {
            isback: 1,
            cateid: id
          }
        })
      }
      
    },
    refresh(e){
      this.nextp = e.nextp
      this.$refs['child'].getlist(this.cateid,this.nextp)
    },
    search(){
      if(this.keyword){
        this.$router.push({
          name: "search_result",
          query: {
            keyword: this.keyword
          }
        })
      }else{
        this.$message({
          message: "请输入搜索内容",
          type: "error"
        })
      }
    }
  },
  beforeCreate () {
    document.querySelector('body').setAttribute('style','background-color: #F6F6F6')
  }
};
</script>

<style scoped>
  .banner{
    width: 100%;
    height: 4.2rem;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
  }
  .banner .title{
    color: #fff;
    font-size: .42rem;
    padding-top: 2.9rem;
    text-align: center;
  }
  .banner .desc{
    color: #fff;
    font-size: .16rem;
    margin-top: .26rem;
    text-align: center;
  }
  .banner .inp{
    width: 5.43rem;
    height: fit-content;
    margin: 0 auto 0;
    display: flex;
    position: absolute;
    left: 50%;
    margin-left: -2.715rem;
    top: 3.3rem;
  }
  .banner .inp > input{
    width: 4.83rem;
    height: .52rem;
    box-sizing: border-box;
    padding: 0 .18rem;
    border: none;
    background-color: #fff;
    border-radius: .4rem 0 0 .4rem;
  }
  .banner .inp > div{
    flex: 1;
    background-color: #013B65;
    border-radius: 0 .4rem .4rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .news_content{
    margin: 0 auto;
  }
  .news_content .position{
    color: #999999;
    font-size: .16rem;
    margin: .3rem 0 .4rem;
  }
  .news_content .position a{
    color: #999999;
  }
  .news_content .tabs{
    display: flex;
    width: 100%;
    background-color: #EFEFEF;
    height: .72rem;
  }
  .news_content .tabs > span{
    display: inline-block;
    /* width: 4rem; */
    width: 33.3%;
    height: 100%;
    color: #666666;
    font-size: .18rem;
    text-align: center;
    line-height: .72rem;
    cursor: pointer;
    transition: all .3s ease;
  }
  .news_content .tabs > .active{
    background-color: #1C65B1;
    font-weight: bold;
    color: #fff;
    transition: all .3s ease;
  }
</style>